/**
 * @file features/global/testStringStart
 * @description Simple function that takes two values - a string start and full string. It returns true or false depending on whether the full string starts with the start string. It generates the Regex used for testing as it is designed to escape Regex characters.
 */

const testStringStart = (stringStart: string, stringToTest: string) => {
    const startRegex = new RegExp(`^${stringStart
        .replace(/\\/g, '\\\\')
        .replace(/\//g, '\\/')
        .replace(/\[/g, '\\[')
        .replace(/\]/g, '\\]')
        .replace(/\\/g, '\\')
        .replace(/\^/g, '\\^')
        .replace(/\$/g, '\\$')
        .replace(/\./g, '\\.')
        .replace(/\|/g, '\\|')
        .replace(/\?/g, '\\?')
        .replace(/\*/g, '\\*')
        .replace(/\+/g, '\\+')
        .replace(/-/g, '\\-')
        .replace(/\(/g, '\\(')
        .replace(/\)/g, '\\)')
        .replace(/'/g, '\'')
    }`);
    if (stringStart && !(startRegex.test(stringToTest))) {
        return false;
    }
    else {
        return true;
    }
};
export default testStringStart;