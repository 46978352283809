/**
 * @file features/user/actions
 * @description Contains Redux actions for User Profile loading.
 */

// Imports
import { TUserProfile, TUserAction } from './types';

/**
 * The PROFILE_LOADING action is fired when user profile load begins.
 */
export const PROFILE_LOADING = 'PROFILE_LOADING';

/**
 * The profileLoading function is used to dispatch the PROFILE_LOADING action - it is a pure action function that simply dispatches the loading status.
 * @returns {TUserAction} Returns a Redux action to be processed by the userReducer.
 */
export const profileLoading = (): TUserAction => {
    return {
        type: PROFILE_LOADING
    };
};

/**
 * The PROFILE_LOADED action is fired when a user's profile loads.
 */
export const PROFILE_LOADED = 'PROFILE_LOADED';

/**
 * The profileLoaded function dispatches the PROFILE_LOADED action - it is a pure action function that simply dispatches the data.
 */
export const profileLoaded = (profile: TUserProfile): TUserAction => {
    return {
        type: PROFILE_LOADED,
        payload: profile
    };
};