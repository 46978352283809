/**
 * @component Tab.tsx
 * @description Renders an array of Sections sharing a tab title - the user can click between tabs.
 */

// Imports - React and types
import React, { useState } from 'react';
import { TViewMetadata } from '../features/view';
import { EMode } from '../features/global';

// Imports - UI components
import AppBar from '@material-ui/core/AppBar';
import MuiTabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Section from './Section';
import { Box, Typography, Grid } from '@material-ui/core';
import Button from './Button';
import ErrorBoundary from './ErrorBoundary';

// Props interface
interface ITabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

// Wrapped components and utility function
const TabPanel: React.FC<ITabPanelProps> = (props: ITabPanelProps) => {
    const { index, value, children, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box key={index} p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}
const a11yProps = (index: any) => {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`
    };
}

export default function Tabs(props: TViewMetadata): JSX.Element {
    // Destructure props and declare state variable for storing the current tab
    const { categoryAndScreen = '', view, tabsOrSteps, topButtons, bottomButtons, availableModes } = props;
    const [value, setValue] = useState(0);

    // Change event handler
    const handleChange = (_: React.ChangeEvent<{}>, value: any) => {
        setValue(value);
    };

    // If we have a Delete button in the view definition, we only keep it in View mode and if "delete" is in the list of available modes
    const filteredButtons = bottomButtons.filter((button) => {
        if (button.button_purpose !== 'delete') {
            return true;
        }
        else {
            return availableModes.indexOf('delete') > -1 ? true : false;
        }
    });

    // Render the tab series
    return (<ErrorBoundary correlationIdText="COMPONENTS/TABS/TAB_ERROR">
        <Grid item xs={12}>
            <Typography variant="h2" style={{ textTransform: 'capitalize' }}>{categoryAndScreen.replace(/-/g, ' ')}: {EMode[view]}</Typography>
        </Grid>
        <Grid item xs={12}>
            <br />
        </Grid>
        <Grid item xs={12} container alignItems="flex-start" alignContent="space-between" justify="flex-start" direction="row" spacing={2}>
            {(topButtons.length > 0 || (topButtons.length === 1 && topButtons[0].id === 'switch-mode' && availableModes.length > 1)) || bottomButtons.length > 0 ?
                <Grid item xs={2} style={{
                    maxHeight: '80vh', position: 'fixed', overflowY: "auto", background: 'rgba(255, 255, 255, 1)', zIndex: 1000,
                    border: '3px solid rgba(0, 0, 0, 0.3)', borderRadius: '3px', boxShadow: 'inset 0 0 6px rgba(181, 181, 181, 0.8)', width: '12vw'
                }}>
                    <Grid item container xs={12} alignItems="center" alignContent="center" justify="center">
                        {topButtons.map((button, index) => (
                            <Button key={index} button_purpose={button.button_purpose || 'navigation'} {...button} availableModes={availableModes} mode={view} fullWidth={true} />
                        ))}
                        <Grid item xs={12}>
                            <div>
                                <br />
                                <br />
                                <br />
                            </div>
                            {filteredButtons.map((button, index) => (
                                <Grid item key={index}><Button button_purpose={button.button_purpose || 'navigation'} {...button} mode={view} fullWidth={true} /><div><br /></div></Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid> : null}
            <Grid item xs={12} style={{ flexGrow: 1, width: '100%', paddingLeft: '15vw' }} >
                {tabsOrSteps.length > 1 ? <>
                    <AppBar position="static">
                        <MuiTabs value={value} variant="scrollable" scrollButtons="on" aria-label={`tabs-${view}-${categoryAndScreen}`} onChange={handleChange} >
                            {tabsOrSteps.map((tab, index) => <Tab key={index} label={tab.title} {...a11yProps(index)} />)}
                        </MuiTabs>
                    </AppBar>
                    {tabsOrSteps.map((tab, index) => (
                        <TabPanel key={index} value={value} index={index}>
                            {tab.sections.map((section, sectionIndex) => <Section key={sectionIndex} thisTabOrStep={value} heading={section.heading} componentsPerRow={section.componentsPerRow} components={section.components} />)}
                        </TabPanel>
                    ))} </> : <>
                    {tabsOrSteps[0].sections.map((section, sectionIndex) => <Section key={sectionIndex} thisTabOrStep={0} heading={section.heading} componentsPerRow={section.componentsPerRow} components={section.components} />)}
                </>}
            </Grid>
        </Grid>
    </ErrorBoundary>
    );
}