/**
 * @file features/form/useValidation
 * @description A custom React hook that dispatches default form data values and validates input on-the-fly. Should be used in components that accept typed/text input. Returns a change handler that should be attached to the input component.
 */

// Imports
import React, { ReactElement, ReactNode, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TFormData } from './types';
import { formEdit } from './actions';
import { EDataType, TCodeLang, TDataType, TParamType } from '../view';
import { TGlobalState, testStringStart } from '../global';
import { alertAction } from '../alert';
import paramValidateAndConvert from './paramValidateAndConvert';
import dayjs from 'dayjs';


// Declare and export the hook
const useValidation = (dataKey: string, required: boolean, alreadyMountedRef: React.MutableRefObject<boolean>, valueSetter?: React.Dispatch<React.SetStateAction<any>> | null, errorSetter?: React.Dispatch<React.SetStateAction<boolean>> | null, errorTextSetter?: React.Dispatch<React.SetStateAction<string>> | null, dataType?: TDataType, updateKeys?: string[] | string, defaultValue?: any, defaultValueKeys?: string[], defaultValueSeparator?: string, forceDefaultValueStart: boolean = false, staticValue?: string, label?: string, dateFormat?: string, selectOptions?: string[] | JSX.Element[], maskPlaceholder?: string, code?: TCodeLang) => {

    // Get relevant data from URL and Redux state
    const urlParams = new URLSearchParams(window.location.search);
    const dispatch = useDispatch();
    const { formData, paramDefinition = {
        key: dataKey,
        type: dataType || 'string',
        bodyData: true,
        urlParam: false,
        required: required,
        maskPlaceholder: maskPlaceholder
    } } = useSelector((state: TGlobalState) => {

        // Get form data
        const formValues = state.form.selectedValues;
        let relevantValues: TFormData = {
            [dataKey]: formValues?.[dataKey] !== undefined && formValues?.[dataKey] !== null ? formValues?.[dataKey] : ''
        };
        const updateKeysToUse = updateKeys instanceof Array ? updateKeys : typeof updateKeys === 'string' ? [updateKeys] : [];
        for (let key of updateKeysToUse) {
            relevantValues[key] = formValues?.[key] !== undefined && formValues?.[key] !== null ? formValues?.[key] : '';
        }
        for (let key of defaultValueKeys || []) {
            relevantValues[key] = formValues?.[key] !== undefined && formValues?.[key] !== null ? formValues?.[key] : '';
        }

        // Get the relevant parameter definition (if any)
        let param: TParamType | undefined;
        const queries = state.view.currentView?.query || [];
        // We only return the first match - for the purposes of real-time checking, we assume that the param definitions match up
        for (let query of queries) {
            const dataKeyParam = query.parameters.find(param => param.key === dataKey && param.type === dataType);
            if (dataKeyParam) {
                param = dataKeyParam;
            }
            if (updateKeys instanceof Array) {
                for (let key of updateKeys || []) {
                    const updateKeyParam = query.parameters.find(param => param.key === key && param.type === dataType);
                    if (updateKeyParam) {
                        param = updateKeyParam;
                    }
                }
            }
            else if (updateKeys) {
                const updateKeyParam = query.parameters.find(param => param.key === updateKeys && param.type === dataType);
                if (updateKeyParam) {
                    param = updateKeyParam;
                }
            }
        }

        return {
            formData: relevantValues,
            paramDefinition: param
        };
    });
    const selectedValue = formData[dataKey];

    // Calculate the default value
    let defaultValueStr = staticValue || defaultValue || '';
    if (!staticValue && defaultValueKeys && defaultValueKeys instanceof Array && defaultValueKeys.length > 0) {
        let defaultValues = [];
        for (let key of defaultValueKeys) {
            defaultValues.push(formData[key]);
        }
        defaultValueStr = defaultValues.join(defaultValueSeparator || ' ');
        if (dataType === 'date' && dateFormat) {
            defaultValueStr = dayjs(defaultValueStr, dateFormat);
        }
    }
    if (selectOptions && selectOptions.indexOf(defaultValueStr) === -1) {
        defaultValueStr = selectOptions[0];
    }

    // Hook to deal with default value dispatch, value changes, etc
    const previousValue = useRef('');
    useEffect(() => {
        let mounted = true;

        // Utility func for dispatching - avoids repetition
        const dispatchValues = (mainKey: string, dispatchValue: any, updates?: string[] | string): void => {
            // console.log('DEBUG: useValidation useEffect dispatchValue:', dispatchValue, 'mainKey:', mainKey);
            if (!dispatchValue && ((dataType === 'code' && code === 'json') || dataType === 'object')) {
                dispatchValue = '{}';
            }
            else if (!dispatchValue && dataType === 'array') {
                dispatchValue = '[]';
            }
            else if (dataType !== 'boolean' && (dispatchValue === undefined || dispatchValue === null)) {
                dispatchValue = '';
            }
            else if (dataType === 'boolean' && !dispatchValue) {
                dispatchValue = false;
            }
            dispatch(formEdit({
                key: mainKey,
                value: dispatchValue
            }));
            if (updates && updates instanceof Array) {
                for (let key of updates) {
                   dispatch(formEdit({
                       key: key,
                       value: dispatchValue
                   }));
                }
            }
            else if (updates && typeof updates === 'string') {
                dispatch(formEdit({
                    key: updates,
                    value: dispatchValue
                }));
            }
            let selectIndex = 0;
            if (selectOptions && selectOptions.indexOf(dispatchValue) > -1) {
                selectIndex = selectOptions.indexOf(dispatchValue);
            }
            previousValue.current = dispatchValue;
            // dispatch(formLoaded());
            valueSetter && valueSetter(selectOptions ? selectIndex : dispatchValue);
        };

        if (mounted) {
            // Required field with nothing - dispatch default or show an error
            if (required && (selectedValue === '' || selectedValue === undefined || selectedValue === null)) {
                // console.log('required field');
                if (defaultValueStr) {
                    dispatchValues(dataKey, defaultValueStr, updateKeys);
                }
                else {
                    errorSetter && errorSetter(true);
                    errorTextSetter && errorTextSetter('Required field');
                }
            }

            // forceDefaultValueStart field
            else if (forceDefaultValueStart && !testStringStart(defaultValueStr, selectedValue)) {
                // console.log('forcing string start:', dataKey, defaultValueStr, selectedValue);
                dispatchValues(dataKey, defaultValueStr, updateKeys);
                dispatch(alertAction({
                    severity: 'warning',
                    message: `The value in "${label || dataKey}" must begin with the text that has just been auto-filled.`,
                    autoHideDuration: 6000,
                    display: true
                }));
            }

            else if (selectedValue !== previousValue.current) {
                // console.log('values differ:', dataKey, selectedValue, previousValue.current, defaultValueStr);
                dispatchValues(dataKey, selectedValue, updateKeys);
            }
        }

        // Cleanup function
        return () => {
            mounted = false;
        }
    }, [dataKey, updateKeys, forceDefaultValueStart, selectedValue, label, dispatch, valueSetter, staticValue, selectOptions, alreadyMountedRef, dataType, code, required, errorSetter, errorTextSetter, defaultValueStr]);

    // Cleanup useEffect hook
    useEffect(() => {
        return () => {
            alreadyMountedRef.current = false;
        }
    }, [alreadyMountedRef]);

    // Declare the change handler to return - this one dispatches values and sets value state
    function handleChange(arg: string): void;
    function handleChange(arg: React.ChangeEvent<HTMLInputElement>): void;
    function handleChange(arg: React.ChangeEvent<{ name?: string; value: unknown }>, child: ReactNode): void;
    function handleChange(arg: React.ChangeEvent<{}>, child: unknown): void;
    function handleChange(arg: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown } | {}> | string, child?: ReactNode | unknown): void {
        // console.log('DEBUG: handleChange arg:', arg);
        // console.log('DEBUG: handleChange child:', child);
        // If we have a staticValue, we can't change it
        if (staticValue) {
            dispatch(alertAction({
                severity: 'warning',
                message: `The value in "${label || dataKey}" is auto-filled and cannot be changed.`,
                autoHideDuration: 6000,
                display: true
            }));
            return;
        }
        // console.log('DEBUG: handleChange typeof "child" arg:', typeof child, child);
        let newValue = typeof child === 'boolean' ? child : typeof child !== 'boolean' && child ? (child as ReactElement)?.props.children || '' : typeof arg === 'object' ? (arg as React.ChangeEvent<any>).currentTarget.value : typeof arg === 'string' && arg ? arg : '';
        // console.log('DEBUG: newValue before processing:', newValue);
        const newIndex = typeof child !== 'boolean' ? typeof child === 'object' && typeof (child as ReactElement)?.props.value === 'number' ? (child as ReactElement)?.props.value : typeof (child as ReactElement)?.props.children === 'string' && selectOptions ? selectOptions.indexOf((child as ReactElement)?.props.children) : null : null;
        // console.log('DEBUG: newIndex before processing:', newIndex);

        // Validate the data
        try {
            const testData: TFormData = {
                [paramDefinition.key]: newValue
            };
            // console.log('testDAta:', testData);
            newValue = paramValidateAndConvert(paramDefinition, testData, urlParams, label);
            // console.log('DEBUG: converted param:', newValue);
            errorSetter && errorSetter(false);
            errorTextSetter && errorTextSetter('');
        } catch (error) {
            errorSetter && errorSetter(true);
            errorTextSetter && errorTextSetter(`Expected data type: ${EDataType[paramDefinition.type]}`);
        }
        // console.log('DEBUG: useValidation handleChange newValue before setting and dispatch:', newValue);
        valueSetter && valueSetter(typeof child !== 'boolean' && child && newIndex !== null ? newIndex : newValue || '');
        dispatch(formEdit({
            key: dataKey,
            value: newValue
        }));
        if (updateKeys && updateKeys instanceof Array) {
            for (let key of updateKeys) {
                dispatch(formEdit({
                    key: key,
                    value: newValue
                }));
            }
        }
        else if (updateKeys && typeof updateKeys === 'string') {
            dispatch(formEdit({
                key: updateKeys,
                value: newValue
            }));
        }
    };

    // Declare the blur handler to return - this one shows "alert" messages
    // We run this one after a component is out of focus so as not to distract the user
    const handleBlur = (arg: React.SyntheticEvent<any>) => {
        try {
            const testData: TFormData = {
                [paramDefinition.key]: selectedValue
            };
            // console.log('DEBUG handleBlur testData, paramDefinition and urlParams:', testData, paramDefinition, urlParams);
            paramValidateAndConvert(paramDefinition, testData, urlParams, label);
            errorSetter && errorSetter(false);
            errorTextSetter && errorTextSetter('');
        } catch (error) {
            dispatch(alertAction({
                severity: 'warning',
                message: error.message,
                autoHideDuration: 6000,
                display: true
            }));
            errorSetter && errorSetter(true);
            errorTextSetter && errorTextSetter(`Expected data type: ${EDataType[paramDefinition.type]}`);
        }
    };
    return {
        handleChange: handleChange,
        handleBlur: handleBlur
    };
};
export default useValidation;