/**
 * @file features/error/actions
 * @description Contains Redux actions for Errors.
 */

// Imports
import { TErrorState, TErrorAction } from './types';

/**
 * The SHOW_ERROR action displays an error.
 */
export const SHOW_ERROR = 'SHOW_ERROR';

/**
 * The HIDE_ERROR action hides an error.
 */
export const HIDE_ERROR = 'HIDE_ERROR';

/**
 * showError is used to dispatch a SHOW_ERROR action - it is a pure action function that doesn't use async code and simply dispatches the error data provided.
 * @param {TErrorState} error The error object to dispatch. Note that for this action, error.display is set to true so the error always shows.
 * @returns {TErrorAction} Returns a Redux action to be processed by the errorReducer.
 */
export const showError = (error: TErrorState): TErrorAction => {
    error.display = true;
    return {
        type: SHOW_ERROR,
        payload: error
    };
};

/**
 * hideError is used to dispatch a HIDE_ERROR action - it is a pure action function that doesn't use async code and simply dispatches the action. No data is cleared/lost except for the "display" property, which is set to false by the errorReducer to hide the error screen, unless a payload is provided. 
 * @param {TErrorState?} payload If a payload is provided, it will overwrite properties (except the "display" property which is always false after this action).
 * @returns {TErrorAction} Returns a Redux action to be processed by the errorReducer.
 */
export const hideError = (error?: TErrorState): TErrorAction => {
    return {
        type: HIDE_ERROR,
        payload: error
    };
};