/**
 * @file featurees/file/fetchFile.ts
 * @description Function for fetching a file from the back-end.
 */

import { fileDownloadUrl } from '../global';
import { authProvider } from '../auth';
import { TFileUuids, TFileITBlob } from './types';
import { codeToStatusText, parseError, showError, TErrorState, validateTErrorState } from '../error';

const fetchFile = async (fileIds: TFileUuids, dispatch: Function) => {
    try {
        const token = (await authProvider.getAccessToken()).accessToken;
        const init = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(fileIds)
        };
        const result = await fetch(fileDownloadUrl, init);
        if (result.ok) {
            const fileData = (await result.json()).data;
            const returnBlob: TFileITBlob = {
                categoryId: fileData.metadata.categoryUuid,
                fileId: fileData.metadata.metadata.uuid,
                fileName: fileData.metadata.fileName,
                mimeType: fileData.metadata.mimeType,
                title: fileData.metadata.title,
                description: fileData.metadata.description,
                projectId: fileData.metadata.projectId || 0,
                workPackageId: fileData.metadata.workPackageId || 0,
                stageitOrderId: fileData.metadata.stageitOrderId || 0,
                stageitServiceId: fileData.metadata.stageitServiceId || 0,
                stageitReportId: fileData.metadata.stageitReportId || 0,
                stageItAssetId: fileData.metadata.stageItAssetId || 0,
                mappingId: fileData.metadata.mappingId || '',
                fileContents: fileData.file || '',
                fileDate: fileData.metadata.fileDate,
                createdDate: fileData.metadata.metadata.createdDate,
                modifiedDate: fileData.metadata.metadata.modifiedDate
            };
            return returnBlob;
        }
        else {
            const status = codeToStatusText(result.status);
            let responseBody = null;
            let message = '';
            let correlationId = 'FEATURES/FILES/FETCH_HTTP_ERROR';
            try {
                responseBody = await result.json();
            } catch (_) {
                responseBody = await result.text();
            }
            if (typeof responseBody === 'string') {
                message = responseBody;
            }
            else {
                if (responseBody.message) {
                    message = responseBody.message;
                }
                if (responseBody.correlationId) {
                    correlationId = responseBody.correlationId;
                }
            }
            const err: TErrorState = {
                status: status,
                message: message,
                correlationId: correlationId,
                display: true,
                showResetOptions: true
            };
            dispatch(showError(err));
            return err;
        }
    } catch (err) {
        if (validateTErrorState(err)) {
            dispatch(showError(parseError(err)));
            return parseError(err);
        }
        else {
            const errorObj: TErrorState = {
                status: 'Service Error',
                message: err.message,
                stack: err.stack,
                correlationId: 'FEATURES/FILES/FETCH_ERROR',
                display: true,
                showResetOptions: true
            };
            dispatch(showError(errorObj));
            return errorObj;
        }
    }
};
export default fetchFile;