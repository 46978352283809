/**
 * @file features/grids/reducer.ts
 * @description Contains the reducer for data grids.
 */

// Imports
import { TFormData } from '../form';
import { DATAGRID_LOADING, DATAGRID_LOADED, DATAGRID_EDIT } from './actions';
import gridsInitialState from './initialState';
import { TAllGrids, TGridState, TGridAction, TCellDataDispatch } from './types';

/**
 * The grid reducer updates Redux state base on actions/payloads.
 * @param {TGridState} state Previous state object.
 * @param {TGridAction?} action Action to process.
 * @returns {TGridState} Returns the new state.
 */
const gridsReducer = (state = gridsInitialState, action: TGridAction | undefined): TAllGrids => {
    if (!action) {
        return state;
    }
    const { type, payload } = action;
    switch (type) {
        case DATAGRID_LOADING: {
            const newState = JSON.parse(JSON.stringify(state));
            const { dataKey, grid, label } = payload as TGridState;
            newState[dataKey] = {
                dataKey: dataKey,
                label: label,
                grid: grid
            };
            newState[dataKey].grid.isLoading = true;
            return newState;
        }
        case DATAGRID_LOADED: {
            const newState = JSON.parse(JSON.stringify(state));
            const { dataKey, label } = payload as TGridState;
            newState[dataKey] = {
                dataKey : dataKey,
                label: label,
                grid: payload
            };
            newState[dataKey].grid.isLoading = false;
            return newState;
        }
        case DATAGRID_EDIT: {
            const newState = JSON.parse(JSON.stringify(state));
            const { dataKey, column, row, value } = payload as TCellDataDispatch;
            if ((newState[dataKey]?.grid?.data as TFormData[])[row]?.[column]) {
                (newState[dataKey].grid?.data as TFormData[])[row][column] = value;
            }
            return newState;
        }
        default:
            return state;
    }
};
export default gridsReducer;