/**
 * @file features/files/types.ts
 * @description Contains type declarations used by the file upload/download feature.
 */

/**
 * @type TMimeType
 * @enum
 * @description List of MIME types supported for file-related operations.
 */
export enum EMimeType {
    'text/plain' = 'TXT',
    'text/csv' = 'CSV',
    'image/png' = 'PNG',
    'image/jpeg' = 'JPG',
    'application/msword' = 'DOC',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = 'DOCX',
    'application/json' = 'JSON',
    'text/json' = 'JSON',
    'application/pdf' = 'PDF',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' = 'XLSX',
    'application/vnd.ms-excel' = 'XLS',
    'application/xml' = 'XML',
    'text/xml' = 'XML',
    'application/zip' = 'ZIP'
}
export type TMimeType = keyof typeof EMimeType;


/**
 * @type TFileITCategory
 * @description Type for a file category. The UUID is used for file uploads/downloads. The MIME type array determines what MIME types are accepted for the category. 
 */
export type TFileITCategory = {
    categoryUuid: string,       // UUID of the category used for file upload to StageIT
    categoryId: number,         // A simpler, integer ID used internally by the specific app
    categoryName: string,
    acceptedMimeTypes: (keyof typeof EMimeType)[]
};

/**
 * @type TFileUuids
 * Type with just the FileIT Category UUID and Blob UUID. Used for Fetch requests and by the TFileITBlob type.
 */
export type TFileUuids = {
    categoryId: string,     // This is the UUID in TFileITCategory
    fileId?: string         // Optional as it won't exist yet for files that haven't been uplooaded
}

/**
 * @type TFileITBlob
 * Type used to describe the format of the data we send to upload a Blob. This is also what our file upload/download components store in their state.
 */
export type TFileITBlob = TFileUuids & {
    fileName: string,
    mimeType: keyof typeof EMimeType,
    title: string,
    description: string,
    projectId?: number,
    workPackageId?: number,
    stageitOrderId?: number,
    stageitServiceId?: number,
    stageitReportId?: number,
    stageItAssetId?: number,
    mappingId?: string,             // IDs that can be used for record mapping as needed
    thisVersion?: number,
    fileContents?: string           // When a file comes in or goes out, it's a Base64-encoded string.
    fileDate?: Date | string,
    createdDate?: Date  | string,
    modifiedDate?: Date | string
};