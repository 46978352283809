/**
 * @component Welcome.tsx
 * @description Default "Welcome" screen component.
 */

// Imports
import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TGlobalState } from '../features/global';

// Export the component
export default function Welcome() {
    const { preferred_start_url, loading, profile } = useSelector((state: TGlobalState) => {
        return {
            profile: state.user.profile ? true : false,
            preferred_start_url: state.user.profile?.preferred_start_url || '', 
            loading: state.user.loading
        };
    });

    if (profile && preferred_start_url) {
        window.location.href = preferred_start_url;
    }

    return (
        <Grid item container direction="column" justify="flex-start" alignContent="center" alignItems="center">
            <Typography variant="h2">Welcome!</Typography>
            <br />
            {profile ?
                    <>
                        <Typography variant="h4">You are being redirected to the home page specified in your profile...</Typography>
                    </>
            : loading ?
                    <>
                        <Typography variant="h3" align="center">Logging you in and loading your profile...</Typography><CircularProgress color="secondary" size={60} />
                    </>
            : <></>}
        </Grid>
    );
}