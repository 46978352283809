/**
 * @file features/form/initialState
 * @description Contains the initial state for the Form reducer.
 */

// Imports
import { TFormState } from './types';

const formInitialState: TFormState = {
    loading: false,
    ready: false,
    selectedValues: null,
    originalValues: null
};
export default formInitialState;