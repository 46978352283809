/**
 * @file features/alert/actions
 * @description Contains Redux actions for Alerts.
 */

// Imports
import { TAlertState, TAlertAction } from './types';

/**
 * The ALERT action type shows an alert.
 */
export const ALERT = 'ALERT';

/**
 * The alertAction is used to dispatch an alert action - it is a pure action function that doesn't use async code and simply dispatches the alert data provided.
 * @param {TAlertState} alert The alert object to dispatch.
 * @returns {TAlertAction} Returns a Redux action to be processed by the alertReducer.
 */
export const alertAction = (alert: TAlertState): TAlertAction => {
    return {
        type: ALERT,
        payload: alert
    };
};