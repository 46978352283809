import { TComponent, TLinkComponent, TDateComponent, EDatePicker } from './types';

/**
 * @function isTDateComponent Checks if an object is of the TDateComponent type.
 * @param {TComponent|TLinkComponent|TDateComponent} testComponentProps The component props to test.
 * @returns {boolean} Returns true if the component props are TDateComponent compliant
 */
export default function isTDateComponent(testComponentProps: TComponent|TLinkComponent|TDateComponent): testComponentProps is TDateComponent {
    if (!testComponentProps) {
		return false;
	}
    const testTDate = testComponentProps as TDateComponent;
    if (
        testTDate.dataKey && typeof testTDate.dataKey === 'string' && testTDate.dataKey.length > 0 && testTDate.dateFormat && typeof testTDate.dateFormat === 'string' && testTDate.dateFormat.length > 0 && 
        (
            (testTDate.datePickerType && EDatePicker[testTDate.datePickerType] > -1) || !testTDate.datePickerType
        )
    ) {
        return true;
    }
    else {
        return false;
    }
}