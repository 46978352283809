/**
 * @component CopyrightFooter.tsx
 * @description Shows the copyright footer on every page. The text is configurable via the REACT_APP_COPYRIGHT_TEXT environment variable, but is (C) 2020-2021 Fujitsu Australia Ltd by default.
 */

// Imports
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

// Export the component
const CopyrightFooter: React.FC = () => {
    let innerText: JSX.Element;
    if (!process.env.REACT_APP_COPYRIGHT_TEXT)
    {
        innerText = <>&copy;&nbsp;2021 Fujitsu Australia Ltd</>;
    }
    else
    {
        innerText = <>process.env.REACT_APP_COPYRIGHT_TEXT</>;
    }
    return (<>
        <Divider style={{ marginTop: '2vh' }} />
        <Typography variant="subtitle1" style={{ textAlign: 'center'}}>
            {innerText}
        </Typography>
    </>);
};
export default CopyrightFooter;