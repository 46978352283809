/**
 * @file Theme.tsx
 * @description Defines our custom Material UI theme.
 */

// Imports
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { Palette } from '@material-ui/core/styles/createPalette';
import { Typography } from '@material-ui/core/styles/createTypography';
import { Overrides } from '@material-ui/core/styles/overrides';
import './index.css';

// Declare the createMuiTheme module
declare module '@material-ui/core/styles/createMuiTheme' {
	interface Theme {
		palette: Palette;
		typography: Typography;
		overrides?: Overrides;
	}
}

// Export the theme
export default createMuiTheme({
	palette: {
		primary: {
			light: '#ccc',
			main: '#999',
			dark: '#333',
			contrastText: '#fff'
		},
		secondary: {
			light: 'rgba(213, 83, 83, 1)',
			main: 'rgba(254, 0, 0, 1)',
			dark: 'rgba(176, 0, 0, 1)',
			contrastText: '#fff'
		},
		error: {
			light: '#e57373',
			main: '#f44336',
			dark: '#d32f2f',
			contrastText: '#fff'
		},
	},
	typography: {
		h1: {
			fontSize: '1.75rem',
			fontFamily: 'Fujitsu-Sans-Bold',
			textAlign: 'center'
		},
		h2: {
			fontSize: '1.5rem',
			fontFamily: 'Fujitsu-Sans-Bold',
			textAlign: 'center'
		},
		h3: {
			fontSize: '1.25rem',
			fontFamily: 'Fujitsu-Sans-Bold',
			textAlign: 'center'
		},
		h4: {
			fontSize: '1.0rem',
			fontFamily: 'Fujitsu-Sans-Bold',
			textAlign: 'left',
			fontStyle: 'italic'
		},
		h5: {
			fontSize: '0.8rem',
			fontFamily: 'Fujitsu-Sans-Bold',
			textAlign: 'left'
		},
		body1: {
			fontSize: '0.8rem',
			fontFamily: 'Fujitsu-Sans-Regular',
			textAlign: 'left'
		},
		body2: {
			fontSize: '0.8rem',
			fontFamily: 'Fujitsu-Sans-Regular',
			textAlign: 'center',
			display: 'inline'
		},
		subtitle1: {
			fontSize: '0.7rem',
			fontFamily: 'Fujitsu-Sans-Regular',
			textAlign: 'center'
		},
		subtitle2: {
			fontSize: '0.7rem',
			fontFamily: 'Fujitsu-Sans-Regular',
			textAlign: 'left',
			color: '#999',
			fontStyle: 'italic'
		},
		fontFamily: 'Fujitsu-Sans-Regular'
	},
	overrides: {
		MuiButton: {
			root: {
				textTransform: 'none',
			},
		},
		MuiMenuItem: {
			root: {
				whiteSpace: 'pre-line',
			},
		},
		MuiLink: {
			root: {
				color: 'red',
				fontWeight: 'bold',
				'&:hover': {
					color: 'rgba(176, 0, 0, 1)',
				},
				'&:focus': {
					color: '#333',
					border: '1px solid #333'
				},
			},
		},
	},
});