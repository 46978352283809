/**
 * @component RenderIcon.tsx
 * @description Displays an icon based on a list of ones we support and optionally renders its name in the system next to it.
 */

import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { isTIconName, TComponentCollection, TIconName, TRenderIconComponent } from '../features/view';
import { TGlobalState } from '../features/global';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

// Imports - Icons
import HomeIcon from '@material-ui/icons/Home';
import DashBoardIcon from '@material-ui/icons/Dashboard';
import PeopleIcon from '@material-ui/icons/People';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ExtensionIcon from '@material-ui/icons/Extension';
import HelpIcon from '@material-ui/icons/Help';
import BuildIcon from '@material-ui/icons/Build';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import FunctionIcon from '@material-ui/icons/Functions';
import LocationIcon from '@material-ui/icons/LocationCity';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import NavIcon from '@material-ui/icons/Navigation';
import ForwardIcon from '@material-ui/icons/ArrowForward';
import BackIcon from '@material-ui/icons/ArrowBack';
import RefreshIcon from '@material-ui/icons/Refresh';
import RestoreIcon from '@material-ui/icons/RestorePage';
import MenuIcon from '@material-ui/icons/Menu';
import HistoryIcon from '@material-ui/icons/History';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FastForwardIcon from '@material-ui/icons/FastForward';
import FastRewindIcon from '@material-ui/icons/FastRewind';
import FileIcon from '@material-ui/icons/AttachFile';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';


// Function for dynamically-returning an icon based on the icon name
const RenderIcon: React.FC<TRenderIconComponent> = forwardRef((props: TRenderIconComponent, ref) => {
	const availableIcons: TComponentCollection = {
		HomeIcon: forwardRef((props, ref) => <HomeIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		DashBoardIcon: forwardRef((props, ref) => <DashBoardIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		PeopleIcon: forwardRef((props, ref) => <PeopleIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		AccountTreeIcon: forwardRef((props, ref) => <AccountTreeIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		ExtensionIcon: forwardRef((props, ref) => <ExtensionIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		HelpIcon: forwardRef((props, ref) => <HelpIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		BuildIcon: forwardRef((props, ref) => <BuildIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		FlightTakeoffIcon: forwardRef((props, ref) => <FlightTakeoffIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		FunctionIcon: forwardRef((props, ref) => <FunctionIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		LocationIcon: forwardRef((props, ref) => <LocationIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		DeleteIcon: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		SaveIcon: forwardRef((props, ref) => <SaveIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		NavIcon: forwardRef((props, ref) => <NavIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		BackIcon: forwardRef((props, ref) => <BackIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		RefreshIcon: forwardRef((props, ref) => <RefreshIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		RestoreIcon: forwardRef((props, ref) => <RestoreIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		MenuIcon: forwardRef((props, ref) => <MenuIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		HistoryIcon: forwardRef((props, ref) => <HistoryIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		ChevronLeftIcon: forwardRef((props, ref) => <ChevronLeftIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		ChevronRightIcon: forwardRef((props, ref) => <ChevronRightIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		ForwardIcon: forwardRef((props, ref) => <ForwardIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		FastForwardIcon: forwardRef((props, ref) => <FastForwardIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		FastRewindIcon: forwardRef((props, ref) => <FastRewindIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		CloudUploadIcon: forwardRef((props, ref) => <CloudUploadIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		FileIcon: forwardRef((props, ref) => <FileIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		DownloadIcon: forwardRef((props, ref) => <ArrowDownwardIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		SearchIcon: forwardRef((props, ref) => <SearchIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		CancelIcon: forwardRef((props, ref) => <CancelIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />) as OverridableComponent<any>,
		'None selected': forwardRef((props, ref) => <></>)  as OverridableComponent<any>
	};
	const { staticValue, dataKey, color = 'secondary', showName = false, className, style } = props;
	const iconName: TIconName = useSelector((state: TGlobalState) => {
		const selectedValues = state.form.selectedValues;
		// staticValue overrides everything
		if (staticValue && isTIconName(staticValue)) {
			return staticValue;
		}
		// Otherwise, use the value at dataKey if it's valid
		else if (selectedValues && dataKey && isTIconName(selectedValues[dataKey])) {
			return selectedValues[dataKey];
		}
		// Otherwise just return string indicating nothing selected
		else {
			return 'None selected';
		}
	});
	const SpecificIcon = availableIcons[iconName];

	switch (showName) {
		case 'above':
			return (
				<Grid item container direction="column" justify="flex-start" alignItems="center" alignContent="center">
					<Typography variant="subtitle1">{iconName}</Typography>
					<SpecificIcon color={color} style={style} className={className} ref={ref} />
				</Grid>
			);
		case 'below':
			return (
				<Grid container direction="column" justify="flex-start" alignItems="center" alignContent="center">
					<SpecificIcon color={color} style={style} className={className} ref={ref} />
					<Typography variant="subtitle1">{iconName}</Typography>
				</Grid>
			);
		case 'left':
			return (
				<Grid item container direction="row" justify="flex-start" alignItems="center">
					<Typography variant="subtitle1">{iconName}&nbsp;</Typography>
					<SpecificIcon color={color} style={style} className={className} ref={ref} />
				</Grid>
			);
		case 'right':
			return (
				<Grid item container direction="row" justify="flex-start" alignItems="center">
					<SpecificIcon color={color} style={style} className={className} ref={ref} />
					<Typography variant="subtitle1">&nbsp;{iconName}</Typography>
				</Grid>
			);
		default:
			return <SpecificIcon color={color} style={style} className={className} ref={ref} />;
	}
});
export default RenderIcon;