/**
 * @component UserMenu.tsx
 * @description User menu that shows on the top right corner of the top app bar.
 */

// React, Redux and other functional imports
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { authProvider } from '../features/auth';
import { useProfile } from '../features/user';
import { TGlobalState } from '../features/global';

// Visual component imports
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import LockIcon from '@material-ui/icons/Lock';

// Define the component itself
export default function UserMenu(): JSX.Element {
    // Set state, hooks, etc.
    const { profile, loading, errorDisplayed } = useSelector((state: TGlobalState) => {
        return {
            profile: state.user.profile,
            loading: state.user.loading,
            errorDisplayed: state.error.display
        };
    });
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    // Memoise values
    const { profileMemo, loadingMemo, errorMemo } = React.useMemo(() => {
        return {
            profileMemo: profile,
            loadingMemo: loading,
            errorMemo: errorDisplayed
        };
    }, [profile, loading, errorDisplayed]);

    // useProfile hook
    useProfile(profileMemo, loadingMemo);


    // Event handlers for opening/closing the menu and logging out
    const handleClick = (event: React.MouseEvent): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: React.MouseEvent): void => {
        setAnchorEl(null);
    };
    const handleLogout = (): void => {
        setAnchorEl(null);
        authProvider.logout();
    };

    

    return (
        <Grid container direction="row" justify="flex-end" onClick={!profileMemo && errorMemo ? handleLogout : undefined}>
            {/*If we have a profile, show the user dropdown*/}
            {profileMemo ? <>
                <Avatar alt={`${profileMemo.first_name} ${profileMemo.last_name}`} src={profileMemo.profile_picture} style={{ marginRight: '10px' }} />
                <Button
                    aria-owns={anchorEl ? 'simple-menu' : undefined}
                    aria-haspopup='true'
                    onClick={handleClick}
                >
                    {`${profileMemo.first_name} ${profileMemo.last_name}`}
                    <ExpandMoreIcon />
                </Button>
                <Menu
                    id="user-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
            {/*     <Link href="/home/my-profile?mode=view" color="secondary">
                        <MenuItem onClick={handleClose}><FingerprintIcon />Profile</MenuItem>
                    </Link> 
                */}
                    <Link href="#" color="secondary">
                        <MenuItem onClick={handleLogout}><LockIcon />Logout</MenuItem>
                    </Link>
                </Menu>
            </> : !profileMemo && errorMemo ? <Link href="#" color="error" onClick={handleLogout}><FingerprintIcon color="error" />&nbsp;Profile Load Error - Click Here to logout and select a different account</Link> : <></>}
        </Grid>
    );
}