/**
 * @component Screen.tsx
 * @description This component fetches a view using the useView hook. It then runs any "on load" queries using the useQuery hook. Finally, it renders the view - either as a wizard with steps, or as a series of tabs through which the user can click.
 */

// Imports
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Tabs from './Tabs';
import Wizard from './Wizard';
import { testTMode, TGlobalState } from '../features/global';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TMode } from '../features/global';
import { useView } from '../features/view';
import { useQuery } from '../features/form';
import { Grid } from '@material-ui/core';

// Declare and export the component
export default function Screen() {

    // Get URL params for the category, screen and view mode
    const { category_name, screen_name } = useParams<{category_name: string, screen_name: string}>();
    let viewMode = new URLSearchParams(window.location.search)?.get('mode') || 'gridview';
    if (!testTMode(viewMode)) {
        viewMode = 'gridview' as TMode;
    }
    const categoryAndScreen = `${category_name}:-${screen_name}`;
    useView(viewMode as TMode, categoryAndScreen);
    useQuery();

    // Hook into Redux state
    const { displayError, viewReady, thisScreen, currentView } = useSelector((state: TGlobalState) => {
        let thisScreen = null;
        if (!state.screens.loading && state.screens.available.length > 0) {
            const foundScreen = state.screens.available.find(screen => screen.category_name.toLowerCase().replace(/[^a-z0-9]/g, '-') === category_name && screen.screen_name.toLowerCase().replace(/[^a-z0-9]/g, '-') === screen_name && screen.available_views.indexOf(viewMode as TMode) > -1);
            if (foundScreen) {
                thisScreen = foundScreen;
            }
        }
        return {
            displayError: state.error.display,
            profileLoading: state.user.loading,
            viewReady: state.view.ready,
            thisScreen: thisScreen,
            currentView: state.view.currentView
        };
    });

    // If there's an error, show nothing here - the ErrorComponent will show it
    if (displayError) {
        return (<></>);
    }

    // Show the loading screen if our view isn't ready yet
    else if (!viewReady) {
        return (
            <Grid item xs={12} container direction="column" alignItems="center" justify="flex-start">
                <Typography variant="h3" align="center">Loading...</Typography>
                <CircularProgress color="secondary" size={60} />
            </Grid>
        );
    }

    // If the screen is loaded and we have a view, render it
    else if (thisScreen && currentView) {
        switch (viewMode) {
            case 'newwizard':
            case 'editwizard':
            case 'wizard':
                return (<Wizard {...currentView} categoryAndScreen={categoryAndScreen} availableModes={thisScreen.available_views} />)
            case 'dashboard':
            case 'edit':
            case 'gridedit':
            case 'gridview':
            case 'new':
            case 'view':
            default:
                return (<Tabs {...currentView} categoryAndScreen={categoryAndScreen} availableModes={thisScreen.available_views} />);
        }
    }

    // Fallback - we shouldn't reach this unless someone is intentionally messing with the state so we return nothing
    return (<></>);
}