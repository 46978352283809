/**
 * @component EditMasked.tsx
 * @description This is a masked input component that locks input to a specific mask. Invalid values that don't match the mask pattern aren't dispatched, so that only valid values can go into the field.
 */

// Imports
import React, { ForwardedRef, forwardRef, MutableRefObject, RefObject, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import InputMask from 'react-input-mask';
import Tooltip from '@material-ui/core/Tooltip';
import { useValidation } from '../features/form';
import { EDataType, TMaskedInputComponent } from '../features/view';
import AdornEnd from './AdornEnd';
import { Typography } from '@material-ui/core';

// Declare the component with a forwardRef
const EditMasked: React.FC<TMaskedInputComponent> = forwardRef((props: TMaskedInputComponent, ref: ForwardedRef<HTMLInputElement>) => {
    // Destructure props
    const { id, label, showLabel, dataKey, defaultValue, defaultValueKeys, forceDefaultValueStart = false, defaultValueSeparator, required = false, style, className, updateKeys, dataType, staticValue, format, thousandSeparator, maskChar, formatChars, decimalScale, prefix, allowEmptyFormatting, decimalSeparator, disabled = false, maskPlaceholder, alreadyMounted } = props;

    // Set internal state variables
    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [helperText, setHelperText] = useState('');

    // We need to make sure we only pass primitives to these components to avoid unexpected behaviour, so stringify any objects
    const valueConvert = (value: any) => {
        if (typeof value === 'object') {
           setValue(JSON.stringify(value));
        }
        else {
            setValue(value);
        }
    };

    // Get the event handlers from the useValidation hook
    const { handleChange, handleBlur } = useValidation(dataKey, required, alreadyMounted as MutableRefObject<boolean>, valueConvert, setError, setHelperText, dataType, updateKeys, defaultValue, defaultValueKeys, defaultValueSeparator, forceDefaultValueStart, staticValue, label, undefined, undefined, maskPlaceholder);

    // Configure mask details & render the correct mask based on datatype
    switch (dataType) {
        case 'phone': {
            // For a phone number, we use the NumberFormat component and require +## ### ### ### input
            return (
                <Tooltip
                    title={disabled ? 'Editing locked' : showLabel !== false && label ? label : `Enter ${EDataType[dataType]}`}
                    aria-label={id}
                >
                    <InputMask
                        aria-invalid={error}
                        mask='+99 999 999 999'
                        maskChar="_"
                        disabled={disabled}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={Object.assign({}, style, { width: '100%' })}
                        className={className}
                    >
                        {(inputProps: TMaskedInputComponent) => (
                            <TextField {...inputProps} label={label} required={required} fullWidth={true} error={error} helperText={helperText} disabled={disabled} InputProps={{
                                endAdornment: <AdornEnd disabled={disabled} dataType={dataType} error={error} />
                            }} ref={ref} />
                        )}
                    </InputMask>
                </Tooltip>
            );
        }
        case 'currency': {
            // For Currency, we use the NumberFormat component and require $##,###,###.## input (2 decimal places allowed)
            return (
                <Tooltip
                    title={disabled ? 'Editing locked' : showLabel !== false && label ? label : `Enter ${EDataType[dataType]}`}
                    aria-label={id}
                >
                    <NumberFormat
                        id={id}
                        aria-invalid={error}
                        value={value}
                        disabled={disabled}
                        prefix="$"
                        placeholder={Number(defaultValue || value || 0).toString()}
                        thousandSeparator=','
                        decimalSeparator="."
                        mask=''
                        label={label}
                        InputProps={{
                            error: error,
                            endAdornment:  <>{helperText ? <Typography variant="subtitle2" color="error">{helperText}</Typography> : null }<AdornEnd disabled={disabled} dataType={dataType} error={error} /></>
                        }}
                        required={required}
                        inputRef={ref as RefObject<HTMLInputElement>}
                        customInput={TextField}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        decimalScale={2}
                        style={style}
                        className={className}
                        allowEmptyFormatting={true}
                        fullWidth={true}
                    />
                </Tooltip>
            );
        }
        case 'uuid': {
            // For a UUID, we use the react-input-mask component with some custom format characters
            const formatChars = {
                'a': '[0-9a-fA-F]'
            };
            return (
                <Tooltip
                    title={disabled ? 'Editing locked' : showLabel !== false && label ? label : `Enter ${EDataType[dataType]}`}
                    aria-label={id}
                >
                    <InputMask
                        aria-invalid={error}
                        mask='aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa'
                        maskChar='_'
                        disabled={disabled}
                        formatChars={formatChars}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={style}
                        className={className}
                    >
                        {(inputProps: TMaskedInputComponent) => (
                            <TextField {...inputProps} label={label} required={required} fullWidth={true} error={error} helperText={helperText} disabled={disabled} InputProps={{
                                endAdornment: <AdornEnd disabled={disabled} dataType={dataType} error={error} />
                            }} ref={ref} />
                        )}
                    </InputMask>
                </Tooltip>
            );
        }
        case 'macaddress': {
            // For a MAC address, we use the react-input-mask component with some custom format characters
            const formatChars = {
                'a': '[0-9A-Fa-f]'
            };
            return (
                <Tooltip
                    title={disabled ? 'Editing locked' : showLabel !== false && label ? label : `Enter ${EDataType[dataType]}`}
                    aria-label={id}
                >
                    <InputMask
                        mask='aa:aa:aa:aa:aa:aa'
                        maskChar='_'
                        disabled={disabled}
                        formatChars={formatChars}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={style}
                        className={className}
                        aria-invalid={error}
                    >
                        {(inputProps: TMaskedInputComponent) => (
                            <TextField {...inputProps} label={label} required={required} fullWidth={true} error={error} helperText={helperText} disabled={disabled} InputProps={{
                                endAdornment: <AdornEnd disabled={disabled} dataType={dataType} error={error} />
                            }} ref={ref} />
                        )}
                    </InputMask>
                </Tooltip>
            );
        }
        case 'number': {
            // For other numeric input, use the NumberFormat component
            return (
                <Tooltip
                    title={disabled ? 'Editing locked' : showLabel !== false && label ? label : `Enter ${EDataType[dataType]}`}
                    aria-label={id}
                >
                    <NumberFormat
                        id={id}
                        value={value}
                        prefix={prefix}
                        disabled={disabled}
                        placeholder={Number(defaultValue || value || 0).toString()}
                        thousandSeparator={thousandSeparator}
                        decimalSeparator={decimalSeparator}
                        mask={maskChar}
                        customInput={TextField}
                        inputRef={ref as React.RefObject<HTMLInputElement>}
                        InputProps={{
                            error: error,
                            endAdornment: <>{helperText ? <Typography variant="subtitle2" color="error">{helperText}</Typography> : null }<AdornEnd disabled={disabled} dataType={dataType} error={error} /></>
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        decimalScale={decimalScale}
                        style={style}
                        className={className}
                        allowEmptyFormatting={allowEmptyFormatting}
                        fullWidth={true}
                        aria-invalid={error}
                    />
                </Tooltip>
            );
        }
        default: {
            // For all other data types, use the react-input-mask component
            return (
                <Tooltip
                    title={disabled ? 'Editing locked' : showLabel !== false && label ? label : `Enter ${EDataType[dataType]}`}
                    aria-label={id}
                >
                    <InputMask
                        id={id}
                        mask={format || ''}
                        maskChar={maskChar}
                        disabled={disabled}
                        formatChars={formatChars}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        style={style}
                        aria-invalid={error}
                        className={className}
                    >
                        {(inputProps: TMaskedInputComponent) => (
                            <TextField {...inputProps} label={label} required={required} fullWidth={true} error={error} helperText={helperText} disabled={disabled} InputProps={{
                                endAdornment: <AdornEnd disabled={disabled} dataType={dataType} error={error} />
                            }} ref={ref} />
                        )}
                    </InputMask>
                </Tooltip>
            );
        }
    }
});
export default EditMasked;