/**
 * @file features/alert/initialState
 * @description Exports the initial state of the Alert Reducer.
 */

import { TAlertState } from './types';

const alertInitialState: TAlertState = {
    severity: 'info',
    display: false
};
export default alertInitialState;