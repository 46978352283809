/**
 * @file features/form/useQuery
 * @description A hook wrapper for runQuery. This should be used on every screen where we need to fetch data. Note that this hook is designed to run only once each time the component using it is mounted; for autocomplete changes, use the useAutocomplete hook instead.
 */

// Imports
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import runQuery from './runQuery';
import { TFormData } from './types';
import { TGlobalState } from '../global';
import { parseError, showError, TErrorState, validateTErrorState } from '../error';
import { configureGrid, gridLoading, TGridState } from '../grids';
import { TDataGrid } from '../view';
import { formLoaded } from './actions';

const useQuery = () => {

    // Hook dispatch and the store
    const dispatch = useDispatch();
    const { view, form, errorDisplay, user } = useSelector((state: TGlobalState) => {
        return {
            ...state,
            errorDisplay: state.error.display
        };
    });

    // useEffect hook that only runs if there is no form data, no error and nothing's been cancelled
    // The hook runs each 'Meta', 'DataLoad', 'AutocompleteOptions' and 'OnLoadSaga' provided in the view definition
    const queries = useMemo(() => view.currentView?.query, [view.currentView?.query]);
    const grids = useMemo(() => {
        let grids: TGridState[] = [];
            for (let tabOrStep of view.currentView?.tabsOrSteps || []) {
                for (let section of tabOrStep.sections) {
                    for (let component of section.components) {
                        if (component.componentType === 'DataGrid') {
                            grids.push({
                                dataKey: component.dataKey || component.id,
                                label: component.label,
                                grid: configureGrid([], component.dataKey || component.id, component as TDataGrid)
                            });
                        }
                    }
                }
            }
        return grids;
    }, [view.currentView?.tabsOrSteps]);
    useEffect(() => {
        let cancelled = false;
        // console.log('executing useQuery useEffect')
        const fetchData = async (newGrids: TGridState[]) => {
            try {
                for (let grid of grids) {
                    dispatch(gridLoading(grid));
                }
                let data: TFormData = form.selectedValues || {};
                if (queries && queries instanceof Array) {
                    for (let query of queries) {
                        if (
                                query.purpose === 'OnLoadSaga' ||
                                query.purpose === 'Meta' ||
                                query.purpose === 'DataLoad' 
                            ) {
                            data = await runQuery(query, data, dispatch, false, newGrids);
                            if (validateTErrorState(data)) {
                                throw data;
                            }
                        }
                    }
                    dispatch(formLoaded());
                }
                else {
                    dispatch(formLoaded());
                }
            } catch (err) {
                dispatch(formLoaded());
                if (validateTErrorState(err)) {
                    dispatch(showError(parseError(err)));
                }
                else {
                    const errorObj: TErrorState = {
                        status: 'Service Error',
                        message: err.message,
                        stack: err.stack,
                        correlationId: 'FEATURES/FORM/USE_QUERY/SYSTEM_ERROR',
                        display: true
                    };
                    dispatch(showError(errorObj));
                }
            }
        };
        if (!cancelled && !form.loading && !form.ready && view.ready) {
            fetchData(grids);
        }

        // Cleanup function
        return () => {
            cancelled = true;
        }

    }, [dispatch, user, errorDisplay, queries, form.selectedValues, grids, form.loading, form.ready, view.ready]);
};
export default useQuery;