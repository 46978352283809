/**
 * @file DateTimePicker.tsx
 * @description Component for rendering a date/time picker using React Material UI. The component shows either a calendar, clock or both 
 */

import React, { ForwardedRef, forwardRef, MutableRefObject, RefObject, useState } from 'react';
import { useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { EDataType, TDateComponent } from '../features/view';
import { TGlobalState } from '../features/global';
import { useValidation } from '../features/form';
import dayjs from 'dayjs';

// Export the component
const DateTimePicker: React.FC<TDateComponent> = forwardRef((props: TDateComponent, ref: ForwardedRef<HTMLInputElement>) => {
    // Destructure props
    const { id, label, showLabel, dataKey, updateKeys, defaultValue, defaultValueKeys, datePickerType, staticValue, disabled, style, className, required = false, alreadyMounted, defaultStartEnd } = props;

    let dateFormat = 'YYYY-MM-DDTHH:mm';
    if (datePickerType === 'date') {
        dateFormat = 'YYYY-MM-DD';
    }
    else if (datePickerType === 'time') {
        dateFormat = 'HH:mm';
    }

    // Set internal state variables
    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const setConvertedValue = (val: string) => {
        setValue(dayjs(val).format(dateFormat));
    }

    // Get form loading state - disable input if it's loading
    const formLoading = useSelector((state: TGlobalState) => state.form.loading);
    const disableInput = formLoading ? true : disabled ? true : false;

    // Get the event handler from the useValidation hook
    const { handleChange, handleBlur } = useValidation(dataKey, required, alreadyMounted as MutableRefObject<boolean>, setConvertedValue, setError, setErrorText, 'date', updateKeys, (defaultStartEnd ? dayjs().format(dateFormat) : defaultValue), defaultValueKeys, '', false, staticValue, label, dateFormat);

    // Render the component
    return (
        <Tooltip
            title={disableInput ? 'Editing locked' : showLabel !== false && label ? label : `Select/Enter ${EDataType.date}`}
            aria-label={`${id}-${datePickerType}`}
        >
            <TextField
                id={id}
                aria-invalid={error}
                color="secondary"
                aria-label={`${id}-${label}`}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
                required={required}
                disabled={disableInput}
                error={error}
                helperText={errorText}
                style={style}
                inputRef={ref as RefObject<HTMLInputElement>}
                type={datePickerType || 'datetime-local'}
                className={className}
            />
        </Tooltip>

    );
});
export default DateTimePicker;