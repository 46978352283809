/**
 * @file featurs/form/showComponent
 * @description This function computes hide/show rules to determine whether a component is shown or not.
 */

import { TFormData } from "./types";
import { TShowRule } from '../view';
import evaluateRule from "./evaluateRule";

/**
 * @function showComponent
 * @description Used to determine whether to show a component according to the logic below. The return value (true or false) determines whether we show the component. Logic:
 * 1. If a boolean 'show' value is supplied, this takes precedence and its value is simply returned (used for overriding usual behaviour). Otherwise, we follow the rules below.
 * 2. If the component is 'hideable', then showRules are evaluated - ALL of them must return true for the component to be shown. Otherwise, the component is hidden.
 * 3. Each rule is evaluated as follows - references to variables below are variables defined within the rule (unless they refer to the function's parameters):
 * 	a. If the rule has neither showValues nor showKey, return true if data[testKey] is truthy.
 * 	b. If showValues is supplied and is not an array:
 * 		i. If data[testKey] is also not an array, return true if showValues == data[testKey] (or a shallow object comparison using the shallowCompare function returns true).
 * 		ii. If data[testKey] is an array, return true if data[testKey] contains showValues (either as an array item or if shallowCompare returns true for one).
 * 	c. If showValues is supplied and is an array:
 * 		i. If data[testKey] is not an array, return true if showValues contains data[testKey] (either as an array item or if shallowCompare returns true for one).
 * 		ii. If data[testKey] is an array:
 * 			(1) If strictTest === true, only return true if data[testKey] has the same items in the same order as showValues. Otherwise return false.
 * 			(2) If !strictTest, return true if data[testKey] is a subset of data[showKey]. Otherwise return false.
 * 	d. Only evaluate below showKey rules if showValues is not supplied.
 * 	e. If data[showKey] is not an array:
 * 		i. If data[testKey] is also not an array return true if data[testKey] == data[showKey] (or shallowCompare returns true).
 * 		ii. If data[testKey] is an array:
 * 			(1) If strictTest === true, only return true if data[testKey] has the same number of items in the same order as data[showKey]. Otherwise return false.
 * 			(2) If !strictTest, return true if data[testKey] is a subset of data[showKey]. Otherwise return false.
 * 	f. If data[showKey] is an array
 * 		i. If data[testKey] is not an array, return true if data[showKey] contains data[testKey]. Otherwise return false.
 * 		ii. If data[testKey] is an array:
 * 			(1) If strictTest === true, only return true if data[testKey] has the same items in the same order as data[showKey]. Otherwise return false.
 * 			(2) If !strictTest, return true if data[testKey] is a subset of data[showKey].
 * @param {boolean} hideable If 'show' has not been set for the component, do we evaluate showRules? If this is false, then no we just return true and show the component.
 * @param {TShowRule[]} showRules The array of showRules to evaluate if 'show' is not set and 'hideable' is true.
 * @param {TFormData} data Form data to use for evaluating showRules.
 * @param {boolean|undefined} show If this is set, it overrides anything else. True means show, false means hide.
 */
export default function showComponent(hideable: boolean, showRules: TShowRule[], data: TFormData, show?: boolean): boolean {
    // If show is manually set, this overrides anything else (Rule 1)
    switch (show) {
        case true:
            return true;
        case false:
            return false;
    }

    // Otherwise, if the component is not hideable, return true - nothing to evaluate (Rule 2)
    if (!hideable) {
        return true;
    }

    // If hideable is true and show is not set, we evaluate each showRule (Rule 3)
    let ruleResults: boolean[] = [];
    for (let rule of showRules) {
        // Get the data we need to evaluate the rule
        ruleResults.push(evaluateRule(rule, data));
    }

    // Check the ruleResults length against the rules
    if (ruleResults.length !== showRules.length || ruleResults.indexOf(false) !== -1) {
        return false;
    }
    else {
        return true;
    }
}