/**
 * @component Wizard.tsx
 * @description The Wizard component is an alternative to the Tabs component. It displays what would normally be shown as a series of Tabs as a series of steps instead. Queries are run as needed as the user steps through.
 */

// Imports - React and types
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TViewMetadata } from '../features/view';
import { EMode, TGlobalState } from '../features/global';
import paramValidateAndConvert from '../features/form/paramValidateAndConvert';
import { alertAction, TAlertState } from '../features/alert';

// Imports - UI components
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from './Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Section from './Section';
import ErrorBoundary from './ErrorBoundary';

export default function Wizard(props: TViewMetadata): JSX.Element {
    // Destructure props and declare state variable for storing the current step
    const { categoryAndScreen, view, tabsOrSteps, topButtons, bottomButtons, availableModes } = props;
    const [stepNo, setStepNo] = useState(0);

    // Change event handlers for moving between steps or showing alerts if we can't
    const dispatch = useDispatch();
    // Reset to the beginning
    const handleStart = () => {
        setStepNo(0);
    };
    // Go to previous
    const handleBack = () => {
        setStepNo((prev) => prev - 1);
    };
    // Go to next
    const handleNext = (completed: boolean) => {
        if (completed) {
            setStepNo((prev) => prev + 1);
        }
        else {
            const alert: TAlertState = {
                severity: 'warning',
                message: 'Please complete the step by correcting the errors displayed in red',
                autoHideDuration: 6000,
                display: true
            };
            dispatch(alertAction(alert));
        }
    };
    // Go to last (the review and confirm step)
    const handleLast = (completed: boolean) => {
        if (completed) {
            setStepNo((tabsOrSteps.length - 1));
        }
        else {
            const alert: TAlertState = {
                severity: 'warning',
                message: 'Please complete the step by correcting the errors displayed in red',
                autoHideDuration: 6000,
                display: true
            };
            dispatch(alertAction(alert));
        }
    };

    // Hook into Redux state to get current form values we use for validation
    const { formData } = useSelector((state: TGlobalState) => {
        return {
            formData: state.form.selectedValues || {}
        };
    });

    // Event listener for showing a dialog if the user tries to exit before completing the form
    window.onbeforeunload = () => {
        if (stepNo !== (tabsOrSteps.length -1)) {
            return true;
        }
    };

    // Render the steps
    return (<ErrorBoundary correlationIdText="COMPONENTS/WIZARD/WIZARD_ERROR">
        <Grid item xs={12}>
            <Typography variant="h2" style={{textTransform: 'capitalize'}}>{categoryAndScreen.replace(/-/g, ' ')}: {EMode[view]}</Typography>
        </Grid>
        <Grid item xs={12}>
            <br />
        </Grid>
        <Grid item xs={12} container alignItems="flex-start" alignContent="space-between" justify="flex-start" direction="row" spacing={2}>
            {(topButtons.length > 0 || (topButtons.length === 1 && topButtons[0].id === 'switch-mode' && availableModes.length > 1)) || bottomButtons.length > 0 ?
                <Grid item xs={2} style={{
                    maxHeight: '80vh', position: 'fixed', overflowY: "auto", background: 'rgba(255, 255, 255, 1)', zIndex: 1000,
                    border: '3px solid rgba(0, 0, 0, 0.3)', borderRadius: '3px', boxShadow: 'inset 0 0 6px rgba(181, 181, 181, 0.8)', width: '12vw'
                }}>
                    <Grid item container xs={12} alignItems="center" alignContent="center" justify="center">
                        {topButtons.map((button, index) => (
                            <Button key={index} {...button} availableModes={availableModes} mode={view} fullWidth={true} />
                        ))}
                    </Grid>
                </Grid> : null}
            <Grid item xs={12} style={{ flexGrow: 1, width: '100%', paddingLeft: '15vw' }} >
                <Stepper activeStep={stepNo} orientation="vertical" style={{border: '3px solid rgba(0, 0, 0, 0.3)', borderRadius: '3px', boxShadow: 'inset 0 0 6px rgba(181, 181, 181, 0.8)', backgroundColor: 'rgba(255, 255, 255, 0.7'}}>
                    {tabsOrSteps.map((step, index, self) => {
                        // Validate any data parameters
                        const { title, validateParams = [], errorText, sections } = step;
                        let completed: boolean = stepNo > index ? true : false;
                        const labelProps: { error?: boolean, optional?: React.ReactNode } = {};
                        const urlParams = new URLSearchParams(window.location.search);
                        for (const param of validateParams) {
                            // console.log('DEBUG: Wizard validation rules:', validateParams, formData, urlParams);
                            try {
                                paramValidateAndConvert(param, formData, urlParams);
                                // console.log('successfully validated param:', param);
                            } catch (error) {
                                // console.error(error);
                                completed = false;
                                labelProps.error = true;
                                labelProps.optional = (
                                    <Typography variant="caption" color="error">{`${errorText}: ${error.message}`}</Typography>
                                );
                                break;
                            }
                        }

                        // Return the step contents - including the section, buttons etc.
                        return (
                            <Step key={index} completed={completed}>
                                <StepLabel {...labelProps} ><Typography variant="h3" color={completed && !labelProps.error ? "primary" : "secondary"}>{title}</Typography></StepLabel>
                                <StepContent>
                                    <Grid item xs={12} container direction="column" spacing={2} alignContent="space-between">
                                        {sections.map((section, sectionIndex) => <Section key={sectionIndex} thisTabOrStep={stepNo} heading={section.heading} componentsPerRow={section.componentsPerRow} components={section.components} />)}
                                        <Grid item xs={12} container direction="row" spacing={2} justify="space-between" style={{paddingTop: '5vh', paddingBottom: '3vh'}}>
                                            <Button id={`${step.title}-wizard-start-${index}`} text="Restart" button_purpose="wizardStart" customAction={handleStart} disabled={index === 0} />
                                            <Button id={`${step.title}-wizard-back-${index}`} text="Back" button_purpose="wizardBack" customAction={handleBack} disabled={index === 0} />
                                            <Button {...bottomButtons[index]} id={`${step.title}-wizard-next${index}`} text="Next" button_purpose="wizardNext" customAction={() => handleNext(labelProps.error ? false : true)} disabled={index === self.length - 1 ? true : false} />
                                            <Button {...bottomButtons[index]} id={index !== self.length - 1 ? `${step.title}-wizard-last-${index}` : `${step.title}-wizard-finalsubmit-${index}`} text={index !== self.length - 1 ? 'End' : 'Confirm &nbsp; Submit'} button_purpose={index !== self.length -1 ? 'wizardLast' : 'submit'} customAction={index !== self.length -1 ? () => handleLast(labelProps.error ? false : true) : bottomButtons[index].customAction || undefined} mode={view} />
                                        </Grid>
                                    </Grid>
                                </StepContent>
                            </Step>
                        );
                    })}
                </Stepper>
            </Grid>
        </Grid>
    </ErrorBoundary>
    );
};