/**
 * @file features/error/utilityFunctions
 * @description Contains utility functions for error-handling - e.g. type guards, functions for converting codes to error statuses, functions for parsing error messages into TErrorState objects.
 */

import { TErrorState, EErrorStatus, TErrorStatus } from "./types";

/**
 * codeToStatusText converts an HTTP code into a status text we use. For simplicity, we don't use as many status texts as HTTP allows, instead grouping different codes into easily-understood status texts.
 * @param {number} code The HTTP code to convert.
 * @returns {TErrorStatus} Returns the status text that most closely matches the error code.
 */
export const codeToStatusText = (code: number): TErrorStatus => {
    // If the code is less than 400, return OK
    if (code < 400)
    {
        return 'OK';
    }

    // Otherwise, switch through and return the most appropriate status
    switch (code)
    {
        // Return: 'Invalid Input'
        // This includes protocol/range/size/conflict errors, including some 5xx errors that come from bad input
        case 400:
        case 402:
        case 405:
        case 406:
        case 409:
        case 411:
        case 412:
        case 413:
        case 414:
        case 415:
        case 416:
        case 417:
        case 418:
        case 422:
        case 424:
        case 425:
        case 426:
        case 428:
        case 431:
        case 449:
        case 463:
        case 494:
        case 497:
        case 501:
        case 505:
        case 509:
        case 510:
            return 'Invalid Input';
        // Return: 'Not Found' for 404s and other responses indicating that something can't/shouldn't be found
        case 404:
        case 410:
        case 444:
        case 450:
        case 451:
            return 'Not Found';
        // Return: 'Timeout Error' for timeouts
        case 408:
        case 440:
        case 504:
        case 522:
        case 524:
        case 598:
        case 599:
            return 'Timeout Error';
        // Return: 'Request Cancelled' for cancelled requests
        case 460:
        case 499:
            return 'Request Cancelled';
        // Return: 'Authentication Required' for requests that require auth that wasn't provided
        case 401:
        case 407:
        case 495:
        case 496:
        case 511:
            return 'Authentication Required';
        // Return: 'Forbidden' for requests that aren't authorised
        case 403:
        case 423:
        case 525:
        case 526:
            return 'Forbidden';
        // Return: 'Service Unavailable' where a service isn't available/rate limited etc.
        case 429:
        case 502:
        case 503:
            return 'Service Unavailable';
        // Return: 'Service Error' as a catch-all and for server-side errors
        case 500:
        case 506:
        case 507:
        case 508:
        default:
            return 'Service Error';
    }
};

/**
 * validateTErrorState validates an object's compliance with the TErrorState type.
 * @param {any} obj The object to validate.
 * @returns {boolean} Returns true/false depending on the outcome of the test.
 */
export const validateTErrorState = (obj: any): obj is TErrorState => {
    if (typeof obj !== 'object')
    {
        return false;
    }
    if (!obj.status || !EErrorStatus[obj.status])
    {
        return false;
    }
    if (undefined === obj.display || typeof obj.display !== 'boolean')
    {
        return false;
    }
    if (!obj.message || typeof obj.message !== 'string')
    {
        return false;
    }
    if (obj.correlationId && typeof obj.correlationId !== 'string')
    {
        return false;
    }
    if (obj.stack && typeof obj.stack !== 'string')
    {
        return false;
    }

    return true;
};

/**
 * parseError either returns a valid TErrorState or converts a JavaScript error into a TErrorState object.
 * @param {TErrorState|Error} error The error to parse.
 * @param {boolean?} includeStack Include the stack trace? Defaults to true unless we're in prod.
 * @param {string?} correlationId Correlation ID to include if we can't otherwise find one from other input.
 */
export const parseError = (error: Error | TErrorState, includeStack?: boolean, correlationId?: string): TErrorState => {
    // Set includeStack based on environment if it hasn't been set
    if (undefined === includeStack && process.env.NODE_ENV === 'production')
    {
        includeStack = false;
    }
    else if (undefined === includeStack)
    {
        includeStack = true;
    }

    // If the error meets TErrorState requirements, just return it
    if (validateTErrorState(error))
    {
        if (!error.correlationId)
        {
            error.correlationId = correlationId || 'FEATURES/ERROR/PARSE_ERROR/ERROR_OBJECT';
        }
        if (includeStack && !error.stack)
        {
            const stackErr = new Error(JSON.stringify(error));
            error.stack = stackErr.stack;
        }
        return error;
    }

    // If the error isn't an instanceof Error, we create a new error object saying so.
    else if (!(error instanceof Error))
    {
        const err = new Error(`The error provided to convertError ("${error}") is not an instance of the Error class - please contact Support.`);
        return {
            status: 'Service Error',
            display: true,
            message: err.message,
            correlationId: correlationId || 'FEATURES/ERROR/PARSE_ERROR/INVALID_ERROR_FORMAT',
            stack: includeStack ? err.stack : undefined
        }
    }

    // Otherwise, try parse the error into a TErrorState object and return it - if we can't, throw another error
    try {
        let errObj = JSON.parse(error.message);
        if (validateTErrorState(errObj))
        {
            return errObj;
        }
        else
        {
            throw new Error(`The error message provided to convertError("${error.message}") does not contain the necessary properties - please contact Support.`);
        }
    } catch (err) {
        return {
            status: 'Service Error',
            display: true,
            message: err.message,
            correlationId: correlationId || 'FEATURES/ERROR/PARSE_ERROR/INVALID_MESSAGE_FORMAT'
        };
    }
};