/**
 * @file features/view/useView
 * @description Contains a custom React hook for fetching View metadata.
 */

// Imports
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TGlobalState, TMode, viewUrl } from '../global';
import { authProvider } from '../auth';
import { codeToStatusText, showError, TErrorState, validateTErrorState, parseError } from '../error';
import { viewLoading, viewLoaded } from './actions';

const useView = (viewMode: TMode, categoryAndScreenName: string) => {

    // Hook dispatch and the store
    const dispatch = useDispatch();
    const { view, screensReady, errorDisplay } = useSelector((state: TGlobalState) => {
        return {
            view: state.view,
            screensReady: state.screens.available.length > 0 && !state.screens.loading ? true : false,
            errorDisplay: state.error.display
        };
    });

    // useEffect hook that only runs if there is no view, no error and nothing's been cancelled
    // It only runs once - to run it again, the VIEW_RESET action must be dispatched
    useEffect(() => {
        let cancelled = false;
        // console.log('executing useView useEffect');

        const fetchView = async () => {
            // console.log('DEBUG: fetchView in useView:', view, screens, errorDisplay, user);
            dispatch(viewLoading());
            try {
                const token = await authProvider.getAccessToken();
                const init = {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token.accessToken}`
                    }
                };
                const url = `${viewUrl}&screen=${categoryAndScreenName}&view=${viewMode}`;
                const result = await fetch(url, init);
                if (result.ok) {
                    const viewData = await result.json().then(body => body.data);
                    dispatch(viewLoaded(viewData));
                }
                else {
                    const status = codeToStatusText(result.status);
                    let responseBody = null;
                    let message = '';
                    let correlationId = 'FEATURES/VIEW/USE_VIEW/FETCH_ERROR';
                    try {
                        responseBody = await result.json();
                    } catch (_) {
                        responseBody = await result.text();
                    }
                    if (typeof responseBody === 'string') {
                        message = responseBody;
                    }
                    else {
                        if (responseBody.message) {
                            message = responseBody.message;
                        }
                        if (responseBody.correlationId) {
                            correlationId = responseBody.correlationId;
                        }
                    }
                    const err: TErrorState = {
                        status: status,
                        message: message,
                        correlationId: correlationId,
                        display: true
                    };
                    dispatch(showError(err));
                }
            } catch (err) {
                dispatch(viewLoaded());
                if (validateTErrorState(err)) {
                    dispatch(showError(parseError(err)));
                }
                else {
                    const errorObj: TErrorState = {
                        status: 'Service Error',
                        message: err.message,
                        stack: err.stack,
                        correlationId: 'FEATURES/VIEW/USE_VIEW/SYSTEM_ERROR',
                        display: true
                    };
                    dispatch(showError(errorObj));
                }
            }
        };
        if (!cancelled && screensReady && !view.loading && !view.ready) {
            fetchView();
        }
        // Cleanup function
        return () => {
            cancelled = true;
        }

    }, [categoryAndScreenName, dispatch, errorDisplay, screensReady, view, viewMode]);
};
export default useView;