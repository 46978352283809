/**
 * Shows the "Switch Mode" buttons - these are usually on the top left of the layout if shown. They are a separate component to reduce the amount of code needed to render them etc.
 */

// Imports
import React from 'react';
import { TMode } from '../features/global';
import { TFormValue } from '../features/form';
import { TButton } from '../features/view';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TableChartIcon from '@material-ui/icons/TableChart';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ListIcon from '@material-ui/icons/List';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import DirectionsIcon from '@material-ui/icons/Directions';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography/Typography';

// Function for rendering each individual button
// Here, the "mode" prop determines which button is rendered
function SingleButton(props: TButton): JSX.Element {
    const { mode, variant, baseHref, disabled } = props;
    const useVariant = variant || 'contained';
    // Preserve URL query data - this is a navigation button, so our links should preserve URL parameters other than "mode"
    let urlParams: TFormValue[] = [];
    const urlQuery = new URLSearchParams(window.location.search);
    urlQuery.forEach((value, key) => urlParams.push({ key: key, value: value }));
    urlParams = urlParams.filter(item => item.key !== 'mode');
    let queryParams = '';
    for (let param of urlParams) {
        queryParams += `&${param.key}=${param.value}`;
    }

    switch (mode) {
        case 'view':
            return (
                <Button variant={useVariant} fullWidth={true} color="secondary" style={{ margin: 'auto', marginTop: '10px' }} onClick={() => {
                    if (disabled) {
                        return;
                    }
                    window.location.href = `${baseHref}?mode=view${queryParams}`;
                }} disabled={disabled}>
                    <VisibilityIcon fontSize="small" />&nbsp;View
                </Button>
            );
        case 'gridview':
            return (
                <Button variant={useVariant} fullWidth={true} color="secondary" style={{ margin: 'auto', marginTop: '10px' }} onClick={() => {
                    if (disabled) {
                        return;
                    }
                    window.location.href = `${baseHref}?mode=gridview${queryParams}`;
                }} disabled={disabled}>
                    <TableChartIcon fontSize="small" />&nbsp;Grid
                </Button>
            );
        case 'edit':
            return (
                <Button variant={useVariant} fullWidth={true} color="secondary" style={{ margin: 'auto', marginTop: '10px' }} onClick={() => {
                    if (disabled) {
                        return;
                    }
                    window.location.href = `${baseHref}?mode=edit${queryParams}`;
                }} disabled={disabled}>
                    <ListAltIcon fontSize="small" />&nbsp;Edit
                </Button>
            );
        case 'gridedit':
            return (
                <Button variant={useVariant} fullWidth={true} color="secondary" style={{ margin: 'auto', marginTop: '10px' }} onClick={() => {
                    if (disabled) {
                        return;
                    }
                    window.location.href = `${baseHref}?mode=gridedit`;
                }} disabled={disabled}>
                    <ListIcon fontSize="small" />&nbsp;Dashboard
                </Button>
         	);
        case 'newwizard':
            return (
                <Button variant={useVariant} fullWidth={true} color="secondary" style={{ margin: 'auto', marginTop: '10px' }} onClick={() => {
                    if (disabled) {
                        return;
                    }
                    window.location.href = `${baseHref}?mode=${mode}${queryParams}`;
                }} disabled={disabled}>
                    <ControlPointIcon fontSize="small" />&nbsp;Wizard: New
                </Button>
            );
        case 'editwizard':
            return (
                <Button variant={useVariant} fullWidth={true} color="secondary" style={{ margin: 'auto', marginTop: '10px' }} onClick={() => {
                    if (disabled) {
                        return;
                    }
                    window.location.href = `${baseHref}?mode=${mode}${queryParams}`;
                }} disabled={disabled}>
                    <EditIcon fontSize="small" />&nbsp;Wizard: Edit
                </Button>
            );
        case 'wizard':
            return (
                <Button variant={useVariant} fullWidth={true} color="secondary" style={{ margin: 'auto', marginTop: '10px' }} onClick={() => {
                    if (disabled) {
                        return;
                    }
                    window.location.href = `${baseHref}?mode=${mode}${queryParams}`;
                }} disabled={disabled}>
                    <DirectionsIcon fontSize="small" />&nbsp;Workflow Wizard
                </Button>
            );
        case 'dashboard':
            return (
                <Button variant={useVariant} fullWidth={true} color="secondary" style={{ margin: 'auto', marginTop: '10px' }} onClick={() => {
                    if (disabled) {
                        return;
                    }
                    window.location.href = `${baseHref}?mode=dashboard`;
                }} disabled={disabled}>
                    <DashboardIcon fontSize="small" />&nbsp;Dashboard
                </Button>
            );
        case 'new':
            return (
                <Button variant={useVariant} fullWidth={true} color="secondary" style={{ margin: 'auto', marginTop: '10px' }} onClick={() => {
                    if (disabled) {
                        return;
                    }
                    window.location.href = `${baseHref}?mode=new`;
                }} disabled={disabled}>
                    <AddCircleIcon fontSize="small" />&nbsp;New
                </Button>
            );
        case 'delete':
            return (<></>);
        /**@todo Add delete button definition here after v1.0.0 Stable release. */
        default:
            throw new Error('Unsupported view mode specified.');
    }
};

// Declare and export the component, which consists of either an empty fragment (if the only available mode is the current mode) or a heading and a series of buttons arranged vertically that switch between modes
export default function ModeButtons(props: TButton): JSX.Element {
    const { id, mode = 'gridview', availableModes = ['gridview', 'view'] } = props;
    // Don't render a button for the current mode
    let modesToShow = availableModes.filter((item) => item !== mode);
    // console.log('DEBUG: mode and modesToShow:', mode, modesToShow);
    // If we're in gridview, dashboard, wizard, new, newwizard or gridedit modes, don't show single-record view & edit buttons
    if (mode === 'gridview' || mode === 'new' || mode === 'newwizard' || mode === 'gridedit' || mode === 'dashboard' || mode === 'wizard') {
        modesToShow = modesToShow.filter(item => item !== 'view' && item !== 'edit' && item !== 'editwizard');
    }
    // No modesToShow? Return empty null
    if (modesToShow.length === 0) {
        return (<></>);
    }

    // Otherwise, return the buttons
    else {
        let buttons: JSX.Element[] = [];
        for (let mode of modesToShow) {
            const modeButton = <SingleButton {...props} key={`modebutton-${mode}-${id || '0'}`} mode={mode as TMode} />;
            buttons.push(modeButton);
        }
        return (
            <Grid item xs={12} container direction="column" justify="flex-start" alignItems="center" alignContent="flex-start">
                <Typography variant="h4" align="center">Mode:</Typography>
                {buttons}
            </Grid>
        )
    }
}