/**
 * @component DialogButton.tsx
 * @description The DialogButton is a wrapper for the FormDialog component. It renders a button that will make a form dialog pop up & dispatch values to it. The dialog is integrated with the rest of the state.
 */

// Imports
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formEdit, TFormValue } from '../features/form';
import FormDialog from './FormDialog';
import Button from './Button';
import { TDialogButton } from '../features/view';
import { TGlobalState } from '../features/global';

// Export the component
export default function DialogButton(props: TDialogButton): JSX.Element {
    // Destructure props
    const { dialogProps, buttonProps, dispatchKeys } = props;

    // Set internal state
    const [showing, setShowing] = useState(false);

    // Hook Redux state
    const { dispatchData } = useSelector((state: TGlobalState) => {
        const dispatchValues: TFormValue[] = [];
        if (state.form.selectedValues) {
            for (let key of dispatchKeys) {
                dispatchValues.push({
                    key: `dialog_${key}`,
                    value: state.form.selectedValues[key]
                });
            }
        }
        return {
            dispatchData: dispatchValues,

        }
    });

    // Handler for opening the dialog and dispatching values
    const dispatch = useDispatch();
    const handleOpen = () => {
        for (let item of dispatchData) {
            dispatch(formEdit(item));
        }
        setShowing(true);
    };

    // Close handler (when the user cancels) - clears values after closing the dialog
    const handleClose = () => {
        setShowing(false);
    };

    // Render the component - it is a fragment wrapping the dialog component and button component
    return (<>
        <FormDialog {...dialogProps} display={showing} displayStateSetter={handleClose}/>
        <Button {...buttonProps} button_purpose="formDialogPopup" disabled={showing} customAction={handleOpen} />
    </>);
}