/**
 * @file features/files/fileCategories.ts
 * @description This file uses variable replacement to declare the file categories and the UUIDs to which they are linked that are used by this app. This is used for file downloads.
 */

import { TFileITCategory, EMimeType } from './types';
import { enumContains } from '../global';

export const validateFileCategory = (input: any): input is TFileITCategory => {
    if (typeof input !== 'object') {
        return false;
    }
    if (!Object.prototype.hasOwnProperty.call(input, 'categoryUuid') || !(/^[0-9a-f]{8,8}-[0-9a-fA-F]{4,4}-[0-9a-fA-F]{4,4}-[0-9a-fA-F]{4,4}-[0-9a-fA-F]{12,12}$/.test(input['categoryUuid']))) {
        return false;
    }
    if (!Object.prototype.hasOwnProperty.call(input, 'categoryName') || typeof input['categoryName'] !== 'string') {
        return false;
    }
    if (!Object.prototype.hasOwnProperty.call(input, 'acceptedMimeTypes') || !(input['acceptedMimeTypes'] instanceof Array)) {
        return false;
    }
    for (let mime of input['acceptedMimeTypes']) {
        if (!enumContains(EMimeType, mime)) {
            return false;
        }
    }
    return true;
};

let fileCategories: TFileITCategory[] = [];
try {
    const testCategories = JSON.parse(process.env.REACT_APP_FILEIT_CATEGORIES || '[]');
    for (let category of testCategories) {
        if (validateFileCategory(category)) {
            fileCategories.push(category);
        }
        else {
            console.warn('WARNING: The following file category definition is invalid - please contact Support:', category);
        }
    }
} catch (_) {
    fileCategories = [];
}
export default fileCategories;