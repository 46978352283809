/**
 * @file features/global/enumContains
 * @description Checks whether an enum contains a specified key, value or key-value pair. NOTE: Value test does not work on complex enums that don't contain primitive values (e.g. enums with functions, objects, etc - this is for simple enums we use for typing).
 */

import enumToArray from "./enumToArray";

const enumContains = (Enum: { [s: string]: unknown; } | { [n: number]: unknown; }, key?: string | number, value?: any): boolean => {
    const enumArr = enumToArray(Enum);
    if ((typeof key === 'string' || typeof key === 'number') && value) {
        return enumArr.find(item => item.key === key && item.value === value) ? true : false;
    }
    else if ((typeof key === 'string' || typeof key === 'number') && !value && typeof value !== 'string' && typeof value !== 'number') {
        return enumArr.find(item => item.key === key) ? true : false;
    }
    else if (typeof key !== 'string' && typeof key !== 'number' && !key && value !== undefined) {
        return enumArr.find(item => item.value === value) ? true : false;
    }
    else {
        return false;
    }
};
export default enumContains;