/**
 * @file features/user/initialState
 * @description Exports the initial state of the User reducer.
 */

import { TUserState } from './types';

const userInitialState: TUserState = {
    loading: false,
    profile: null
};
export default userInitialState;