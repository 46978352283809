/**
 * @component DataGrid.tsx
 * @description Displays tabular data and allows filtering, sorting and export.
 */

// Imports
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MaterialTable, { Action, Column } from 'material-table';
import { TDataGrid } from '../features/view';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/List';
import { arrayContains, readableTitle, TGlobalState } from '../features/global';
import Skeleton from '@material-ui/lab/Skeleton';
import './dataGrid.css';
import DataCell from './DataCell';
import { TFormData, formEdit, evaluateRule, TFormValue } from '../features/form';
import FormDialog from './FormDialog';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

// Export the component
export default function DataGrid(props: TDataGrid): JSX.Element {
	// Destructure props
	const { label, dataKey = 'datagrid', editDialog, newDialog, rowStyleRules, rowStyleColours, showViewAction = true, showEditAction = true, showNewAction = true, thisTabOrStep = 0, deleteDialog, showDeleteAction = false, baseHref } = props;

	// Get the grid state, form state & available views
	const { grid, loadingState, availableViews, data } = useSelector((state: TGlobalState) => {
		const thisScreen = state.screens.available.find(screen => screen.base_href === window.location.pathname);
		return {
			grid: state.grids[dataKey]?.grid || null,
			loadingState: state.user.loading || state.screens.loading || state.view.loading || state.form.loading || state.grids[dataKey]?.grid?.isLoading || false,
			availableViews: thisScreen?.available_views || ['gridview'],
			data: state.grids[dataKey]?.grid?.data || [],
			formValues: state.form.selectedValues || {}
		};
	});

	// Declare icon components for the various possible actions
	const tableIcons = {
		Add: forwardRef((props, ref) => <AddBox {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		Check: forwardRef((props, ref) => <Check {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		Clear: forwardRef((props, ref) => <Clear {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		Delete: forwardRef((props, ref) => <DeleteIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		Edit: forwardRef((props, ref) => <Edit {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		Search: forwardRef((props, ref) => <Search {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		VisibilityIcon: forwardRef((props, ref) => <VisibilityIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		EditIcon: forwardRef((props, ref) => <EditIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
		ListIcon: forwardRef((props, ref) => <ListIcon {...props} ref={ref as React.RefObject<SVGSVGElement>} />),
	};


	// Set column settings and add render method for showing data
	const updatedColumns: Column<TFormData>[] = useMemo(() => {
		const columns: Column<TFormData>[] = [];
		if (grid && grid.columns && grid.columns instanceof Array && grid.columns.length > 0) {
			for (let column of grid.columns) {
				let newColumn = Object.assign({}, column || {});
				// Columns with date/time in the field name - show as locale strings
				if (/\bdate\b|\btime\b/i.test(column.title as string || '')) {
					newColumn.dateSetting = { locale: 'en-AU' };
					newColumn.type = 'datetime';
				}

				// Other columns: use the DataCell component
				else {
					newColumn.render = (rowData: TFormData | string | number) => {
						let parsedData: any = '';
						let rowId = 0;
						// Is rowData a string? If so, we use that as the value (when grouping, rowData becomes a string as the group-by value becomes its own row)
						if (typeof rowData === 'string' || typeof rowData === 'number') {
							parsedData = rowData;
						}
						else {
							try {
								rowId = rowData?.tableData?.id || 0;
								parsedData = rowData[column.field as string];
							} catch (_) {
								// No-op
							}
						}
						return <DataCell cellData={parsedData} column={newColumn} dataKey={dataKey} rowId={rowId} rowData={rowData as TFormData} />;
					};
				}
				columns.push(newColumn);
			}
		}
		return columns;
	}, [grid, dataKey]);

	// Toggle show/hide new and edit dialogs
	const [editOpen, setEditOpen] = useState(false);
	const handleEditClose = () => {
		setEditOpen(false);
	}
	const [newOpen, setNewOpen] = useState(false);
	const handleNewClose = () => {
		setNewOpen(false);
	};
	const [deleteOpen, setDeleteOpen] = useState(false);
	const handleDeleteClose = () => {
		setDeleteOpen(false);
	};


	// Configure grid actions if we have params defined
	const dispatch = useDispatch();
	const gridActions: Action<TFormData>[] = useMemo(() => {
		const actions: Action<TFormData>[] = [];
		// console.log('DEBUG: inside useMemo: grid:', grid);
		if (grid) {
			// We always push a button for "view" mode if it is available
			// console.log('DEBUG: availableViews:', availableViews);
			if (grid.linkParams && availableViews.indexOf('view') > -1 && showViewAction && grid.data && grid.data.length > 0) {
				let urlTarget = baseHref ? `${baseHref}?mode=view` : `${window.location.protocol}//${window.location.host}${window.location.pathname}?mode=view`;
				actions.push({
					icon: () => <VisibilityIcon color="secondary" />,
					onClick: (event: React.MouseEvent, rowData: TFormData) => {
						let urlParams = new URLSearchParams();
						for (let param of grid?.linkParams || []) {
							urlParams.set(param.urlKey, rowData[param.name]);
						}
						urlTarget = `${urlTarget}&${urlParams.toString()}`;
						window.location.href = urlTarget;
					},
					tooltip: `View Record`
				});
			}
			// console.log('DEBUG: DataGrid newDialog:', newDialog);
			// If we have a newDialog, we show that as a free Action the user can click to add items
			if (newDialog && showNewAction) {
				const handleOpen = (rowData: TFormData) => {
					for (let key in rowData) {
						dispatch(formEdit({ key: `dialog_${key}`, value: rowData[key] }));
					}
					setNewOpen(true);
				};
				actions.push({
					icon: () => <AddBox color="secondary" />,
					onClick: (event: React.MouseEvent, rowData: any) => {
						handleOpen(rowData);
					},
					tooltip: 'Add New',
					isFreeAction: true
				});
			}
			// If we have an editDialog, we show that instead of the single-record edit links
			if (editDialog && showEditAction  && grid.data && grid.data.length > 0) {
				const handleOpen = (rowData: TFormData) => {
					for (let key in rowData) {
						dispatch(formEdit({ key: `dialog_${key}`, value: rowData[key] }));
					}
					setEditOpen(true);
				};
				actions.push({
					icon: () => <EditIcon color="secondary" />,
					onClick: (event: React.MouseEvent, rowData: any) => {
						handleOpen(rowData);
					},
					tooltip: 'Edit'
				});
			}
			// Otherwise, show buttons that link to single-record views
			else if (grid.linkParams && showEditAction) {
				if (availableViews.indexOf('edit') > -1 && grid.data && grid.data.length > 0) {
					let urlTarget = baseHref ? `${baseHref}?mode=edit` : `${window.location.protocol}//${window.location.host}${window.location.pathname}?mode=edit`;
					actions.push({
						icon: () => <EditIcon color="secondary" />,
						onClick: (event: React.MouseEvent, rowData: TFormData) => {
							let urlParams = new URLSearchParams();
							for (let param of grid?.linkParams || []) {
								urlParams.set(param.urlKey, rowData[param.name]);
							}
							urlTarget = `${urlTarget}&${urlParams.toString()}`;
							window.location.href = urlTarget;
						},
						tooltip: `Edit Record`
					});
				}
				if (availableViews.indexOf('editwizard') > -1 && grid.data && grid.data.length > 0) {
					// console.log('showEditAction')
					let urlTarget = baseHref ? `${baseHref}?mode=editwizard` : `${window.location.protocol}//${window.location.host}${window.location.pathname}?mode=editwizard`;
					actions.push({
						icon: () => <ListIcon color="secondary" />,
						onClick: (event: React.MouseEvent, rowData: TFormData) => {
							let urlParams = new URLSearchParams();
							for (let param of grid?.linkParams || []) {
								urlParams.set(param.urlKey, rowData[param.name]);
							}
							urlTarget = `${urlTarget}&${urlParams.toString()}`;
							window.location.href = urlTarget;
						},
						tooltip: `Edit Wizard`
					});
				}
			}
			// console.log('deleteDialog:', deleteDialog, showDeleteAction, availableViews);
			if (deleteDialog && showDeleteAction && availableViews.indexOf('delete') > -1 && grid.data && grid.data.length > 0) {
				const handleOpen = (rowData: TFormData) => {
					for (let key in rowData) {
						dispatch(formEdit({ key: `dialog_${key}`, value: rowData[key] }));
					}
					setDeleteOpen(true);
				};
				actions.push({
					icon: () => <DeleteIcon color="secondary" />,
					onClick: (event: React.MouseEvent, rowData: any) => {
						handleOpen(rowData);
					},
					tooltip: 'Delete/Cancel'
				});
			}
		}
		return actions;
	}, [grid, availableViews, showViewAction, newDialog, showNewAction, editDialog, showEditAction, deleteDialog, showDeleteAction, dispatch, baseHref]);

	// Configure grid colour coding if we have the props
	const rowStyle = useMemo(() => {
		if (rowStyleRules && rowStyleColours && rowStyleRules.length === rowStyleColours.length) {
			return (rowData: TFormData) => {
				for (let r = 0; r < rowStyleRules.length; r++) {
					if (evaluateRule(rowStyleRules[r], rowData)) {
						return {
							verticalAlign: 'top',
							backgroundColor: rowStyleColours[r].background,
							color: rowStyleColours[r].foreground
						};
					}
				}
				return {
					verticalAlign: 'top'
				};
			}
		}
		return {
			verticalAlign: 'top'
		};
	}, [rowStyleRules, rowStyleColours]);


	// Set other options
	const gridOptions = Object.assign(
		{},
		grid?.style || {},
		{
			filtering: true,
			grouping: true,
			fixedColumns: grid?.options?.fixedColumns || {},
			exportButton: true,
			exportAllData: true,
			pageSize: 10,
			pageSizeOptions: [5, 10, 20, 50, 100],
			rowStyle: rowStyle
		}
	);

	// Dynamically filter the data based on URL filters - makes drilldown reports easier
	const urlFilter = useMemo<TFormValue | null>(() => {
		const urlParams = new URLSearchParams(window.location.search).get('gridfilter');
		if (urlParams) {
			const pair = urlParams.split('--MATCH--');
			if (pair.length !== 2) {
				return null;
			}
			else {
				return {
					key: pair[0],
					value: pair[1]
				};
			}
		}
		else {
			return null;
		}
	}, []);
	const gridData = useRef(data as TFormData[]);
	const clearHref = useMemo(() => {
		const urlParams = new URLSearchParams(window.location.search);
		urlParams.delete('gridfilter');
		return `${window.location.pathname}?${urlParams.toString()}`;
	}, []);

	// useEffect for setting data
	useEffect(() => {
		let active = true;

		if (!active || loadingState || newOpen || editOpen) {
			// console.log('dataGrid useEffect: returning undefined')
			return undefined;
		}

		if (active && !loadingState && !newOpen && !editOpen && !arrayContains(gridData.current, data) && data instanceof Array && data.length > 0 && urlFilter) {
			// console.log('dataGrid useEffect: filter branch')
			const regex = new RegExp(urlFilter.value, 'i');
			const filteredData = data.filter(val => regex.test(val[urlFilter.key]));
			if (filteredData.length !== gridData.current.length) {
				gridData.current = filteredData;
			}
		}
		else if (active && !loadingState && !newOpen && !editOpen && data instanceof Array && !arrayContains(gridData.current, data) && data.length > 0) {
			// console.log('dataGrid useEffect: other branch');
			gridData.current = data;
		}

		return () => {
			active = false;
		}
	}, [data, editOpen, loadingState, newOpen, urlFilter]);

	// Cleanup useEFfect
	const renderEditDialog = useRef(editDialog ? true : false);
	const renderNewDialog = useRef(newDialog ? true : false);
	const renderDeleteDialog = useRef(deleteDialog ? true : false);
	const thisTabOrStepRef = useRef(thisTabOrStep);
	useEffect(() => {
		const tabOrStep = thisTabOrStepRef.current;
		return () => {
			if (tabOrStep !== thisTabOrStep) {
				gridData.current = [];
				renderNewDialog.current = false;
				renderEditDialog.current = false;
				renderDeleteDialog.current = false;
			}
		}
	}, [thisTabOrStep]);

	// Render the grid
	// console.log('DEBUG: grid render: updatedColumns:', updatedColumns);
	// console.log('DEBUG: grid render: data:', data);
	// console.log('DEBUG: grid render: grid actions:', gridActions);
	// console.log('DEBUG: grid:', grid);
	return (<>
		{loadingState && !newOpen && !editOpen && !deleteOpen ?
			<>
				<Skeleton animation="pulse" variant="rect" height="3vh" />
				<br />
				<Skeleton animation="pulse" variant="rect" height="3vh" />
				<br />
				<Skeleton animation="pulse" variant="rect" height="3vh" />
				<br />
				<Skeleton animation="pulse" variant="rect" height="3vh" />
				<br />
				<Skeleton animation="pulse" variant="rect" height="3vh" />
				<br />
				<Skeleton animation="pulse" variant="rect" height="3vh" />
			</>
			: <>
			{renderEditDialog.current && editDialog ? <FormDialog {...editDialog} display={editOpen} displayStateSetter={handleEditClose} /> : null}
			{renderNewDialog.current && newDialog ? <FormDialog {...newDialog} display={newOpen} displayStateSetter={handleNewClose} /> : null}
			{renderDeleteDialog.current && deleteDialog ? <FormDialog {...deleteDialog} display={deleteOpen} displayStateSetter={handleDeleteClose} /> : null}
			<MaterialTable 
				{...props} 
				columns={updatedColumns} 
				actions={gridActions} 
				data={gridData.current} 
				title={(<><Typography variant="h5">{urlFilter ? `${label} (filtered: ${readableTitle(urlFilter.key || '')} contains ${urlFilter.value || ''})` : label}</Typography>{urlFilter ? <Link href={clearHref} color="secondary">Clear filter</Link> : null}</>)} 
				icons={tableIcons as { [x: string]: React.ForwardRefExoticComponent<React.RefAttributes<SVGSVGElement>> }} 
				options={Object.assign({}, gridOptions, { exportFileName: `${label}${urlFilter ? `-filtered-${urlFilter.key}-contains-${urlFilter.value}` : ''}.csv` })} />
		</>}
	</>);
}