/**
 * @component RichText.tsx
 * @description Used for editing rich text
 */

import React from 'react';
import { connect } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import { TGlobalState } from '../features/global';
import { TFormData } from '../features/form';

type TProps = {
    dataKey: string, 
    onChange: Function, 
    onBlur?: Function, 
    disabled?: boolean, 
    formValues?: TFormData | null
};

type TState = {
    text: string
};

class RichText extends React.Component<TProps, TState> {
    constructor(props: {dataKey: string, onChange: Function, formValues?: TFormData | null, onBlur?: Function, disabled?: boolean}) {
        super(props);
        this.state = {
            text: props.formValues?.[props.dataKey] || ''
        };
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }

    componentDidMount() {
        this.setState({
            text: this.props.formValues?.[this.props.dataKey] || ''
        });
    }

    componentDidUpdate(prevProps: TProps) {
        if (prevProps.formValues?.[prevProps.dataKey] !== this.props.formValues?.[this.props.dataKey]) {
            this.setState({
                text: this.props.formValues?.[this.props.dataKey] || ''
            });
        }
    }

    onChange(value: string) {
        this.setState({text: value});
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    onBlur() {
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    render() {
        return (
            <ReactQuill
                value={this.state.text}
                readOnly={this.props.disabled}
                onChange={this.onChange}
                onBlur={this.onBlur}
                theme="snow"
            />
        );
    }
}

// Map Redux state, props. Connect and export
const mapState = (state: TGlobalState) => ({
    formValues: state.form.selectedValues
});
const connector = connect(mapState);
export default connector(RichText);