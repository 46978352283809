/**
 * @file features/global/enumToArray.ts
 * @description Converts an Enum to an array of objects - the array of objects has a {"key": [Enum Key], "value": [Enum Value]} structure. For example:
 * enum Weeks {
 *  Monday = 1,
 *  Tuesday = 2,
 *  Wednesday = 3,
 *  Thursday = 4,
 *  Friday = 5,
 *  Saturday = 6,
 *  Sunday = 7
 * }
 * Would be converted to:
 * [
 *  { key: 'Monday', value: 1 },
 *  { key: 'Tuesday', value: 2 },
 *  { key: 'Wednesday', value: 3 },
 *  { key: 'Thursday', value: 4 },
 *  { key: 'Friday', value: 5 },
 *  { key: 'Saturday' value: 6 },
 *  { key: 'Sunday', value: 7}
 * ]
 */

import { TFormValue } from "../form";

const enumToArray = (Enum: { [s: string]: unknown; } | { [n: number]: unknown; }): TFormValue[] => {
    const returnArray: TFormValue[] = [];
    for (const [propertyKey, propertyValue] of Object.entries(Enum)) {
        returnArray.push({ key: propertyKey, value: propertyValue });
    }
    return returnArray;
};
export default enumToArray;