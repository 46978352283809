/**
 * @component AddressPicker.tsx
 * @description MUI autocomplete component that uses Microsoft's Bing Maps API to select an address & dispatches the values. It will dispatch data to multiple keys for different parts of the address when a selection is made. However, it doesn't use a DB query to fetch data, but the Bing API.
 * Keys for data dispatch are mapped as follows:
 * - dataKey: Will contain the selected address as an entire string
 * - updateKeys are mapped in the following order - these will contain the components of the address (noting that they are an array):
    0: Country
    1: State
    2: Postcode
    3: Suburb
    4: Street 1
    5: Street 2 and/or Location Name
 */
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { TAutocompleteComponent } from '../features/view';
import AdornEnd from './AdornEnd';
import { TGlobalState } from '../features/global';
import { formEdit } from '../features/form';

function loadScript(src: string, position: HTMLElement | null, id: string) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };

interface PlaceType {
    description: string;
    place_id: string;
    structured_formatting: {
        main_text: string;
        secondary_text: string;
        main_text_matched_substrings: [
            {
                offset: number;
                length: number;
            },
        ];
    };
}

const AddressPicker = forwardRef((props: TAutocompleteComponent, ref) => {

    const { id, dataKey, updateKeys = [] } = props;

    const ready = useSelector((state: TGlobalState) => state.form.ready && !state.form.loading);

    const [value, setValue] = useState<PlaceType | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState<PlaceType[]>([]);
    const loaded = useRef(false);
    const map = useRef<any>(null);
    const [loadingPlace, setLoadingPlace] = useState(false);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_API_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }
        loaded.current = true;
    }

    const fetchPlaces = React.useMemo(
        () =>
            throttle((request: { input: string }, callback: (results?: PlaceType[]) => void) => {
                const updatedRequest = Object.assign({}, request, {
                    componentRestrictions: {
                        country: ['au', 'nz']
                    }
                });
                (autocompleteService.current as any).getPlacePredictions(updatedRequest, callback);
            }, 200),
        [],
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && (window as any).google && (window as any).google.maps && (window as any).google.maps.places && (window as any).google.maps.places.AutocompleteService) {
            autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }
        if (!map.current && (window as any).google) {
            map.current = new (window as any).google.maps.Map(document.getElementById(`${id}-map-container`));
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetchPlaces({ input: inputValue }, (results?: PlaceType[]) => {
            if (active) {
                let newOptions = [] as PlaceType[];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetchPlaces, id]);

    const dispatch = useDispatch();
    const handleChange = async (event: React.ChangeEvent<{}>, newValue: PlaceType | null) => {
        setLoadingPlace(true);
        setOptions(newValue ? [newValue, ...options] : options);
        if ((window as any).google && newValue && map.current) {
            const placesService = new (window as any).google.maps.places.PlacesService(map.current);
            placesService.getDetails({
                placeId: newValue.place_id,
                fields: [
                    'adr_address',
                    'address_components',
                    'name'
                ]
            }, (place: any, status: string) => {
                const nameRegex = new RegExp(place.name);
                const addressValue = nameRegex.test(place.adr_address) ? place.adr_address : `<span class="place-name">${place.name}</span>, ${place.adr_address}`
                dispatch(formEdit({
                    key: dataKey,
                    value: addressValue
                }));
                const components = (place.address_components as any[]);
                const country = components.find(item => item.types.indexOf('country') > -1)?.long_name || '';
                if (updateKeys[0] && country) {
                    dispatch(formEdit({
                        key: updateKeys[0],
                        value: country
                    }));
                }
                else if (updateKeys[0]) {
                    dispatch(formEdit({
                        key: updateKeys[0],
                        value: ''
                    }));
                }
                const state = components.find(item => item.types.indexOf('administrative_area_level_1') > -1)?.short_name || '';
                if (updateKeys[1] && state) {
                    dispatch(formEdit({
                        key: updateKeys[1],
                        value: state
                    }));
                }
                else if (updateKeys[1]) {
                    dispatch(formEdit({
                        key: updateKeys[1],
                        value: ''
                    }));
                }
                const postcode = components.find(item => item.types.indexOf('postal_code') > -1)?.long_name || '';
                if (updateKeys[2] && postcode) {
                    dispatch(formEdit({
                        key: updateKeys[2],
                        value: postcode
                    }));
                }
                else if (updateKeys[2]) {
                    dispatch(formEdit({
                        key: updateKeys[2],
                        value: ''
                    }));
                }
                const suburb = components.find(item => item.types.indexOf('locality') > -1)?.long_name || '';
                if (updateKeys[3] && suburb) {
                    dispatch(formEdit({
                        key: updateKeys[3],
                        value: suburb.toUpperCase()
                    }));
                }
                else if (updateKeys[3]) {
                    dispatch(formEdit({
                        key: updateKeys[3],
                        value: ''
                    }));
                }
                const streetNo = components.find(item => item.types.indexOf('street_number') > -1)?.long_name || '';
                const streetName = components.find(item => item.types.indexOf('route') > -1)?.long_name || '';
                const streetAddress1 = `${streetNo} ${streetName}`.trim();
                const subPremise = components.find(item => item.types.indexOf('subpremise') > -1)?.long_name || '';
                const streetAddress2 = `${place.name} ${subPremise}`.trim();
                if (updateKeys[4] && streetAddress1) {
                    dispatch(formEdit({
                        key: updateKeys[4],
                        value: streetAddress1
                    }));
                }
                else if (updateKeys[4] && streetAddress2) {
                    dispatch(formEdit({
                        key: updateKeys[4],
                        value: streetAddress2
                    }));
                }
                else if (updateKeys[4]) {
                    dispatch(formEdit({
                        key: updateKeys[4],
                        value: ''
                    }));
                }
                const streetRegex = new RegExp(streetAddress2);
                if (updateKeys[5] && streetAddress2 && streetAddress1 && !streetRegex.test(streetAddress1)) {
                    dispatch(formEdit({
                        key: updateKeys[5],
                        value: streetAddress2
                    }));
                }
                else if (updateKeys[5]) {
                    dispatch(formEdit({
                        key: updateKeys[5],
                        value: ''
                    }));
                }
                setLoadingPlace(false);
            });
        }
        else {
            dispatch(formEdit({
                key: dataKey,
                value: ''
            }));
            for (let key of updateKeys) {
                dispatch(formEdit({
                    key: key,
                    value: ''
                }));
            }
            setLoadingPlace(false);
        }
        setValue(newValue);
    }

    return (<Grid item container xs={12} direction="column" justify="flex-start">
        <Autocomplete
            id={id}
            style={{ width: 300 }}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={handleChange}
            fullWidth
            disabled={!ready}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField {...params} label="Type a location" fullWidth inputRef={ref}
                InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <React.Fragment>
                            <AdornEnd disabled={!ready || loadingPlace} loadingCircle={true} dataType="location" />
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
                />
            )}
            renderOption={(option) => {
                const matches = option.structured_formatting.main_text_matched_substrings;
                const parts = parse(
                    option.structured_formatting.main_text,
                    matches.map((match: any) => [match.offset, match.offset + match.length]),
                );

                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            <LocationOnIcon color="primary" />
                        </Grid>
                        <Grid item xs>
                            {parts.map((part, index) => (
                                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    {part.text}
                                </span>
                            ))}
                            <Typography variant="body2" color="textSecondary">
                                <br/>{option.structured_formatting.secondary_text}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
         <div id={`${id}-map-container`} style={{width: 0, height: 0}} />
    </Grid>);
});
export default AddressPicker;