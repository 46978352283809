/**
 * @file features/auth/reducer
 * @description The reducer for AAD authentication actions.
 */

// Imports
import { AuthenticationActions, AuthenticationState } from 'react-aad-msal';
import { TAction } from '../global';
import aadInitialState from './initialState';
import { TAADResponseState } from './types';

/**
 * The reducer itself deals with AAD responses - Redux state is updated to match authentication state.
 * @param {TAADResponseState} state Previous state object.
 * @param {TAction} action Action to process.
 * @returns {TAADResponseState} Returns the new state.
 */
const authReducer = (state = aadInitialState, action: TAction = {type: ''}): TAADResponseState => {
    const { type, payload } = action;
    switch (type)
    {
        case '':
            return state;
        case AuthenticationActions.Initializing:
            return {
                ...state,
                initialising: true,
                initialised: false,
            };
        case AuthenticationActions.Initialized:
            return {
                ...state,
                initialising: false,
                initialised: true,
            };
        case AuthenticationActions.AcquiredIdTokenSuccess:
            return {
                ...state,
                idToken: payload,
            };
        case AuthenticationActions.AcquiredAccessTokenSuccess:
            return {
                ...state,
                accessToken: payload
            };
        case AuthenticationActions.AcquiredAccessTokenError:
            return {
                ...state,
                accessToken: null
            };
        case AuthenticationActions.LoginSuccess:
            return {
                ...state,
                account: payload.account
            };
        case AuthenticationActions.LoginError:
        case AuthenticationActions.AcquiredIdTokenError:
        case AuthenticationActions.LogoutSuccess:
            return {
                ...state,
                idToken: null,
                accessToken: null,
                account: null,
                authState: AuthenticationState.Unauthenticated
            };
        case AuthenticationActions.AuthenticatedStateChanged:
            return {
                ...state,
                authState: payload
            };
        default:
            return state;
    }
};
export default authReducer;