/**
 * @file FormDialog.tsx
 * @description Launches a dialog with a form on the screen - used for multi-record edits and edits/inserts within forms.
 */

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Section from './Section';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TFormDialog, TQuery, TSection } from '../features/view';
import { formEdit, formLoaded, formLoading, TFormData } from '../features/form';
import { TGlobalState } from '../features/global';
import { alertAction } from '../features/alert';
import { runQuery } from '../features/form';
import Grid from '@material-ui/core/Grid';
import { validateTErrorState, parseError, TErrorState, hideError } from '../features/error';
import { TGridState } from '../features/grids';
import CircularProgress from '@material-ui/core/CircularProgress';
import RenderIcon from './RenderIcon';


function RenderedSections(props: { sections: TSection[] }): JSX.Element {
    // console.log('Rendering sections:', props.sections)
    return (<>
        {props.sections.map((sectionProps: TSection, index: number) => {
            return <Section key={index} {...sectionProps} />;
        })}
    </>);
};

export default function FormDialog(props: TFormDialog): JSX.Element {

    // Destructure props
    const { id, label, dialogText, sections, confirmQueries, displayStateSetter, display = false, clearDataOnHide = true } = props;

    // Hook into state to get meta and form data
    const { formData, queries, gridArray, formCurrentlyLoading } = useSelector((state: TGlobalState) => {
        const viewQueries = state.view?.currentView?.query || [];
        let runQueries: TQuery[] = [];
        for (let q of confirmQueries) {
            if (viewQueries[q]) {
                runQueries.push(viewQueries[q]);
            }
        }
        const grids = state.grids;
        let gridArray: TGridState[] = [];
        for (let key in grids) {
            gridArray.push(grids[key]);
        }
        return {
            formData: state.form.selectedValues || {},
            queries: runQueries,
            gridArray: gridArray,
            formCurrentlyLoading: state.form.loading
        };
    });

    // Hook dispatch
    const dispatch = useDispatch();


    // Submit handler
    // Function for running a query when a button is pressed & navigating (if applicable) - uses the runQuery utility function, which submits necessary actions etc. Also handles customActions and customActionParams
    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>, queries: TQuery[], data: TFormData, displayStateSetter?: Function, reason?: string) => {
        // console.log('DEBUG: FormDialog: inside handleSubmit:', event, queries, data, displayStateSetter);
        try {

            // Run the query or queries attached to the button
            let queryResult: TFormData | TErrorState = data;
            if (queries.length > 0) {
                dispatch(alertAction({ severity: 'info', message: 'Processing...', autoHideDuration: 6000 }));
                for (let query of queries) {
                    if (!formCurrentlyLoading) {
                        dispatch(formLoading());
                    }
                    queryResult = await runQuery(query, queryResult, dispatch, false, gridArray, undefined, false);
                    // Don't proceed further if there was an error
                    if (validateTErrorState(queryResult)) {
                        dispatch(formLoaded());
                        dispatch(hideError(queryResult));
                        dispatch(alertAction({ severity: 'error', message: `Error encountered: ${queryResult.message}`, autoHideDuration: 6000, display: true }));
                        return;
                    }
                }
            }

            // Dispatch success message
            dispatch(alertAction({ severity: 'success', message: 'Success!', autoHideDuration: 6000, display: true }));

            // Execute any stateSetter - this will usually hide the dialog
            // Note that any operation that changes whether this dialog is visible should be included here to prevent memory leaks - we can't update the state of an unmounted component
            if (displayStateSetter) {
                displayStateSetter();
            }
        } catch (error) {
            dispatch(formLoaded());
            if (validateTErrorState(error)) {
                dispatch(hideError(error));
                dispatch(alertAction({ severity: 'error', message: `Error encountered: ${error.message}`, autoHideDuration: 6000, display: true }));
                return parseError(error);
            }
            else {
                const errorObj: TErrorState = {
                    status: 'Service Error',
                    message: error.message,
                    stack: error.stack,
                    correlationId: 'FEATURES/FORM_DIALOG/RUN_QUERY/SYSTEM_ERROR',
                    display: true
                };
                dispatch(hideError(errorObj));
                dispatch(alertAction({ severity: 'error', message: `Error encountered: ${error.message}`, autoHideDuration: 6000, display: true }));
                if (displayStateSetter) {
                    displayStateSetter();
                }
            }
        }
    };

    // handleSave is like handleSubmit, but only runs queries (saves) and doesn't close or navigate away.
    const handleSave = async (event: React.MouseEvent<HTMLButtonElement>, queries: TQuery[], data: TFormData) => {
        // console.log('DEBUG: FormDialog: inside handleSave:', event, queries, data, displayStateSetter);
        try {

            // Run the query or queries attached to the button
            let queryResult: TFormData | TErrorState = data;
            if (queries.length > 0) {
                dispatch(alertAction({ severity: 'info', message: 'Processing...', autoHideDuration: 1000 }));
                for (let query of queries) {
                    if (!formCurrentlyLoading) {
                        dispatch(formLoading());
                    }
                    queryResult = await runQuery(query, queryResult, dispatch, false, gridArray, undefined, false);
                    // Don't proceed further if there was an error but don't display the error screen
                    if (validateTErrorState(queryResult)) {
                        dispatch(hideError(queryResult));
                        dispatch(alertAction({ severity: 'error', message: `Error encountered: ${queryResult.message}`, autoHideDuration: 6000, display: true }));
                        return;
                    }
                }
                // Dispatch success message
                dispatch(alertAction({ severity: 'success', message: 'Success!', autoHideDuration: 6000, display: true }));
                dispatch(formLoaded());
            }
            else {
                dispatch(alertAction({ severity: 'info', message: 'Nothing to do!', autoHideDuration: 2000, display: true }));
            }
        } catch (error) {
            dispatch(formLoaded());
            if (validateTErrorState(error)) {
                dispatch(hideError(error));
                dispatch(alertAction({ severity: 'error', message: `Error encountered: ${error.message}`, autoHideDuration: 6000, display: true }));
                return;
            }
            else {
                const errorObj: TErrorState = {
                    status: 'Service Error',
                    message: error.message,
                    stack: error.stack,
                    correlationId: 'FEATURES/FORM_DIALOG/RUN_QUERY/SYSTEM_ERROR',
                    display: true
                };
                dispatch(hideError(errorObj));
                dispatch(alertAction({ severity: 'error', message: `Error encountered: ${error.message}`, autoHideDuration: 6000, display: true }));
            }
        }
    };

    // handleClose is the cancel handler
    const handleClose = (event: React.MouseEvent, reason?: string) => {
        if (reason === 'backdropClick') {
            return;
        }
        else if (clearDataOnHide) {
            for (let key in formData) {
                if (/^dialog_/.test(key)) {
                    dispatch(formEdit({
                        key: key,
                        value: ''
                    }));
                }
            }
        }
        displayStateSetter && displayStateSetter();
    }

    return (
        <Dialog
            open={display}
            onClose={handleClose}
            aria-labelledby={`${id}-form-dialog-title`}
            fullWidth={true}
            maxWidth='lg'
        >
            {label ? <DialogTitle id={`${id}-form-dialog-title`}>
                {label}
            </DialogTitle>
                : null}
            <DialogContent>
                {formCurrentlyLoading ? <Grid container direction="column" alignItems="center" style={{ margin: 'auto', textAlign: 'center' }}><DialogContentText>Please wait...</DialogContentText><CircularProgress color="secondary" size={30} /></Grid> : <DialogContentText style={{ margin: 'auto', textAlign: 'center' }}>{dialogText}</DialogContentText>}
                <Grid item xs={12} container direction="column" justify="flex-start" spacing={2}>
                    <RenderedSections sections={sections} />
                </Grid>
            </DialogContent>
            <DialogActions>
                {confirmQueries.length > 0 ? <>
                <Button style={{border: formCurrentlyLoading ? '3px solid #773333' : '3px solid #FF0000', borderRadius: '6px'}} onClick={(event: React.MouseEvent<HTMLButtonElement>, reason?: string) => handleSave(event, queries, formData || {})} color="secondary" disabled={formCurrentlyLoading}><RenderIcon id="save-and-continue" staticValue="SaveIcon" color="secondary"></RenderIcon>&nbsp;Save &amp; Continue</Button>
                <Button style={{border: formCurrentlyLoading ? '3px solid #773333' : '3px solid #FF0000', borderRadius: '6px'}} onClick={(event: React.MouseEvent<HTMLButtonElement>, reason?: string) => handleSubmit(event, queries, formData || {}, displayStateSetter, reason)} color="secondary" disabled={formCurrentlyLoading}><RenderIcon id="save-and-close-start" staticValue="SaveIcon" color="secondary"></RenderIcon>&nbsp;Save &amp; Close&nbsp;<RenderIcon id="save-and-close-end" staticValue="CancelIcon" color="secondary"></RenderIcon></Button></>
                : null }
                <Button style={{border: formCurrentlyLoading ? '3px solid #ddd' : '3px solid #999', borderRadius: '6px'}} onClick={handleClose} color="primary" disabled={formCurrentlyLoading}><RenderIcon id="close-start" staticValue="CancelIcon" color="primary"></RenderIcon>&nbsp;{confirmQueries.length > 0 ? 'Cancel' : 'Close'}</Button>
            </DialogActions>
        </Dialog>
    );
}