/**
 * @file features/global/apiUrls
 * @description API URL configurations. These use env vars that should be set at build time.
 */

/**
 * URL for fetching a user's own profile.
 */
export const profileUrl = `${process.env.REACT_APP_API_URL_ROOT}/run/users/getmyprofile?requestingapp=${process.env.REACT_APP_AUTH_APP_NAME}`;

/**
 * URL for fetching a user's available screens.
 */
export const screensUrl = `${process.env.REACT_APP_API_URL_ROOT}/run/metadata/getmyscreens?requestingapp=${process.env.REACT_APP_AUTH_APP_NAME}`;

/**
 * URL for fetching view metadata.
 */
export const viewUrl = `${process.env.REACT_APP_API_URL_ROOT}/run/metadata/getviewmeta?requestingapp=${process.env.REACT_APP_AUTH_APP_NAME}`;

/**
 * Base Saga URL
 */
export const sagaUrl = `${process.env.REACT_APP_API_URL_ROOT}/run/`;

/**
 * File download URL
 */
export const fileDownloadUrl = `${process.env.REACT_APP_API_URL_ROOT}/run/files/download?requestingapp=${process.env.REACT_APP_AUTH_APP_NAME}`;

/**
 * New file URL
 */
export const fileCreateUrl = `${process.env.REACT_APP_API_URL_ROOT}/run/files/newfile?requestingapp=${process.env.REACT_APP_AUTH_APP_NAME}`;

/**
 * Update file URL
 */
export const fileUpdateUrl = `${process.env.REACT_APP_API_URL_ROOT}/run/files/updatefile?requestingapp=${process.env.REACT_APP_AUTH_APP_NAME}`;