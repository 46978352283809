/**
 * @component AdornEnd.tsx
 * @description End adornment component for input fields - accepts two props - the data type and whether the component is disabled.
 */

import React from 'react';
import { EDataType, TDataType } from '../features/view';
import LockIcon from '@material-ui/icons/Lock';
import PhoneIcon from '@material-ui/icons/Phone';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import Typography from '@material-ui/core/Typography';
import EthernetIcon from '@material-ui/icons/SettingsEthernet';
import EmailIcon from '@material-ui/icons/AlternateEmail';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CalendarIcon from '@material-ui/icons/Today';
import TimerIcon from '@material-ui/icons/Timer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CodeIcon from '@material-ui/icons/Code';
import QrScannerIcon from './QrScannerIcon';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocationIcon from '@material-ui/icons/LocationOn';

interface IProps {
    disabled: boolean;
    dataType: TDataType | 'location';
    error?: boolean;
    barcodeInput?: boolean;
    loadingCircle?: boolean;
}

export default function AdornEnd(props: IProps): JSX.Element {
    const { disabled, dataType, error, barcodeInput, loadingCircle } = props;
    const colour = disabled ? 'primary' : error ? 'secondary' : 'primary';
    if (disabled) {
        return loadingCircle ? <CircularProgress color="primary" size={10} /> : <LockIcon color="primary" />;
    }
    else if (barcodeInput) {
        return <QrScannerIcon color={colour} />;
    }
    else {
        switch (dataType) {
            case 'phone':
                return <PhoneIcon color={colour} />;
            case 'currency':
                return <MoneyIcon color={colour} />;
            case 'uuid':
                return <Typography variant="subtitle1" color={colour}>UUID</Typography>;
            case 'macaddress':
                return <EthernetIcon color={colour} />;
            case 'number':
                return <Typography variant="subtitle1" color={colour}>123...</Typography>;
            case 'email':
                return <EmailIcon color={colour} />;
            case 'boolean':
                return <CheckCircleIcon color={colour} />;
            case 'date':
                return <CalendarIcon color={colour} />;
            case 'duration':
                return <TimerIcon color={colour} />;
            case 'object':
                return <Typography variant="subtitle1" color={colour}>{'{...}'}</Typography>
            case 'array':
                return <Typography variant="subtitle1" color={colour}>{'[...]'}</Typography>
            case 'code':
                return <CodeIcon color={colour} />;
            case 'file':
                return <AttachFileIcon color={colour} />;
            case 'location':
                return <LocationIcon color={colour} />;
            case 'link':
            case 'string':
            default:
                return <Typography variant="subtitle1" color={colour}>{EDataType[dataType]}</Typography>
        }
    }
}