/**
 * @component EditLongText.tsx
 * @description The EditLongText component is for displaying multi-line text input. This includes rich text and SQL code input. Like the EditShortText component, it uses the useValidation hook.
 */

// Imports
import React, { ChangeEventHandler, forwardRef, MutableRefObject, RefObject, useState } from 'react';
import TextAreaAutosize from '@material-ui/core/TextareaAutosize';
import CodeEditor from 'react-simple-code-editor';
import hljs from 'highlight.js';
import RichText from './RichText';
import ReactJson, { InteractionProps, ThemeKeys } from 'react-json-view';
import { useValidation } from '../features/form';
import { TInputComponent } from '../features/view';
import { useSelector } from 'react-redux';
import { TGlobalState } from '../features/global';

// Declare the component with a forwardRef, so that we can pass a ref for adjusting focus, etc
const EditLongText: React.FC<TInputComponent> = forwardRef((props: TInputComponent, ref) => {
    // Destructure props
    const { id, label, dataKey, defaultValue, defaultValueKeys, forceDefaultValueStart = false, defaultValueSeparator, required = false, style, className, updateKeys, dataType = 'string', staticValue, code, disabled, alreadyMounted } = props;

    // Set internal state variables
    const [value, setValue] = useState(staticValue || defaultValue || (dataType === 'code' && code === 'json' ? '{}' : ''));

    // Get form loading state - we disable input if it's loading
    const disableInput = useSelector((state: TGlobalState) => {
        return disabled ? true : state.form.loading ? true : false;
    });

    // Get the event handler from the useValidation hook
    const { handleChange, handleBlur } = useValidation(dataKey, required, alreadyMounted as MutableRefObject<boolean>, dataType === 'code' && code === 'richtext' ? null : setValue, null, null, dataType, updateKeys, defaultValue, defaultValueKeys, defaultValueSeparator, forceDefaultValueStart, staticValue, label, undefined, undefined, undefined, code);

    // Set the width to 100% to make sure it fills the container
    const styleToUse = Object.assign({}, style || {}, { width: '100%' });

    // Render the component depending on its type
    if (dataType === 'code' && code === 'pgsql') {
        return (
            <CodeEditor
                value={value}
                disabled={disableInput}
                onValueChange={handleChange}
                onBlur={handleBlur}
                highlight={code => hljs.highlight('pgsql', code).value}
                padding={10}
                style={{
                    fontFamily: 'monospace',
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    background: 'white',
                    border: '1px solid black',
                    boxShadow: 'inset 0 0 6px rgba(181, 181, 181, 0.8)'
                }}
                ref={ref as RefObject<any>}
            />
        );
    }
    else if (dataType === 'code' && code === 'richtext') {
        return (
            <RichText
                dataKey={dataKey}
                disabled={disableInput}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        );
    }
    else if (dataType === 'code' && code === 'json') {
        let parsedValue: any = {};
        try {
            parsedValue = typeof value === 'object' ? value : JSON.parse(value);
        } catch (error) {
            parsedValue = {
                error: 'Unable to parse value',
                message: error.message,
                stack: process.env.NODE_ENV !== 'production' ? error.stack : 'Please fix the data displayed here - it must be valid JSON. If the problem persists, please contact Support',
                correlationId: 'COMPONENTS/EDITLONGTEXT/JSON_PARSE_ERROR'
            };
        }
        const jsonChangeHandler = (editObject: InteractionProps) => {
            const newValue = JSON.stringify(editObject.updated_src);
            handleChange(newValue);
        }
        if (disableInput) {
            return (
                <ReactJson
                    src={parsedValue}
                    theme={className as ThemeKeys || 'bright:inverted'}
                    name={false}
                    style={styleToUse}
                />
            );
        }
        else {
            return (
                <ReactJson
                    src={parsedValue}
                    theme={className as ThemeKeys || 'bright:inverted'}
                    name={false}
                    style={styleToUse}
                    onEdit={jsonChangeHandler}
                    onAdd={jsonChangeHandler}
                    onDelete={jsonChangeHandler}
                />
            );

        }

    }
    else {
        return (
            <TextAreaAutosize
                id={id}
                disabled={disableInput}
                aria-label={label}
                value={value}
                onChange={handleChange as ChangeEventHandler}
                onBlur={handleBlur}
                required={required}
                style={styleToUse}
                className={className}
                ref={ref as RefObject<HTMLTextAreaElement>}
            />
        )
    }
});
export default EditLongText;