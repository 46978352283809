/**
 * @component DurationPicker.tsx
 * @description Component for entering a duration - is basically an text field that requires input to be in PostgreSQL duration format minus the @ sign (i.e. needs at least a number and a duration unit; can also take 'ago' etc). Also provides two MUI date/time pickers for start and end to calculate a duration.
 */

// Imports
import React, { forwardRef, RefObject, useState, useEffect, MutableRefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { useValidation, formEdit } from '../features/form';
import { TInputComponent, EDataType, TDateComponent } from '../features/view';
import Grid from '@material-ui/core/Grid';
import { calculateDuration, TGlobalState } from '../features/global';
import AdornEnd from './AdornEnd';
import Typography from '@material-ui/core/Typography';
import dayjs from 'dayjs';

// Declare the component with a forwardRef, so that we can pass a ref for adjusting focus, etc
const DurationPicker: React.FC<TInputComponent & TDateComponent> = forwardRef((props: TInputComponent & TDateComponent, ref) => {
    // Destructure props
    const { id, label, showLabel, dataKey, defaultValue, defaultValueKeys, required = false, style, className, updateKeys, staticValue, disabled = false, datePickerType, alreadyMounted, defaultStartEnd } = props;
    const formatString = datePickerType === 'time' ? 'HH:mm' : datePickerType === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DDTHH:mm';

    // Hook state to get "ready time"
    const readyTime = useSelector((state: TGlobalState) => state.view.readyTime);

    // Set internal state variables
    const [value, setValue] = useState('');
    const [start, setStart] = useState(defaultStartEnd && readyTime ? dayjs(readyTime.toISOString()).format(formatString) : '');
    const [end, setEnd] = useState(defaultStartEnd && readyTime ? dayjs().format(formatString) : '');
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');

    // Get the event handlers from the useValidation hook
    const { handleChange, handleBlur } = useValidation(dataKey, required, alreadyMounted as MutableRefObject<boolean>, setValue, setError, setErrorText, 'duration', updateKeys, defaultValue, defaultValueKeys, ' ', false, staticValue, label);

    // useEffect hook to check if we have a start/end - if so, compute the duration
    const dispatch = useDispatch();
    useEffect(() => {
        let mounted = true;
        
        if (mounted && start && end) {
            const duration = calculateDuration(start, end, formatString);
            if (duration) {
                setValue(duration);
                dispatch(formEdit({
                    key: dataKey,
                    value: duration
                }));
                if (updateKeys && updateKeys instanceof Array) {
                    for (let key of updateKeys) {
                        dispatch(formEdit({
                            key: key,
                            value: duration
                        }));
                    }
                }
                else if (updateKeys && typeof updateKeys === 'string') {
                    dispatch(formEdit({
                        key: updateKeys,
                        value: duration
                    }));
                }
            }
        }

        return () => {
            mounted = false;
        }
    }, [start, end, dataKey, updateKeys, dispatch, setValue, datePickerType, formatString]);

    // Render the component
    return (
        <Tooltip
            title={disabled ? 'Editing locked' : showLabel !== false && label ? label : `Enter ${EDataType.duration}`}
            aria-label={`${id}-enter-duration`}
        >
            <Grid container item xs={12} direction="column" justify="flex-start" spacing={1}>
                <Grid item xs={12}>
                    <TextField
                        id={id}
                        label={disabled ? '' : 'Type here (e.g. 3 hours 15 minutes)'}
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={required}
                        disabled={disabled}
                        error={error}
                        helperText={errorText}
                        InputProps={{
                            endAdornment: <AdornEnd disabled={disabled} dataType='duration' error={error} />
                        }}
                        style={style}
                        className={className}
                        inputRef={ref as RefObject<HTMLInputElement>}
                        fullWidth={true}
                    />
                </Grid>
                <Grid item xs={12} container direction="row" justify="space-between">
                    <Grid item xs={1}>
                        <Typography variant="h5" style={{visibility: disabled ? 'hidden' : 'visible'}}>or select start:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            id={`${id}-start-datetime`}
                            aria-invalid={error}
                            color="secondary"
                            aria-label={`${id}-start-datetime`}
                            value={start}
                            onChange={(e) => setStart(e.currentTarget.value)}
                            type={datePickerType || "datetime-local"}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={1}>
                    <Typography variant="h5" style={{visibility: disabled ? 'hidden' : 'visible'}}>select end:</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            id={`${id}-end-datetime`}
                            aria-invalid={error}
                            color="secondary"
                            aria-label={`${id}-end-datetime`}
                            value={end}
                            onChange={(e) => setEnd(e.currentTarget.value)}
                            type={datePickerType || "datetime-local"}
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
            </Grid>

        </Tooltip>
    );
});
export default DurationPicker;