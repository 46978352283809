/**
 * @component FileAttachmentUpload.tsx
 * @description Component for handling file attachment uploads. Displays a button - when pressed, the button will show a form dialog that will allow file info to be entered and a file to be uploaded.
 */

// Imports - hooks, components
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormDialog from './FormDialog';
import Button from './Button';


// Imports - types, interfaces, non-hook functions
import { enumToArray, TGlobalState } from '../features/global';
import { fileCategories, TFileITBlob, TFileITCategory, fetchFile, uploadFile, EMimeType, TMimeType } from '../features/files';
import { TFileAttachment, TSection } from '../features/view';
import { formEdit, formLoaded, formLoading } from '../features/form';
import b64toblob from 'b64-to-blob';
import { alertAction } from '../features/alert';
import { validateTErrorState } from '../features/error';
import dayjs from 'dayjs';

// Declare and export the component
export default function FileAttachment(props: TFileAttachment): JSX.Element {

    // Destructure props
    const { id, dataKey, defaultValue, defaultValueKeys = [], saveFileIdQuery, staticValue, disabled, categoryId, categoryIdKey, recordTableName, recordIdKey, uploadable = false, uploadSizeLimit = 0, projectIdKey = '', clearDataOnHide = false } = props;

    // Hook Redux state - get relevant form data values, ready state, etc.
    const { selectedCategoryIndex, categoryUuid, fileUuid, currentFileName, currentFileDate, currentFileTitle, currentFileDescription, fileRecord, currentProjectId, formReady } = useSelector((state: TGlobalState) => {
        const selectedId = categoryId || (categoryIdKey ? state.form.selectedValues?.[categoryIdKey] : 0);
        const categoryUuid = fileCategories.find(category => category.categoryId === selectedId)?.categoryUuid || '';
        const fileUuid = staticValue || state.form.selectedValues?.[dataKey] || defaultValue || (defaultValueKeys.length > 0 ? state.form.selectedValues?.[defaultValueKeys[0]] : '') || '';
        let fileRecordIdProperty = 'mappingId';
        switch (recordTableName) {
            case 'services':
                fileRecordIdProperty = 'stageitServiceId';
                break;
            case 'orders':
                fileRecordIdProperty = 'stageitOrderId';
                break;
            case 'assets':
                fileRecordIdProperty = 'stageItAssetId';
                break;
            case 'cost_recovery':
                fileRecordIdProperty = 'stageitReportId';
                break;
        }
        return {
            selectedCategoryIndex: fileCategories[selectedId] !== undefined ? selectedId : -1,
            categoryUuid: categoryUuid,
            fileUuid: fileUuid,
            fileRecord: {
                propertyName: fileRecordIdProperty,
                keyValue: recordIdKey ? state.form.selectedValues?.[recordIdKey] : null
            },
            currentFileName: state.form.selectedValues?.[`${dataKey}FileName`],
            currentFileDate: state.form.selectedValues?.[`${dataKey}FileDate`],
            currentFileTitle: state.form.selectedValues?.[`${dataKey}FileTitle`],
            currentFileDescription: state.form.selectedValues?.[`${dataKey}FileDescription`],
            currentProjectId: state.form.selectedValues?.[projectIdKey] || 0,
            formReady: state.form.ready
        };
    });

    // Configure internal state
    const dispatch = useDispatch();
    const fileCategory = useMemo<TFileITCategory | null>(() => {
        return fileCategories[selectedCategoryIndex] !== undefined ? fileCategories[selectedCategoryIndex] : null;
    }, [selectedCategoryIndex]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    // Hook for downloading the file if we have IDs but no file and the dialog is open
    const fileBlob = useRef<TFileITBlob | null>(null);
    useEffect(() => {
        let active = true;
        // console.log('FileAttachment useEffect:', loading, categoryUuid, fileUuid);

        // No IDs, already loading, not open or form not ready? Return undefined
        if (!active || !formReady || loading || !open || !categoryUuid || !fileUuid) {
            return undefined;
        }

        // No file but we have IDs? Fetch
        (async () => {
            if (active && !loading && formReady && open && categoryUuid && fileUuid && !fileBlob.current) {
                setLoading(true);
                dispatch(formLoading());
                const fetchedBlob = await fetchFile({ categoryId: categoryUuid, fileId: fileUuid }, dispatch);
                if (validateTErrorState(fetchedBlob)) {
                    dispatch(formLoaded());
                    setLoading(false);
                    return;
                }
                else {
                    dispatch(formLoaded());
                    const updatedBlob = Object.assign({}, fetchedBlob, { [fileRecord.propertyName]: fileRecord.keyValue });
                    dispatch(formEdit({
                        key: `${dataKey}FileTitle`,
                        value: fetchedBlob.title || ''
                    }));
                    dispatch(formEdit({
                        key: `${dataKey}FileName`,
                        value: fetchedBlob.fileName || ''
                    }));
                    dispatch(formEdit({
                        key: `${dataKey}FileDate`,
                        value: dayjs(fetchedBlob.fileDate).format('YYYY-MM-DD') || ''
                    }));
                    dispatch(formEdit({
                        key: `${dataKey}FileDateCreated`,
                        value: fetchedBlob.createdDate || ''
                    }));
                    dispatch(formEdit({
                        key: `${dataKey}FileDateModified`,
                        value: fetchedBlob.modifiedDate || ''
                    }));
                    dispatch(formEdit({
                        key: `${dataKey}FileDescription`,
                        value: fetchedBlob.description || ''
                    }));
                    dispatch(formEdit({
                        key: `${dataKey}FileDownloadButton`,
                        value: fetchedBlob && fetchedBlob.fileContents && fetchedBlob.mimeType && fetchedBlob.fileName ? fetchedBlob.fileName : ''
                    }));
                    fileBlob.current = updatedBlob;
                    setLoading(false);
                }
            }
        })();

        // Cleanup function
        return () => {
            active = false;
        }
    }, [loading, categoryUuid, fileUuid, dispatch, dataKey, fileRecord.propertyName, fileRecord.keyValue, open, formReady]);

    // If the dialog isn't mounted, clear the file to free memory
    useEffect(() => {
        return () => {
            // console.log('file attachment dialog executing cleanup');
            //     console.log('file attachment dialog closed');
            fileBlob.current = null;
        };
    }, []);

    // Handler for opening the dialog
    // Dispatches relevant values to formData
    const handleOpen = () => {
        dispatch(formEdit({
            key: `${dataKey}FileTitle`,
            value: fileBlob.current?.title || ''
        }));
        dispatch(formEdit({
            key: `${dataKey}FileName`,
            value: fileBlob.current?.fileName || ''
        }));
        dispatch(formEdit({
            key: `${dataKey}FileDate`,
            value: dayjs(fileBlob.current?.fileDate).format('YYYY-MM-DD') || ''
        }));
        dispatch(formEdit({
            key: `${dataKey}FileDateCreated`,
            value: fileBlob.current?.createdDate || ''
        }));
        dispatch(formEdit({
            key: `${dataKey}FileDateModified`,
            value: fileBlob.current?.modifiedDate || ''
        }));
        dispatch(formEdit({
            key: `${dataKey}FileDescription`,
            value: fileBlob.current?.description || ''
        }));
        dispatch(formEdit({
            key: `${dataKey}FileDownloadButton`,
            value: fileBlob && fileBlob.current?.fileContents && fileBlob.current?.mimeType && fileBlob.current?.fileName ? fileBlob.current?.fileName : ''
        }));
        dispatch(formEdit({
            key: `${dataKey}FileRecordId`,
            value: fileRecord.keyValue
        }));
        dispatch(formEdit({
            key: `${dataKey}FileUuid`,
            value: fileUuid
        }));
        setOpen(true);
    };

    // Close handler
    const handleClose = () => {
        setOpen(false);
    };

    // File selection handler
    const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Check we have a file
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            dispatch(formEdit({
                key: `${dataKey}FileName`,
                value: file.name
            }));
            // Check the MIME type
            if (file.type && fileCategory && fileCategory.acceptedMimeTypes.indexOf(file.type as TMimeType) === -1) {
                dispatch(alertAction({
                    severity: 'warning',
                    message: `Unsupported file type - please select one of the following file types: ${fileExtensions.join(', ')}`
                }));
                event.target.value = '';
                return;
            }
            // Check the size
            if (uploadSizeLimit && file.size && file.size > uploadSizeLimit) {
                let readableSize = `${uploadSizeLimit}B`;
                if (uploadSizeLimit > 1024) {
                    readableSize = `${(uploadSizeLimit / 1024).toFixed(2)}KB`;
                }
                if (uploadSizeLimit > 1048576) {
                    readableSize = `${(uploadSizeLimit / 1048576).toFixed(2)}MB`;
                }
                if (uploadSizeLimit > 1073741824) {
                    readableSize = `${(uploadSizeLimit / 1073741824).toFixed(2)}GB`;
                }
                dispatch(alertAction({
                    severity: 'warning',
                    message: `The file you have uploaded is too large. The maximum supported file size is ${readableSize}`
                }));
                event.target.value = '';
                return;
            }

            // If these checks passed, update the selection in state and props
            const reader = new FileReader();
            reader.onload = () => {
                let base64String = reader && reader.result && typeof reader.result === 'string' ? reader.result.replace(/^data:.+;base64,/, '') : '';
                const updateObj: TFileITBlob = {
                    mimeType: file.type as TMimeType,
                    fileContents: base64String,
                    fileName: file.name,
                    description: currentFileDescription,
                    categoryId: categoryUuid,
                    title: currentFileTitle,
                    fileDate: currentFileDate,
                    projectId: currentProjectId || 0
                };
                const updatedBlob: TFileITBlob = Object.assign({}, fileBlob, updateObj);
                // console.log('DEBUG: updatedBlob', updatedBlob);
                fileBlob.current = updatedBlob;
                dispatch(alertAction({
                    severity: 'success',
                    message: 'File selected. Press "Save Selected File" to upload it. When uploaded, press "Submit" to link it to this record'
                }));
            };
            reader.readAsDataURL(file);
        }
        else {
            dispatch(alertAction({
                severity: 'info',
                message: 'No file selected',
                autoHideDuration: 3000,
                display: true
            }));
        }
    };

    // Handle file upload
    const handleFileUpload = async () => {
        if (fileBlob.current) {
            dispatch(alertAction({
                severity: 'info',
                message: 'Uploading file...'
            }));
            // console.log('fileBlob properties:', fileBlob);
            dispatch(formLoading());
            fileBlob.current = Object.assign({}, fileBlob.current, {
                fileName: currentFileName,
                title: currentFileTitle,
                description: currentFileDescription,
                projectId: currentProjectId,
                [fileRecord.propertyName]: fileRecord.keyValue
            });
            const uploadResult = await uploadFile(fileBlob.current, dispatch);
            if (validateTErrorState(uploadResult)) {
                dispatch(formLoaded());
                dispatch(alertAction({
                    severity: 'error',
                    message: 'File upload error!'
                }));
            }
            else {
                dispatch(formLoaded({
                    [`${dataKey}FileUuid`]: uploadResult.fileId,
                    [`${dataKey}FileDateCreated`]: uploadResult.createdDate,
                    [`${dataKey}FileDateModified`]: uploadResult.modifiedDate
                }));
                dispatch(formEdit({
                    key: dataKey,
                    value: uploadResult.fileId
                }))
                // console.log('uploadResult:', uploadResult);
                dispatch(alertAction({
                    severity: 'success',
                    message: 'File uploaded successfully! Press "Submit" to link it to this record'
                }));
            }

        }
        else {
            dispatch(alertAction({
                severity: 'warning',
                message: 'No file to save'
            }));
        }
    };

    // Handle file download
    const handleFileDownload = () => {
        if (fileBlob.current && fileBlob.current.fileContents) {
            dispatch(alertAction({
                severity: 'info',
                message: `Downloading ${currentFileName}`,
                display: true,
                autoHideDuration: 6000
            }));
            const blob = b64toblob(fileBlob.current.fileContents, fileBlob.current.mimeType);
            let link = document.createElement('a');
            link.download = currentFileName;
            link.href = URL.createObjectURL(blob);
            link.click();
            URL.revokeObjectURL(link.href);
        }
    }

    // Dialog Sections
    const mimeTypes = enumToArray(EMimeType);
    const fileExtensions: string[] = fileCategory && fileCategory.acceptedMimeTypes ? mimeTypes.filter(mime => (fileCategory?.acceptedMimeTypes as string[]).indexOf(mime.key) > 0).map(mime => mime.value) : [];
    const dialogSections: TSection[] = [
        {
            heading: 'File Category Details',
            componentsPerRow: 2,
            components: [
                {
                    id: `${id}-dialog-category-name`,
                    componentType: 'ViewText',
                    dataKey: `${dataKey}FileCategoryName`,
                    staticValue: fileCategory?.categoryName || '',
                    label: 'Category Name'
                },
                {
                    id: `${id}-dialog-accepted-file-types`,
                    componentType: 'ViewText',
                    dataKey: `${dataKey}AcceptedMimes`,
                    staticValue: fileExtensions.length > 0 ? fileExtensions.join(', ') : '',
                    label: 'Accepted File Types'
                }
            ]
        },
        {
            heading: 'File Details',
            componentsPerRow: 1,
            components: [
                {
                    id: `${id}-download-button`,
                    componentType: 'Button',
                    dataKey: `${dataKey}FileDownloadButton`,
                    label: 'Download Current File',
                    button_purpose: 'misc',
                    iconName: 'DownloadIcon',
                    customAction: handleFileDownload,
                    hideable: true,
                    text: 'Download',
                    showRules: [
                        {
                            testKey: `${dataKey}FileDownloadButton`
                        }
                    ]
                },
                {
                    id: `${id}-dialog-file-date`,
                    componentType: uploadable ? 'DateTimePicker' : 'ViewText',
                    dataKey: `${dataKey}FileDate`,
                    defaultValue: fileBlob.current?.fileDate,
                    label: 'File Dated',
                    datePickerType: 'date',
                    required: true,
                    dataType: 'date'
                },
                {
                    id: `${id}-dialog-file-title`,
                    componentType: uploadable ? 'EditShortText' : 'ViewText',
                    dataKey: `${dataKey}FileTitle`,
                    label: 'File Title',
                    required: true
                },
                {
                    id: `${id}-dialog-file-description`,
                    componentType: uploadable ? 'EditShortText' : 'ViewText',
                    dataKey: `${dataKey}FileDescription`,
                    label: 'File Description',
                    required: true
                },
                {
                    id: `${id}-dialog-file-created`,
                    componentType: 'ViewText',
                    dataType: 'date',
                    dateFormat: 'DD/MM/YYYY h:mm:ss a',
                    dataKey: `${dataKey}FileDateCreated`,
                    defaultValue: fileBlob.current?.createdDate || '',
                    label: 'File Created Date'
                },
                {
                    id: `${id}-dialog-file-modified`,
                    componentType: 'ViewText',
                    dataType: 'date',
                    dateFormat: 'DD/MM/YYYY h:mm:ss a',
                    dataKey: `${dataKey}FileDateModified`,
                    defaultValue: fileBlob.current?.modifiedDate || '',
                    label: 'File Modified Date'
                },
                {
                    id: `${id}-dialog-file-name`,
                    componentType: 'ViewText',
                    dataKey: `${dataKey}FileName`,
                    label: 'Filename'
                },
                {
                    id: `${id}-dialog-file-button-notes`,
                    componentType: 'ViewText',
                    dataKey: `${dataKey}FileButtonNotes`,
                    staticValue: 'The "Select New File" button will become enabled when you have completed all required fields. The "Upload New File" button will become available when all fields are complete and a file has been selected.',
                    label: 'Select & Upload buttons',
                    show: uploadable ? true : false
                },
                {
                    id: `${id}-dialog-attach-button`,
                    componentType: 'Button',
                    dataKey: `${dataKey}FileAttachButton`,
                    button_purpose: 'fileSelect',
                    iconName: 'FileIcon',
                    text: 'Select New File',
                    color: 'primary',
                    customAction: handleFileSelection,
                    customActionParams: fileCategory?.acceptedMimeTypes,
                    label: 'Select New file',
                    show: uploadable ? true : false,
                    disabled: !disabled && currentFileDate && currentFileTitle && currentFileDescription ? false : true,
                },
                {
                    id: `${id}-dialog-upload-button`,
                    componentType: 'Button',
                    dataKey: `${dataKey}FileUploadButton`,
                    button_purpose: 'misc',
                    iconName: 'CloudUploadIcon',
                    text: 'Save Selected File',
                    color: 'secondary',
                    customAction: handleFileUpload,
                    label: 'Upload New file',
                    show: uploadable ? true : false,
                    disabled: !disabled && currentFileDate && currentFileTitle && currentFileName && currentFileDescription && fileBlob ? false : true,
                },
            ]
        },
        {
            heading: 'Record Details',
            componentsPerRow: 1,
            components: [
                {
                    id: `${id}-dialog-record-text`,
                    componentType: 'ViewText',
                    dataKey: `${dataKey}FileRecordNotes`,
                    staticValue: `If you press "Submit", the file with the above details and below UUID will be linked to the below ${recordTableName} Record ID (if any).`,
                    show: uploadable ? true : false
                },
                {
                    id: `${id}-dialog-record-id`,
                    componentType: 'ViewText',
                    dataKey: `${dataKey}FileRecordId`,
                    label: 'StageIT Record ID#'
                },
                {
                    id: `${id}-dialog-file-id`,
                    componentType: 'ViewText',
                    dataKey: `${dataKey}FileUuid`,
                    label: 'FileIT File UUID'
                }
            ]
        }
    ];

    // Render the component - it is a fragment wrapping the dialog component and button component
    return (<>
        <FormDialog id={`${id}-dialog-${open ? 'open' : 'closed'}`} clearDataOnHide={clearDataOnHide} label={`FileIT ${uploadable ? 'Up' : 'Down'}load`} dialogText={uploadable ? 'Select and upload a file to FileIT using this dialog (or download the current file). If uploading, please enter a file date, file title and filename. Use the "Select File" button to select a file and the "Upload the file" button to save it. After the file has been saved, press "Submit" to link the new file to this record.' : 'File Details and the Download button appear below.'} confirmQueries={uploadable ? [saveFileIdQuery] : []} sections={dialogSections} display={open} displayStateSetter={handleClose} />
        <Button id={`${id}-button-opendialog`} iconName={uploadable ? "CloudUploadIcon" : "FileIcon"} button_purpose="formDialogPopup" disabled={disabled} customAction={handleOpen} text={`${uploadable ? 'Up' : 'Down'}load File`} />
    </>);
}