/**
 * @component ErrorBoundary.tsx
 * @description Used as a wrapper around other components to prevent an unhandled exception/crash in one from corrupting the entire component tree. Should be wrapped around logical groupings of components (and dynamic components).
 */

import React, { ErrorInfo } from 'react';
import { connect } from 'react-redux';
import { showError, TErrorState, parseError, validateTErrorState } from '../features/error';
import { TGlobalState } from '../features/global';

interface IProps {
    dispatchError: (error: TErrorState) => void;
    correlationIdText: string;
}
interface IState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<React.PropsWithChildren<IProps>, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    // Use the componentDidCatch lifecycle method to dispatch errors
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        if (validateTErrorState(error)) {
            return this.props.dispatchError(parseError(error));
        }
        else {
            const errorObj: TErrorState = {
                status: 'Service Error',
                message: error.message,
                stack: errorInfo.componentStack,
                correlationId: this.props.correlationIdText,
                display: true
            };
            return this.props.dispatchError(errorObj);
        }
    }

    render() {
        if (this.state.hasError) {
            return (<></>);
        }
        else {
            return this.props.children;
        }
    }
}

// Map Redux state, props. Connect and export
const mapState = (state: TGlobalState) => ({
    hasError: state.error.display
});
const mapDispatch = ({
    dispatchError: (error: TErrorState) => showError(error)
});
const connector = connect(mapState, mapDispatch);
export default connector(ErrorBoundary);