/**
 * @file features/alert/reducer
 * @description The reducer for alert actions.
 */

// Imports
import { ALERT } from './actions';
import alertInitialState from './initialState';
import { TAlertState, TAlertAction } from './types';

/**
 * The alert reducer updates Redux state based on actions/payloads.
 * @param {TAlertState} state Previous state object.
 * @param {TAlertAction} action Action to process.
 * @returns {TAlertState} Returns the new state.
 */
const alertReducer = (state = alertInitialState, action: TAlertAction | undefined): TAlertState => {
    if (!action)
    {
        return state;
    }
    const { type, payload } = action;
    switch (type)
    {
        case ALERT:
            if (!payload)
            {
                return state;
            }
            const { autoHideDuration = 6000, display = true } = payload;
            return {
                ...state,
                ...payload,
                autoHideDuration: autoHideDuration,
                display: display
            };
        default:
            return state;
    }
};
export default alertReducer;