/**
 * @file features/screens/initialState
 * @description Exports the initial state of the Screens reducer.
 */

import { TScreenState } from './types';

const screensInitialState: TScreenState = {
    loading: false,
    available: []
};
export default screensInitialState;