/**
 * @file features/global/calculateDuration.ts
 * @description Function for calculating a duration string from two dates provided as ISO Strings. If the number is negative, "ago" is appended to the end.
 */

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
dayjs.extend(duration);

const calculateDuration = (start: string, end: string, stringFormat?: string): string => {
    // Validate the strings - if they're invalid, return nothing
    // console.log('DEBUG: calculateDuration: start, end and format:', start, end, stringFormat);
    if (!dayjs(start, stringFormat).isValid() || !dayjs(end, stringFormat).isValid()) {
        console.error('Cannot calculate duration: Start and End times are not valid - start:', start, ' end:', end);
        return '';
    }

    // Otherwise, build the duration string
    const startDateTime = dayjs(start, stringFormat);
    const endDateTime = dayjs(end, stringFormat);
    const duration = dayjs.duration(endDateTime.diff(startDateTime));
    const formattedDuration = duration.format('Y [years] M [months] D [days] H [hours] m [minutes] s [seconds]');
    const strippedDuration = formattedDuration.replace(/\b-?0\s[a-z]*\s?/g, ' ').replace(/\s{1,10}/g, ' ');
    if (strippedDuration.indexOf('-') > -1) {
        return `${strippedDuration.replace(/-/g, '')} ago`.replace(/\s{1,10}/g, ' ').replace(/^\s/, '').replace(/\s$/, '');
    }
    else {
        return strippedDuration.replace(/\s{1,10}/g, ' ').replace(/^\s/, '').replace(/\s$/, '');
    }
};
export default calculateDuration;