/**
 * @file features/error/reducer.ts
 * @description The reducer for error actions.
 */

// Imports
import { SHOW_ERROR, HIDE_ERROR } from './actions';
import errorInitialState from './initialState';
import { TErrorState, TErrorAction } from './types';

/**
 * The error reducer updates Redux state based on actions/payloads.
 * @param {TErrorState} state Previous state object.
 * @param {TErrorAction} action Action to process.
 * @returns {TErrorState} Returns the new state.
 */
const errorReducer = (state = errorInitialState, action: TErrorAction | undefined): TErrorState => {
    if (!action)
    {
        return state;
    }
    const { type, payload } = action;
    switch (type)
    {
        case SHOW_ERROR:
            return {
                ...state,
                ...payload,
                display: true
            };
        case HIDE_ERROR:
            return {
                ...state,
                ...payload,
                display: false
            };
        default:
            return state;
    }
};
export default errorReducer;