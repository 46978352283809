/**
 * @file features/form/actions
 * @description Contains Redux actions for managing a Form.
 */

// Imports
import { TFormAction, TFormData, TFormValue } from './types';

/**
 * The FORMDATA_LOADING action is fired when a form is awaiting data and is locked for input. This includes situations where a form is first loading, where a form is awaiting autocomplete entries and where a form has been submitted and the system is waiting for a response.
 */
export const FORMDATA_LOADING = 'FORMDATA_LOADING';

/**
 * The formLoading function is used to dispatch the FORMDATA_LOADING action - it is a pure action function that simply dispatches the loading status.
 * @param {null} payload If Null is passed as the payload, then form values (as well as the loading status) are cleared too. Other payloads have no effect and leave additional data as is. The FORMDATA_LOADED action should be used for setting data.
 * @returns {TFormAction} Returns a Redux action to be processed by the formReducer.
 */
export const formLoading = (payload?: null): TFormAction => {
    return {
        type: FORMDATA_LOADING,
        payload: payload
    };
};

/**
 * The FORMDATA_LOADED action is fired when form data has loaded and the form is ready to accept input.
 */
export const FORMDATA_LOADED = 'FORMDATA_LOADED';

/**
 * The formLoaded function is used to dispatch the FORMDATA_LOADED action - it is a pure action function that simply dispatches the action and any data received. This action will load any data provided as originalValues. If Null is passed, originalValues are cleared too.
 * @param {TFormData|null?} data The data (if any) to dispatch.
 * @returns {TFormAction} Returns a Redux action to be processed by the formReducer.
 */
export const formLoaded = (data?: TFormData | null) => {
    return {
        type: FORMDATA_LOADED,
        payload: data
    };
};

/**
 * The FORMDATA_EDIT action is fired when an individual data key is edited.
 */
export const FORMDATA_EDIT = 'FORMDATA_EDIT';
/**
 * The formEdit function is used to dispatch the FORMDATA_EDIT action - it is a pure action function that simply dispatches the action and any data received. This action will only edit the data key provided in selectedValues - it leaves originalValues alone.
 * @param {TFormValue} data The data to dispatch.
 * @returns {TFormAction} Returns a Redux action to be processed by the formReducer.
 */
export const formEdit = (data: TFormValue) => {
    return {
        type: FORMDATA_EDIT,
        payload: data
    };
};

/**
 * The FORMDATA_RESET action is fired when a form is reset - it causes originalValues to be copied to selectedValues.
 */
export const FORMDATA_RESET = 'FORMDATA_RESET';
/**
 * The formReset function is used to dispatch the FORMDATA_RESET action - it is a pure action function that simply dispatches the action. This action will cause the contents of originalValues to be copied to selectedValues.
 * @returns {TFormAction} Returns a Redux action to be processed by the formReducer.
 */
export const formReset = () => {
    return {
        type: FORMDATA_RESET
    };
};