/**
 * @file features/form/evaluateRule.ts
 * @description This function is used to evaluate a single showRule. Whilst showComponent evaluates a whole set of them, this function evaluates just one. It uses the following logic for each rule -references to variables below are variables defined within the rule (unless they refer to the function's parameters):
 * 	a. If the rule has neither showValues nor showKey, return true if data[testKey] is truthy.
 * 	b. If showValues is supplied and is not an array:
 * 		i. If data[testKey] is also not an array, return true if showValues == data[testKey] (or a shallow object comparison using the shallowCompare function returns true).
 * 		ii. If data[testKey] is an array, return true if data[testKey] contains showValues (either as an array item or if shallowCompare returns true for one).
 * 	c. If showValues is supplied and is an array:
 * 		i. If data[testKey] is not an array, return true if showValues contains data[testKey] (either as an array item or if shallowCompare returns true for one).
 * 		ii. If data[testKey] is an array:
 * 			(1) If strictTest === true, only return true if data[testKey] has the same items in the same order as showValues. Otherwise return false.
 * 			(2) If !strictTest, return true if data[testKey] is a subset of data[showKey]. Otherwise return false.
 * 	d. Only evaluate below showKey rules if showValues is not supplied.
 * 	e. If data[showKey] is not an array:
 * 		i. If data[testKey] is also not an array return true if data[testKey] == data[showKey] (or shallowCompare returns true).
 * 		ii. If data[testKey] is an array:
 * 			(1) If strictTest === true, only return true if data[testKey] has the same number of items in the same order as data[showKey]. Otherwise return false.
 * 			(2) If !strictTest, return true if data[testKey] is a subset of data[showKey]. Otherwise return false.
 * 	f. If data[showKey] is an array
 * 		i. If data[testKey] is not an array, return true if data[showKey] contains data[testKey]. Otherwise return false.
 * 		ii. If data[testKey] is an array:
 * 			(1) If strictTest === true, only return true if data[testKey] has the same items in the same order as data[showKey]. Otherwise return false.
 * 			(2) If !strictTest, return true if data[testKey] is a subset of data[showKey].
 */

import { arrayContains, shallowCompare } from "../global";
import { TShowRule } from "../view";
import { TFormData } from "./types";

const evaluateRule = (rule: TShowRule, data: TFormData): boolean => {
    // Get the data we need to evaluate the rule
    const { testKey, showValues, showKey, strictTest } = rule;
    // console.log('evaluating rule: testKey, showValues:', testKey, showValues);

    // If data[testKey] is empty, at least make it defined
    if (data[testKey] === undefined) {
        data[testKey] = '';
    }
    // Check if showValues and showKey have been supplied - otherwise, return true if data[testKey] is truthy or a non-empty array (Rule 3a)
    if (showValues === undefined && showKey === undefined) {
        if (data[testKey] && data[testKey].constructor === Array && data[testKey].length > 0) {
            return true;
        }
        else if (data[testKey]) {
            return true;
        }
        else {
            return false;
        }
    }
    // If showValues was supplied and is not an array (Rule 3b)
    else if (showValues !== undefined && !(showValues instanceof Array)) {
        // If data[testKey] is also not an array, return true if showValues == data[testKey] or shallowCompare(data[testKey], showValues) === true. Otherwise return false. (Rule 3bi)
        if (!(data[testKey] instanceof Array)) {
            if (typeof data[testKey] === 'object' && typeof showValues === 'object') {
                return shallowCompare(data[testKey], showValues);
            }
            else if (data[testKey] == showValues)	// eslint-disable-line eqeqeq
            {
                return true;
            }
            else {
                return false;
            }
        }
        // If data[testKey] is an array, return true if data[testKey] contains showValues. (Rule 3bii)
        else {
            return arrayContains(data[testKey], showValues, strictTest);
        }
    }
    // If showValues is supplied and is an array, return true if showValues contains data[testKey] (Rule 3c) - note the arrayContains function deals with strictTest as per the logic in the description.
    else if (showValues !== undefined && showValues instanceof Array) {
        return arrayContains(showValues, data[testKey], strictTest);
    }
    // If showValues is not supplied, evaluate showKey rules
    else {
        if (!showKey) {
            // Return true so we show by default if the rule is invalidly defined
            return true;
        }
        // If data[showKey] is not an array (Rule 3e)...
        else if (!(data[showKey] instanceof Array)) {
            // If data[testKey] is not an array, return true if data[testKey] === data[showKey] or shallowCompare(data[testKey], data[showKey]) for objects (Rule 3ei)
            if (!(data[testKey] instanceof Array)) {
                if (typeof data[testKey] === 'object' && typeof data[showKey] ===
                    'object' && shallowCompare(data[testKey], data[showKey])) {
                    return true;
                }
                else if (data[testKey] == data[showKey])	// eslint-disable-line eqeqeq
                {
                    return true;
                }
                else {
                    return true;
                }
            }
            // If data[testKey] is an array (Rule 3eii), return arrayContains result for data[showKey], which deals with strictTest
            else {
                return arrayContains(data[testKey], data[showKey], strictTest);
            }
        }
        // If data[showKey] is an array (Rule 3f)...
        else {
            // Apply rule 3f using the arrayContains method
            return arrayContains(data[showKey], data[testKey], strictTest);
        }
    }
};
export default evaluateRule;