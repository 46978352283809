/**
 * @file features/auth/authProvider
 * @description Contains Microsoft Authentication Library configurations and exports the authentication provider.
 */

import { Configuration } from "msal";
import { MsalAuthProvider, LoginType } from "react-aad-msal";

// Authentication AAD App and token cache configuration
type storageLocation = 'localStorage' | 'sessionStorage' | undefined;
const authConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID as string,
        authority: process.env.REACT_APP_AAD_AUTHORITY as string,
        validateAuthority: true,
        redirectUri: window.location.origin,
        navigateToLoginRequestUrl: true,
        postLogoutRedirectUri: window.location.origin,
        
    },
    cache: {
        cacheLocation: 'sessionStorage' as storageLocation,
        storeAuthStateInCookie: true
    },
    system: {
        loadFrameTimeout: 20000,
    }
};

// Authentication parameters and scopes
const authParameters = {
    scopes: [
        'openid',
        'User.Read',
        'email',
        'profile',
        'Group.Read.All'
    ]
};

// Authentication options
const authOptions = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: `${window.location.origin}/auth.html`
};

// Export the provider
const authProvider = new MsalAuthProvider(authConfig, authParameters, authOptions);
export default authProvider;