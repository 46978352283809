/**
 * @file features/view/initialState
 * @description Contains the initial state for the View reducer.
 */

// Imports
import { TViewState } from './types';

const viewInitialState: TViewState = {
    loading: false,
    ready: false,
    readyTime: null,
    currentView: null,
};
export default viewInitialState;