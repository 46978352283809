/**
 * @component ErrorComponent.tsx
 * @description Component for displaying error messages on the screen.
 */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Link } from '@material-ui/core';
import { TGlobalState } from '../features/global';
import { hideError } from '../features/error';
import { TAlertState, alertAction } from '../features/alert';
import RenderIcon from './RenderIcon';
import { formLoaded } from '../features/form';

export default function ErrorComponent(): JSX.Element {
    const { error, formReady } = useSelector((state: TGlobalState) => {
        return {
            error: state.error,
            formReady: state.form.ready
        };
    });
    const dispatch = useDispatch();
    const { status, display, message, correlationId, showResetOptions = false } = error;

    const handleBack = (event: React.MouseEvent) => {
        window.history.back();
    };

    const handleRetry = (event: React.MouseEvent) => {
        const retryAlert: TAlertState = {
            severity: 'info',
            display: true,
            message: 'Restoring pre-submission values...',
            autoHideDuration: 3000
        };
        dispatch(formLoaded());
        dispatch(alertAction(retryAlert));
        dispatch(hideError());
    };

    const handleRefresh = (event: React.MouseEvent) => {
        const refreshAlert: TAlertState = {
            severity: 'info',
            display: true,
            message: 'Reloading...',
            autoHideDuration: 3000
        };
        dispatch(alertAction(refreshAlert));
        window.location.reload();
    };

    return (display ?
        <Grid item xs={12} container direction="column" justify="center">
            <Typography variant="h2" color="error" style={{ textDecoration: 'underline' }}>{status}</Typography>
            <br />
            <Typography variant="body1" style={{ textAlign: 'center' }}>An error was encountered. Please correct any invalid data and try again. If the problem persists, please <Link href="mailto:deployit.support@au.fujitsu.com" color="secondary">contact support</Link> and provide them with the following details:</Typography>
            <br />
            <Grid item container direction="row" justify="space-evenly">
                <Grid item xs={5}>
                    <Typography variant="h4">Status:&nbsp;</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="body1">{status}</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="h4">Message:&nbsp;</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="body1">{/[.|!|?]$/.test(message) ? message : `${message}.`}</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="h4">Correlation ID:&nbsp;</Typography>
                </Grid>
                <Grid item xs={5}>
                    <Typography variant="body1">{correlationId}</Typography>
                </Grid>
            </Grid>
            {showResetOptions || formReady ? <Grid item container justify="center">
                <Typography variant="body1">"Try Again" will restore any values entered before this error occurred.</Typography></Grid>
                : null}
            <Grid item xs={12} container direction="row" justify="space-evenly">
                <Button onClick={handleBack} variant="contained" color="secondary"><RenderIcon id="back-icon" staticValue="BackIcon" style={{ color: "white" }} />&nbsp; Go Back</Button>
                {showResetOptions || formReady ?
                    <Button onClick={handleRetry} variant="contained" color="primary"><RenderIcon id="retry-icon" staticValue="RestoreIcon" style={{ color: "white" }} />&nbsp; Try Again</Button>
                    : null}
                <Button onClick={handleRefresh} variant="contained" color="secondary"><RenderIcon id="refresh-icon" staticValue="RefreshIcon" style={{ color: "white" }} />&nbsp; Reload View</Button>
            </Grid>
        </Grid> : <></>
    );
}