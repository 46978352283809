/**
 * @file features/global/shallowCompare.ts
 * @description Does a shallow comparison of two objects. First checks that Object A and Object B have the same keys. Then checks that the values match (or assumes a match if the values are objects - this is a shallow compare so we don't check sub-keys)
 */

/**
 * @function shallowCompare Take two objects (objA and objB) and performs a shallow comparison - in other words, it only checks that both objects have the same keys and that for keys that contain primitive values, the values match. Does not check complex data types
 * @param {Object} objA First object to compare.
 * @param {Object} objB Second object to compare.
 * @returns {Boolean} Returns true if the comparison passes or false if it fails. 
 * @throws {TypeError} If non-objects are supplied as parameters.
 */
export default function shallowCompare(objA: any, objB: any): boolean {
    // If we don't have two objects, throw an error
    if (typeof objA !== 'object' || typeof objB !== 'object') {
        throw new TypeError(`shallowCompare can only be called on two objects - received a ${typeof objA} and a ${typeof objB}`);
    }
    // Check that keys match
    let keysA = Object.keys(objA);
    keysA.sort();
    let keysB = Object.keys(objB);
    keysB.sort();
    // Different number of keys: false
    if (keysA.length !== keysB.length) {
        return false;
    }
    // Iterate - check the keys and values all match
    for (let k = 0; k < keysA.length; k++) {
        // Key mismatch: false
        if (keysA[k] !== keysB[k]) {
            return false;
        }
        // Value mismatch: false (test loose equality, unless both subkeys are objects, in which case the test passes regardless of whether they match, as we're only doing a shallow comparison so only the first level of keys needs to match)
        if (typeof objA[keysA[k]] === 'object' && typeof objB[keysB[k]] === 'object') {
            continue;
        }
        else if (objA[keysA[k]] != objB[keysB[k]]) // eslint-disable-line eqeqeq
        {
            return false;
        }
    }
    // If we got through the loop without a fail, we have a shallow, loose match
    return true;
}