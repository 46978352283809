/**
 * @file features/error/initialState
 * @description Exports the initial state of the Error Reducer.
 */
import { TErrorState } from './types';

const errorInitialState: TErrorState = {
    status: 'OK',
    message: 'No error',
    display: false,
};
export default errorInitialState;