/**
 * @component Button.tsx
 * @description Renders a button with actions, etc. attached. These can be included anywhere in forms, but are usually all on the left-hand side, except for the next/previous wizard buttons.
 */

// Imports
import React from 'react';
import { TButton, TQuery } from '../features/view';
import { formLoading, TFormData, TFormValue } from '../features/form';
import MuiButton from '@material-ui/core/Button';
import { alertAction } from '../features/alert';
import { useDispatch, useSelector } from 'react-redux';
import { TGlobalState, TMode } from '../features/global';
import { formReset } from '../features/form';
import { showError, TErrorState, validateTErrorState, parseError } from '../features/error';
import { runQuery } from '../features/form';
import RenderIcon from './RenderIcon';
import ModeButtons from './ModeButtons';
import { TGridState } from '../features/grids';

export default function Button(props: TButton): JSX.Element {
    // Initialise variables
    const { id, variant, button_purpose = 'navigation', clickQueries = [], color, text, mode = 'view', baseHref, bodyParams, fullWidth, iconName, iconColor = 'secondary', disabled = false, customAction, customActionParams, errorIfNone } = props;

    // Configure dispatch function
    const dispatch = useDispatch();

    // Hook into state to get form and view data
    const { selectedValues, runQueries, grids, isDisabled, formCurrentlyLoading } = useSelector((state: TGlobalState) => {
        const viewQueries = state.view?.currentView?.query || [];
        let runQueries: TQuery[] = [];
        for (let q of clickQueries) {
            if (viewQueries[q]) {
                runQueries.push(viewQueries[q]);
            }
        }
        return {
            selectedValues: state.form.selectedValues,
            runQueries: runQueries,
            grids: state.grids,
            isDisabled: disabled || state.form.loading,
            formCurrentlyLoading: state.form.loading
        };
    });
    const gridArray: TGridState[] = [];
    for (let key in grids) {
        gridArray.push(grids[key]);
    }

    // Function for resetting form values
    const handleReset = (event: React.MouseEvent<HTMLButtonElement>) => {
        dispatch(alertAction({ severity: 'info', message: 'Form values reset', autoHideDuration: 6000 }));
        dispatch(formReset());
    };

    // Function for running a query when a button is pressed & navigating (if applicable) - uses the runQuery utility function, which submits necessary actions etc. Also handles customActions and customActionParams
    const handleQuery = async (event: React.MouseEvent<HTMLButtonElement>, queries: TQuery[], data: TFormData, customFunction?: Function, customFunctionParams?: any[]) => {
        try {

            // Run the query or queries attached to the button
            let queryResult: TFormData | TErrorState = data;
            if (queries.length > 0) {
                dispatch(alertAction({ severity: 'info', message: 'Processing...', autoHideDuration: 6000 }));
                for (let query of queries) {
                    if (!formCurrentlyLoading) {
                        dispatch(formLoading());
                    }
                    queryResult = await runQuery(query, queryResult, dispatch, false, gridArray, errorIfNone);
                    // Don't proceed further if there was an error
                    if (validateTErrorState(queryResult)) {
                        dispatch(alertAction({ severity: 'error', message: 'Error encountered!', autoHideDuration: 6000 }));
                        return;
                    }
                }
            }

            // Execute any customFunction
            if (customFunction && customFunctionParams) {
                await customFunction(...customFunctionParams);
            }
            else if (customFunction) {
                await customFunction();
            }

            // If we have a baseHref, navigate
            if (baseHref) {
                // Navigate to the next view in the default flow (wizards and dashboards have their own flow and don't use the baseHref but if for some reason we are in a wizard and end up in this function, it will go back to the default grid view)
                const nextModeMap = (mode: TMode) => {
                    switch (mode) {
                        case 'new':
                        case 'edit':
                        case 'newwizard':
                        case 'editwizard':
                            return 'view';
                        default:
                            return 'gridview';
                    }
                };
                // Preserve URL parameters but if the query props specify bodyParams, replace any bodyParam values with values from the query response
                let urlParams: TFormValue[] = [];
                //debugger;
                const urlQuery = new URLSearchParams(window.location.search);
                urlQuery.forEach((value, key) => urlParams.push({ key: key, value: value }));
                urlParams = urlParams.filter(item => item.key !== 'mode');
                let baseHrefToUse = baseHref;
                for (let param of urlParams) {
                    urlQuery.set(param.key, param.value);
                }
                if (bodyParams && bodyParams.length > 0) {
                    for (let param of bodyParams) {
                        // We set the urlKey that matches the name to the relevant value
                        // Query result data takes precedence over input data, but input data is used as a fallback, followed by ''.
                        const paramValue = (queryResult as TFormData)[param.name] || data[param.name] || '';
                        urlQuery.set(param.urlKey, paramValue);
                    }
                }
                urlQuery.set('mode', nextModeMap(mode));
                const queryParams = urlQuery.toString();
                const destinationUrl = `${window.location.protocol}//${window.location.host}/${baseHrefToUse}?${queryParams}`;
                console.log('Navigating to ', destinationUrl);
                dispatch(alertAction({ severity: 'success', message: `Success! Navigating in 3 seconds...`, autoHideDuration: 3000 }));
                setTimeout(() => {
                    window.location.href = destinationUrl;
                }, 3000);
            }
            else if (queries.length > 0) {
                dispatch(alertAction({ severity: 'success', message: 'Operation Successful!', autoHideDuration: 3000 }));
            }
        } catch (error) {
            if (validateTErrorState(error)) {
                dispatch(showError(parseError(error)));
                return parseError(error);
            }
            else {
                const errorObj: TErrorState = {
                    status: 'Service Error',
                    message: error.message,
                    stack: error.stack,
                    correlationId: 'FEATURES/FORM_BUTTON/RUN_QUERY/SYSTEM_ERROR',
                    display: true
                };
                dispatch(showError(errorObj));
                return errorObj;
            }
        }
    };

    // If the button's ID is 'switch-mode', render the ModeButtons
    if (id === 'switch-mode') {
        return <ModeButtons id={id} mode={mode} variant={variant || 'contained'} baseHref={baseHref || ''} availableModes={props.availableModes} fullWidth={fullWidth} disabled={disabled}/>;
    }

    // Otherwise, render another button depending on the purpose
    else {
        switch (button_purpose) {
            // Submit button - usually runs a query and may navigate if a baseHref is passed
            // Is always red and uses the Save icon
            case 'submit': {
                return (
                    <MuiButton id={id} variant='contained' onClick={async (event: React.MouseEvent<HTMLButtonElement>) => handleQuery(event, runQueries, selectedValues || {}, customAction, customActionParams)} color="secondary" startIcon={<RenderIcon id={`${id}-icon`} staticValue="SaveIcon" style={{ color: 'white' }} />} fullWidth={fullWidth} disabled={isDisabled}>Submit</MuiButton>
                );
            }
            // Delete button - usually runs a query and may navigate if a baseHref is passed. 
            // Behaves like the Submit button, but uses the delete icon
            case 'delete': {
                return (
                    <MuiButton id={id} variant='contained' onClick={async (event: React.MouseEvent<HTMLButtonElement>) => handleQuery(event, runQueries, selectedValues || {}, customAction, customActionParams)} color="primary" startIcon={<RenderIcon id={`${id}-icon`} staticValue="DeleteIcon" style={{ color: 'red' }} />} fullWidth={fullWidth} disabled={isDisabled}>Submit</MuiButton>
                );
            }
            // Reset button - we pass the handleReset onClick handler which just resets form values
            // Is always grey and uses the Delete icon
            case 'reset': {
                return (
                    <MuiButton id={id} variant='contained' onClick={handleReset} color="primary" startIcon={<RenderIcon id={`${id}-icon`} staticValue="DeleteIcon" style={{ color: 'white' }} />} fullWidth={fullWidth} disabled={isDisabled}>Reset</MuiButton>
                );
            }
            // Navigation button - we just pass the query (if one is associated) and the baseHref - these buttons are used for navigating and can be used for saving data, but are more flexible than the standard "submit" button
            case 'navigation': {
                return (
                    <MuiButton id={id} variant={variant || 'contained'} onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleQuery(event, runQueries, selectedValues || {}, customAction, customActionParams)} color={color || 'secondary'} startIcon={<RenderIcon id={`${id}-icon`} staticValue={iconName || 'NavIcon'} style={{ color: iconColor || 'white' }} />} fullWidth={fullWidth} disabled={isDisabled}>{text}</MuiButton>
                );
            }
            // Wizard Start button - moves to the first wizard step
            case 'wizardStart': {
                return (
                    <MuiButton id={id} variant={variant || 'contained'} onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleQuery(event, runQueries, selectedValues || {}, customAction, customActionParams)} color={color || 'primary'} startIcon={<RenderIcon id={`${id}-icon`} staticValue='FastRewindIcon' style={{ color: 'white' }} />} fullWidth={fullWidth} disabled={isDisabled}>{text}</MuiButton>
                );
            }
            // Step back button - moves to the previous wizard step
            case 'wizardBack': {
                return (
                    <MuiButton id={id} variant={variant || 'contained'} onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleQuery(event, runQueries, selectedValues || {}, customAction, customActionParams)} color={color || 'primary'} startIcon={<RenderIcon id={`${id}-icon`} staticValue='BackIcon' style={{ color: 'white' }} />} fullWidth={fullWidth} disabled={isDisabled}>{text}</MuiButton>
                );
            }
            // Step forward button - submits data and/or runs queries before moving to the next step. It is used by the Stepper component in multi-stage forms.
            case 'wizardNext': {
                return (
                    <MuiButton id={id} variant={variant || 'contained'} onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleQuery(event, runQueries, selectedValues || {}, customAction, customActionParams)} color={'secondary'} startIcon={runQueries.length > 0 ? <><RenderIcon id={`${id}-icon`} staticValue='SaveIcon' style={{ color: 'white' }} /><RenderIcon id={`${id}-icon`} staticValue='ForwardIcon' style={{ color: 'white' }} /></> : <RenderIcon id={`${id}-icon`} staticValue='ForwardIcon' style={{ color: 'white' }} />} fullWidth={fullWidth} disabled={isDisabled}>{text}</MuiButton>
                );
            }
            // Last Step button - moves to the last step in a wizard
            case 'wizardLast': {
                return (
                    <MuiButton id={id} variant={variant || 'contained'} onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleQuery(event, runQueries, selectedValues || {}, customAction, customActionParams)} color={'secondary'} startIcon={runQueries.length > 0 ? <><RenderIcon id={`${id}-icon`} staticValue='SaveIcon' style={{ color: 'white' }} /><RenderIcon id={`${id}-icon`} staticValue='FastForwardIcon' style={{ color: 'white' }} /></> : <RenderIcon id={`${id}-icon`} staticValue='FastForwardIcon' style={{ color: 'white' }} />} fullWidth={fullWidth} disabled={isDisabled}>{text}</MuiButton>
                );
            }
            // Form Dialog Popup button - opens a form popup dialog
            case 'formDialogPopup': {
                return (
                    <MuiButton id={id} variant={variant || 'contained'} onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleQuery(event, runQueries, selectedValues || {}, customAction, customActionParams)} color={'secondary'} startIcon={<RenderIcon id={`${id}-icon`} staticValue={iconName} style={{ color: 'white' }} />} fullWidth={fullWidth} disabled={isDisabled} >{text}</MuiButton>
                );
            }
            // For selecting a file - wraps a file input. On change, runs the customAction (doesn't run a query)
            case 'fileSelect': {
                return (
                        <MuiButton variant={variant || 'contained'} color={'primary'} startIcon={<RenderIcon id={`${id}-icon`} staticValue={iconName || 'FileIcon'} style={{ color: 'white' }} />} fullWidth={fullWidth} disabled={isDisabled}>
                        <input type="file" id={`${id}-file-input`} name={`${id}-file-input`} style={{color: 'white', fontFamily: 'Fujitsu-Sans-Regular'}} onChange={customAction as (event: React.ChangeEvent<HTMLInputElement>) => void} accept={customActionParams?.join() || ''} /></MuiButton>
                )
            }
            // Misc - can be used for multiple purposes, e.g. used by the file attachment components for most buttons except the actual select button
            case 'misc': {
                return (
                    <MuiButton id={id} variant={variant || 'contained'} onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleQuery(event, runQueries, selectedValues || {}, customAction, customActionParams)} color={color || 'secondary'} startIcon={<RenderIcon id={`${id}-icon`} staticValue={iconName} style={{ color: 'white' }} />} fullWidth={fullWidth} disabled={isDisabled}>{text}</MuiButton>
                )
            }
            // Log an error and render an empty fragment if the button purpose isn't supported
            default: {
                const error: TErrorState = {
                    status: 'Service Error',
                    message: `Unsupported button purpose, "${button_purpose}", specified`,
                    correlationId: 'COMPONENTS/BUTTON/INVALID_PURPOSE',
                    display: true
                };
                dispatch(showError(error));
                console.error(`Unsupported button purpose, ${button_purpose}, specified.`);
                return (<></>);
            }
        }
    }
}