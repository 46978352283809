/**
 * @component SwitchInput.tsx
 * @description An On/Off switch component (like a checkbox but prettier).
 */

// Imports
import React, { MutableRefObject, useState } from 'react';
import { useSelector } from 'react-redux';
import Switch from '@material-ui/core/Switch';
import { useValidation } from '../features/form';
import { TInputComponent } from '../features/view';
import { TGlobalState } from '../features/global';

// Component with forwardRef
export default function SwitchInput(props: TInputComponent): JSX.Element {
    // Destructure props and hook Redux state
    const { id, dataKey, updateKeys, defaultValue, defaultValueKeys, disabled, style, className, staticValue, label, alreadyMounted } = props;
    const { selectedValue, disableInput } = useSelector((state: TGlobalState) => {
        let selected: boolean | string = false;

        if (staticValue !== undefined && staticValue !== null) {
            selected = staticValue;
        }
        if (defaultValue) {
            selected = defaultValue;
        }
        if (defaultValueKeys?.[0]) {
            selected = defaultValueKeys[0];
        }
        if (state.form.selectedValues?.[dataKey] !== undefined && state.form.selectedValues?.[dataKey] !== null && state.form.selectedValues?.[dataKey] !== '') {
            // console.log('DEBUG: SwitchInput - selectedValues being assigned:', dataKey, state.form.selectedValues[dataKey]);
            selected = state.form.selectedValues[dataKey];
        }
        if (typeof selected !== 'boolean') {
            switch (selected) {
                case 'checked':
                case 'true':
                    selected = true;
                    break;
                case 'unchecked':
                case 'false':
                default:
                    selected = false;
                    break;
            }
        }
        // console.log('DEBUG: useSelector SwitchInput selected:', dataKey, selected);
        return {
            selectedValue: selected,
            disableInput: disabled ? true : state.form.loading ? true : typeof staticValue === 'boolean' || staticValue === 'true' || staticValue === 'false' ? true : false
        };
    });

    // Set internal state variables and the state setter
    const [value, setValue] = useState(selectedValue);
    const setBooleanValue = (val?: string | boolean | null) => {
        if (typeof val === 'boolean') {
            setValue(val);
        }
        else {
            switch (val) {
                case 'checked':
                case 'true':
                    setValue(true);
                    break;
                case 'unchecked':
                case 'false':
                default:
                    setValue(false);
                    break;
            }
        }
    };

    // Use hook to get event handlers for dispatching changes
    const { handleChange, handleBlur } = useValidation(dataKey, false, alreadyMounted as MutableRefObject<boolean>, setBooleanValue, null, null, 'boolean', updateKeys, defaultValue, defaultValueKeys, '', false, staticValue, label);

    // Return the switch
    return (
        <Switch
            id={id}
            checked={value}
            color={value === true ? "secondary" : "primary"}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disableInput}
            style={style}
            className={className}
        />
    );
}