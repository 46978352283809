/**
 * @component Section.tsx
 * @description Renders an array of SingleComponents sharing a componentsPerRow and heading value (i.e. renders a section).
 */

import React, { useMemo } from 'react';
import SingleComponent from './SingleComponent';
import { TSection, TComponentPropType, TWidthValues } from '../features/view';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ErrorBoundary from './ErrorBoundary';

const ComponentList = (props: {components: TComponentPropType[], widths: TWidthValues, thisTabOrStep?: number, componentsPerRow: number}) => {
    // console.log('Rendering components:', props.components);
    return (<>
        {props.components.map((componentProps: TComponentPropType, index: number) => {
            const { componentType = 'ViewText', id = 'no_page_id' } = componentProps;
            const correlationIdText = `COMPONENTS/${(componentType || 'UNKNOWN_COMPONENT').toUpperCase()}/${(id || 'NO_PAGE_ID').toUpperCase().replace(/[^A-Z0-9_]/g, '_')}/${index}`;
            return (
                <ErrorBoundary key={index} correlationIdText={correlationIdText}>
                    <SingleComponent widths={props.widths} componentsPerRow={props.componentsPerRow} thisTabOrStep={props.thisTabOrStep} {...componentProps} />
                </ErrorBoundary>
            );
        })}
    </>);
}

export default function Section(props: TSection): JSX.Element {
    const { heading, componentsPerRow, components, thisTabOrStep } = props;
    let widths: TWidthValues = {
        labelWidth: 3,              // Default - one component per row
        valueWidth: 9
    };
    switch(componentsPerRow) {
        case 0:
            widths.labelWidth = 0;
            widths.valueWidth = 12;
            break;
        case 2:
            widths.labelWidth = 2;
            widths.valueWidth = 4;
            break;
        case 3:
            widths.labelWidth = 2;
            widths.valueWidth = 2;
            break;
        case 1:
        default:
            widths.labelWidth = 3;
            widths.valueWidth = 9;
            break;
    }
    // console.log('DEBUG: props:', props);
    // console.log('DEBUG: components:', components);
    // const formData = useSelector((state: TGlobalState) => state.form.selectedValues);
    const memoisedComponents = useMemo(() => components, [components]);

    return (<Grid style={{ paddingTop: '2vh', paddingBottom: '1vh' }} item spacing={3} container direction="column">
        <Grid item xs={12} style={{ borderBottom: '1px solid black' }}>
            <Typography variant="h4">{heading}</Typography>
        </Grid>
        <Grid item xs={12} container direction="row" justify="flex-start" spacing={2}>
            <ErrorBoundary correlationIdText="COMPONENTS/SECTION/SECTION_ERROR">
                <ComponentList components={memoisedComponents} widths={widths} thisTabOrStep={thisTabOrStep} componentsPerRow={componentsPerRow} />
            </ErrorBoundary>
        </Grid>
    </Grid>);
}