/**
 * @file features/form/reducer
 * @description Redux reducer for currently-loaded Form data.
 */

// Imports
import { FORMDATA_LOADING, FORMDATA_LOADED, FORMDATA_EDIT, FORMDATA_RESET } from './actions';
import formInitialState from './initialState';
import { TFormState, TFormAction, TFormData } from './types';

/**
 * The form reducer updates Redux state base on actions/payloads.
 * @param {TFormState} state Previous state object.
 * @param {TFormAction?} action Action to process.
 * @returns {TFormState} Returns the new state.
 */
const formReducer = (state = formInitialState, action: TFormAction | undefined): TFormState => {
    if (!action) {
        return state;
    }
    const { type, payload } = action;
    switch (type) {
        case FORMDATA_LOADING:
            if (payload === null) {
                return {
                    ...state,
                    loading: true,
                    ready: false,
                    originalValues: null,
                    selectedValues: null
                };
            }
            else {
                return {
                    ...state,
                    ready: false,
                    loading: true
                };
            }
        case FORMDATA_LOADED:
            if (payload === null) {
                return {
                    ...state,
                    loading: false,
                    ready: true,
                    originalValues: null,
                    selectedValues: null
                };
            }
            else if (!payload) {
                return {
                    ...state,
                    loading: false,
                    ready: true
                };
            }
            else {
                const originalValuesCopy = JSON.parse(JSON.stringify(state.originalValues || {}));
                const selectedValuesCopy = JSON.parse(JSON.stringify(state.selectedValues || {}));
                return {
                    ...state,
                    loading: false,
                    ready:true,
                    originalValues: Object.assign({}, originalValuesCopy, payload),
                    selectedValues: Object.assign({}, selectedValuesCopy, payload)
                };
            }
        case FORMDATA_EDIT:
            if (!payload) {
                return state;
            }
            else {
                const selectedValuesCopy = JSON.parse(JSON.stringify(state.selectedValues || {}));
                const updatedValue: TFormData = {
                    [payload.key]: payload.value
                };
                const modifiedValues = Object.assign({}, selectedValuesCopy, updatedValue);
                return {
                    ...state,
                    selectedValues: modifiedValues
                };
            }
        case FORMDATA_RESET:
            const originalValuesCopy = JSON.parse(JSON.stringify(state.originalValues || {}));
            return {
                ...state,
                loading: false,
                ready: true,
                selectedValues: originalValuesCopy
            };
        default:
            return state;
    }
};
export default formReducer;