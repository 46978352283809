/**
 * @component ExpandingMenu.tsx
 * @description This is the left-hand main menu that dynamically loads and shows available screens and screen order.
 */

// Imports
import React from 'react';
import { useSelector } from 'react-redux';
import { useScreens, TScreen } from '../features/screens';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import RenderIcon from './RenderIcon';
import { TGlobalState } from '../features/global';

/**
 * CreateMenuItems creates the individual menu items based on screens fed into it.
 */
const CreateMenuItems: React.FC<{ available: TScreen[], accordionClass: string }> = ({ available, accordionClass }) => {

    // Add submenu headings to an object - keys are the headings and values are arrays of the individual menu items that fall under them
    let subMenuItems: { [key: string]: JSX.Element[] } = {};
    for (let screen of available) {
        const { category_name, screen_name, base_href } = screen;
        if (Object.prototype.hasOwnProperty.call(subMenuItems, category_name)) {
            subMenuItems[category_name].push(<Link key={screen_name} href={base_href} color="secondary"><MenuItem key={screen_name}>{screen_name}</MenuItem></Link>)
        }
        else {
            subMenuItems[category_name] = [<Link key={screen_name} href={base_href} color="secondary"><MenuItem key={screen_name}>{screen_name}</MenuItem></Link>];
        }
    }

    // Create the main menu items and push to an array
    let menuItems = [];
    let previousCategoryName = '';
    for (let screen of available) {
        const { category_name, icon_name, base_href } = screen;
        if (category_name !== previousCategoryName) {
            menuItems.push(
                <ListItem button key={base_href.replace(/^\//, '')}>
                    <Accordion className={accordionClass}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel-${base_href.replace(/^\//, '')}`}
                            id={`panel-${base_href.replace(/^\//, '')}-header`}
                        >
                            <ListItemIcon>
                                <RenderIcon id={`icon-${category_name}`} staticValue={icon_name} />
                            </ListItemIcon>
                            <ListItemText primary={category_name} />
                        </AccordionSummary>
                        <AccordionDetails>
                            <MenuList>
                                {subMenuItems[category_name]}
                            </MenuList>
                        </AccordionDetails>
                    </Accordion>
                </ListItem>
            );
            previousCategoryName = category_name;
        }
    }

    // When done iterating, return the menu as a MUI List
    return (
        <List component="nav" aria-label="Menu List">
            {menuItems}
        </List>
    );
};

/**
 * The ExpandingMenu component contains the actual menu with hooks, etc. applied
 */
export default function ExpandingMenu({ accordionClass = 'expanding-menu-accordion' }): JSX.Element {
    // Hook state and run useScreens hook
    const { screensLoading, available, profile } = useSelector((state: TGlobalState) => {
        return {
            screensLoading: state.screens.loading,
            available: state.screens.available,
            profile: state.user.profile
        };
    });
    useScreens();

    // No screens or still waiting for profile? Show loading message
    if (screensLoading || !profile) {
        return (
            <Grid item>
                <Typography variant="h3" color="error"><CircularProgress color="secondary" />Loading menu...</Typography>
            </Grid>
        );
    }
    // If we have screens loaded, return the menu
    else if (available && available.length > 0) {
        return (
            <CreateMenuItems available={available} accordionClass={accordionClass} />
        );
    }
    // If we have no screens and they aren't loading, show a message saying there are no options to show. If an error was dispatched, it will show on the main screen.
    else {
        return (
            <Grid item>
                <Typography variant="h3" color="error">No options to show</Typography>
            </Grid>
        );
    }
}