/**
 * @file features/form/runQuery
 * @description Contains the function used for running a query. This function just runs the operation - it should be wrapped in whatever hook, event handler, etc. is needed for the specific use case. When executed, it locks the form (dispatches the formLoading action), runs the query and then dispatches the result using the formLoaded, autocompletesLoaded or showError action.
 */

import { sagaUrl } from '../global';
import { authProvider } from '../auth';
import { codeToStatusText, showError, TErrorState, validateTErrorState, parseError } from '../error';
import { formLoaded } from './actions';
import { TQuery } from '../view';
import { TFormData } from './types';
import paramValidateAndConvert from './paramValidateAndConvert';
import { configureGrid, gridLoaded, gridLoading, TGridState } from '../grids';
import { alertAction } from '../alert';

export default async function runQuery(query: TQuery, data: TFormData, dispatch: Function): Promise<TErrorState|TFormData>;
export default async function runQuery(query: TQuery, data: TFormData, dispatch: Function, returnOnly?: boolean, grids?: TGridState[], errorIfNone?: boolean, dispatchErrors?: boolean): Promise<TErrorState|TFormData[]>;
export default async function runQuery(query: TQuery, data: TFormData, dispatch: Function, returnOnly: boolean = false, grids?: TGridState[], errorIfNone = true, dispatchErrors = true): Promise<TFormData|TFormData[]|TErrorState> {
    // Destructure the query provided
    const { purpose, query_type, query_name, parameters, dataKey, updateKeys = [] } = query;

    // If we have grids, dispatch loading state
    if (grids)
	{
		for (let grid of grids)
		{
			if (grid.dataKey === dataKey)
			{
				dispatch(gridLoading(grid));
			}
		}
	}

    // Validity-test any data provided and build the body
    let queryData: TFormData = {
        query_name: query_name,
        query_type: query_type,
        parameters: []
    };
    // Validate parameters
    const currentUrlParams = new URLSearchParams(window.location.search);
    let newUrlParams = new URLSearchParams();
    for (const param of parameters) {
        try {
            // Destructure
            const { key, urlParam, bodyData } = param;

            // Validate the parameter
            // console.log('DEBUG: param, data and currentUrlParams', param, data, currentUrlParams);
            const processedParam = paramValidateAndConvert(param, data, currentUrlParams, undefined);
            // console.log('DEBUG: processedParam:', processedParam);

            // Is this a URL parameter? If so, put the value in newUrlParams
            if (urlParam && !bodyData) {
                newUrlParams.set(key, encodeURIComponent(processedParam));
                queryData[key] = processedParam;
                queryData.parameters.push(processedParam);
            }
            else if (urlParam) {
                newUrlParams.set(key, encodeURIComponent(processedParam));
            }

            // Is this bodyData? If so, put the value in queryData
            if (bodyData) {
                queryData[key] = processedParam;
                queryData.parameters.push(processedParam);
            }
        } catch (error) {
            const errorObj: TErrorState = {
                status: 'Invalid Input',
                message: error.message,
                stack: error.stack,
                correlationId: 'FEATURES/FORM/RUN_QUERY/VALIDATION_ERROR',
                display: true,
                showResetOptions: query_type === 'insert' || query_type === 'update' || query_type === 'transaction' ? true : false
            };
            dispatch(formLoaded());
            if (dispatchErrors) {
                dispatch(showError(errorObj));
            }
            return errorObj;
        }
    }
    // console.log('DEBUG: queryData.parameters:', queryData.parameters);

    // Construct the query URL based on purpose
    let queryUrl = '';
    switch (purpose) {
        case 'Meta':
        case 'EditMeta': {
            queryUrl = `${sagaUrl}metadata/${query_name}?requestingapp=${process.env.REACT_APP_AUTH_APP_NAME}&${newUrlParams.toString()}`;
            break;
        }
        case 'DataLoad':
        case 'AutocompleteOptions':
        case 'EditRecord': {
            queryUrl = `${sagaUrl}db/runquery?requestingapp=${process.env.REACT_APP_AUTH_APP_NAME}&querytype=${query_type}&queryname=${query_name}&${newUrlParams.toString()}`;
            break;
        }
        case 'OnLoadSaga':
        case 'TriggeredSaga': {
            queryUrl = `${sagaUrl}${query_type}/${query_name}?requestingapp=StagingManagement&${newUrlParams.toString()}`;
            break;
        }
        default:
            const errorObj: TErrorState = {
                status: 'Invalid Input',
                message: `"${purpose}" is not a supported Query Purpose.`,
                correlationId: 'FEATURES/FORM/RUN_QUERY/URL_BUILD_ERROR',
                display: true,
                showResetOptions: query_type === 'insert' || query_type === 'update' || query_type === 'transaction' ? true : false
            };
            dispatch(formLoaded());
            if (dispatchErrors) {
                dispatch(showError(errorObj));
            }
            return errorObj;
    }

    // Send the query
    try {
        const token = await authProvider.getAccessToken();
        const init = {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token.accessToken}`
            },
            body: JSON.stringify(queryData)
        };
        const result = await fetch(queryUrl, init);
        if (result.ok) {
            const formData: TFormData | TFormData[] = await result.json().then(body => body.data);
            if (formData instanceof Array && dataKey && !returnOnly) {
                if (grids) {
                    const thisGrid = grids.find(grid => grid.dataKey === dataKey);
                    if (thisGrid) {
                        const updatedGrid = configureGrid(formData, dataKey, thisGrid.grid);
                        // console.log('DEBUG: updatedGrid and data:', updatedGrid, formData);
                        dispatch(gridLoaded(updatedGrid));
                    }
                }
                dispatch(formLoaded({[dataKey]: formData}));
                return Object.assign({}, data, {[dataKey]: formData});
            }
            else if (formData instanceof Array && !returnOnly) {
                if (grids) {
                    const thisGrid = grids.find(grid => grid.dataKey === dataKey);
                    if (thisGrid) {
                        const updatedGrid = configureGrid(formData, dataKey, thisGrid.grid);
                        dispatch(gridLoaded(updatedGrid));
                    }
                }
                if (formData.length === 0 && errorIfNone) {
                    const err: TErrorState = {
                        status: 'Not Found',
                        message: 'The query returned no results. The data you are trying to access might not exist, or it might not be accessible at this URL.',
                        correlationId: 'FEATURES/FORM/RUN_QUERY/NO_RESULTS',
                        display: true,
                        showResetOptions: query_type === 'insert' || query_type === 'update' || query_type === 'transaction' ? true: false
                    };
                    if (dispatchErrors) {
                        dispatch(showError(err));
                    }
                    return err;
                }
                else if (formData.length === 0 && !errorIfNone) {
                    dispatch(alertAction({
                        severity: 'info',
                        message: 'No results were returned'
                    }));
                    if (updateKeys.length > 0) {
                        let obj: TFormData = {};
                        for (let key of updateKeys) {
                            obj[key] = '';
                        }
                        dispatch(formLoaded(obj));
                        return Object.assign({}, data, obj);
                    }
                    else {
                        dispatch(formLoaded());
                        return data;
                    }
                }
                else if (formData.length === 1) {
                    dispatch(formLoaded(formData[0]));
                    return Object.assign({}, data, formData[0]);
                }
                else {
                    dispatch(formLoaded({queryResults: formData}));
                    return Object.assign({}, data, {queryResults: formData});
                }
            }
            else if (!returnOnly) {
                dispatch(formLoaded(formData));
                return Object.assign({}, data, formData);
            }

            // The returnOnly variable is false by default and is used to indicate if we want to return the result only without a dispatch
            else {
                return formData;
            }
        }
        else {
            const status = codeToStatusText(result.status);
            let responseBody = null;
            let message = '';
            let correlationId = 'FEATURES/FORM/RUN_QUERY/FETCH_ERROR';
            try {
                responseBody = await result.json();
            } catch (_) {
                responseBody = await result.text();
            }
            if (typeof responseBody === 'string') {
                message = responseBody;
            }
            else {
                if (responseBody.message) {
                    message = responseBody.message;
                }
                if (responseBody.correlationId) {
                    correlationId = responseBody.correlationId;
                }
            }
            const err: TErrorState = {
                status: status,
                message: message,
                correlationId: correlationId,
                display: true,
                showResetOptions: query_type === 'insert' || query_type === 'update' || query_type === 'transaction' ? true : false
            };
            if (dispatchErrors) {
                dispatch(showError(err));
            }
            return err;
        }
    } catch (err) {
        if (validateTErrorState(err)) {
            if (dispatchErrors) {
                dispatch(showError(parseError(err)));
            }
            return parseError(err);
        }
        else {
            const errorObj: TErrorState = {
                status: 'Service Error',
                message: err.message,
                stack: err.stack,
                correlationId: 'FEATURES/FORM/RUN_QUERY/SYSTEM_ERROR',
                display: true,
                showResetOptions: query_type === 'insert' || query_type === 'update' || query_type === 'transaction' ? true : false
            };
            if (dispatchErrors) {
                dispatch(showError(errorObj));
            }
            return errorObj;
        }
    }
}