/**
 * @component PieChart.tsx
 * @description Displays a chart chart.
 */

// Imports
import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ChartJS from 'chart.js';
import palette from 'google-palette';
import { TChart } from '../features/view';
import { arrayContains, TGlobalState } from '../features/global';
import { TFormData } from '../features/form';
import { alertAction } from '../features/alert';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';

export default function Chart(props: TChart) {

    // Destructure props
    const { id, label, chartType, dataLabels, dataLabelKeys, borderColours, backgroundColours, hoverBackgroundColours, dataKey, staticValue, drilldownBaseHref, drilldownMode, drilldownUrlParam, valueKey, style, widths, thisTabOrStep } = props;

    // Grab data
    const { chartData, chartLabels, drilldownUrlValue, formReady, formLoading } = useSelector((state: TGlobalState) => {
        let chartLabels = [];
        if (dataLabelKeys) {
            for (let key of dataLabelKeys) {
                chartLabels.push(state.form.selectedValues?.[key] || '');
            }
        }
        if (dataLabels) {
            chartLabels = dataLabels;
        }
        // console.log('DEBUG: selected values:', state.form.selectedValues?.[dataKey]);
        return {
            chartData: staticValue && typeof staticValue === 'object' ? staticValue : state.form.selectedValues?.[dataKey] || [],
            chartLabels: chartLabels,
            drilldownUrlValue: state.form.selectedValues?.[drilldownUrlParam?.name || ''] || '',
            formReady: state.form.ready,
            formLoading: state.form.loading
        };
    });
    // console.log('DEBUG: chartData:', chartData);

    // Data formatters - puts the chartData into the format needed by the chart type
    // console.log('DEBUG: chartData:', chartData);
    const { formattedData, formattedLabels } = useMemo(() => {
        switch (chartType) {
            // Pie charts - we have an array with our dataKey (labels) and valueKey (counts)
            case 'pie':
                const labels = chartData && chartData instanceof Array ? chartData.map((item: TFormData) => item[dataKey] || '') : [];
                const values = chartData && chartData instanceof Array ? chartData.map((item: TFormData) => Number(item[valueKey]) || 0) : [];
                // console.log('DEBUG: recalculated formattedData:', values);
                return {
                    formattedData: values,
                    formattedLabels: labels
                };
            // Default - support for other chart types that have their own formatters can be added here if we can't get the data from SQL in the right form.
            default: 
                return {
                    formattedData: chartData,
                    formattedLabels: chartLabels
                };
        }
    }, [chartType, chartData, chartLabels, dataKey, valueKey]);

    // Memoise the dataset
    const dataSet = useMemo(() => {
        const defaultColours = palette('tol-rainbow', formattedData.length).map((hex: string) => `#${hex}`);
        // console.log('defaultColours:', defaultColours);
        return {
            label: label,
            data: formattedData,
            backgroundColor: backgroundColours || defaultColours,
            hoverBackgroundColor: hoverBackgroundColours || backgroundColours || defaultColours,
            borderColor: borderColours || defaultColours,
            borderWidth: 1
        };
    }, [label, formattedData, backgroundColours, hoverBackgroundColours, borderColours]);

    // Set sizes
    const sizes = {
        height: 400,
        width: 400
    };
    if (widths && widths.valueWidth) {
        switch (widths.valueWidth) {
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
                sizes.height = 15 * widths.valueWidth;
                sizes.width = 30 * widths.valueWidth;
                break;
            case 'auto':
            default:
                break;
        }
    }
    if (style && style.height) {
        sizes.height = !isNaN(style.height) ? style.height : sizes.height;
    }
    if (style && style.width) {
        sizes.width = !isNaN(style.width) ? style.width : sizes.width;
    }

    // useEffect hook for creating the chart
    const chartRef = useRef<Chart | null>(null);
    const previousData = useRef(dataSet.data);
    const dispatch = useDispatch();
    const thisTabOrStepRef = useRef(thisTabOrStep);
    useEffect(() => {
        let active = true;
        const canvas = document.getElementById(id) as HTMLCanvasElement | null;
        // console.log('canvas:', canvas, 'ref:', chartRef.current);
        if (active && canvas && formReady && !formLoading && dataSet.data.length > 0 && (!chartRef.current || !arrayContains(dataSet.data, previousData.current, true))) {
            // console.log('inside useEffect for Chart canvas:', canvas, 'ref:', chartRef.current);
            if (chartRef.current && !arrayContains(dataSet.data, previousData.current, true)) {
                previousData.current = dataSet.data;
                chartRef.current.destroy();
                chartRef.current = null;
            }
            
            // canvas.width = sizes.width;
            canvas.height = sizes.height;
            const ctx = canvas.getContext('2d');
            chartRef.current = new ChartJS(ctx as CanvasRenderingContext2D, {
                type: chartType,
                data: {
                    labels: formattedLabels,
                    datasets: [
                        dataSet
                    ]
                },
                options: { 
                    tooltips: {
                        callbacks: {
                            label: (tooltipItem, data) => {
                                const valueIndex = tooltipItem.index || 0;
                                const label = data.labels?.[valueIndex] as string || '';
                                const dataSetIndex = tooltipItem.datasetIndex || 0;
                                const dataValue = data.datasets?.[dataSetIndex]?.data?.[valueIndex] || 0;
                                if (drilldownMode && drilldownBaseHref) {
                                    return `${label}: ${dataValue} (click to drill down)`;
                                }
                                return `${label}: ${dataValue}`;
                            },
                            
                        }
                    }
                }
            });
            canvas.onmousemove = (event) => {
                const activePoints: any[] = chartRef.current?.getElementsAtEvent(event) || [];
                if (activePoints[0] && drilldownMode && drilldownBaseHref) {
                    document.body.style.cursor = 'pointer';
                }
                else {
                    document.body.style.cursor = 'auto';
                }
            }
            canvas.onclick = (event) => {
                const activePoints: any[] = chartRef.current?.getElementsAtEvent(event) || [];
                if (activePoints[0] && drilldownMode && drilldownBaseHref) {
                    const chartData = activePoints[0]['_chart'].config.data;
                    const index = activePoints[0]['_index'];
                    const label = chartData.labels[index];
                    let urlParams = new URLSearchParams();
                    urlParams.set('mode', drilldownMode);
                    if (drilldownUrlParam && (label || drilldownUrlValue)) {
                        urlParams.set('gridfilter', `${drilldownUrlParam.urlKey}--MATCH--${label || drilldownUrlValue}`);
                    }
                    const url = `${drilldownBaseHref}?${urlParams.toString()}`;
                    dispatch(alertAction({
                        severity: 'success',
                        message: 'Navigating to drilldown view...'
                    }));
                    setTimeout(() => {
                        window.location.href = url;
                    }, 1000);
                }
            }
        }
        
        return () => {
            active = false;
        }
    }, [id, chartType, chartLabels, dataSet, drilldownMode, drilldownBaseHref, dispatch, formattedLabels, formReady, formLoading, sizes.width, sizes.height, drilldownUrlParam, drilldownUrlValue, thisTabOrStep]);
    
    // Destructor - clear the chart if the step changes
    useEffect(() => {
        const tabOrStep = thisTabOrStepRef.current;
        // console.log('destructor useEffect:', tabOrStep, thisTabOrStep);
        return () => {
            if (tabOrStep !== thisTabOrStep) {
                // console.log('destroyng chart')
                chartRef.current && chartRef.current.destroy();
                chartRef.current = null;
            }
        }
    }, [thisTabOrStep]);

    // We return the canvas
    // console.log('chart render thisTabOrStep:', thisTabOrStep, thisTabOrStepRef);
    return (<>
        {!chartRef.current && (!formReady || formLoading)
        ? 
            <Skeleton animation="pulse" variant="circle" style={{marginLeft: 'auto', marginRight: 'auto', width: sizes.width, height: sizes.height*2}} />
        :
            !chartRef.current && formReady && !formLoading
        ?
            <Typography variant="h6">No data yet...</Typography>
        :
            null}
        <canvas id={id} width={sizes.width} height={sizes.height} />
    </>);
}