/**
 * @function validateEmail.ts Tests if an email address is valid according to the RFC specification. Doesn't use Regex, but rather checks for the presence and absence of characters in specific positions as per the specification.
 * @param {string} input The string to test.
 * @returns {boolean} Returns true if the input is a valid email address or false otherwise.
 */
export default function validateEmail(input: string) {
    let ampIsThere = false;
    let disallowedThere = false;
    let textBeforeAmp = false;
    let textAfterAmp = false;
    let dotAfterAmp = false;
    let textAfterDot = false;
    let otherError = false;

    for (var i = 0; i < input.length; i++) {
        if (input.charAt(i) === '@') {
            if (ampIsThere) {
                otherError = true;
            }
            ampIsThere = true;
        }
        else if (!ampIsThere) {
            textBeforeAmp = true;
        }
        else if (input.charAt(i) === '.') {
            dotAfterAmp = true;
        }
        else if (input.charAt(i-1) === '.') {
            textAfterDot = true;
        }
        else {
            textAfterAmp = true;
        }
        if (input.charAt(i) === ' ' || input.charAt(i) === ',' || input.charAt(i) === '(' || input.charAt(i) === ')' || input.charAt(i) === '<' || input.charAt(i) === '>' || input.charAt(i) === ';' || input.charAt(i) === ':' || input.charAt(i) === '\\' || input.charAt(i) === '"' || input.charAt(i) === '[' || input.charAt(i) === ']') {
            disallowedThere = true;
        }
    }

    if (disallowedThere || !ampIsThere || !textAfterAmp || !textBeforeAmp || !dotAfterAmp || !textAfterDot || otherError) {
        return false;
    }
    else {
        return true;
    }
}