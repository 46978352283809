/**
 * @file features/screens/actions
 * @description Contains Redux actions for loading a user's available screens and screen order.
 */

// Imports
import { TScreen, TScreenAction } from './types';

/**
 * The SCREENS_LOADING action is fired when available screens and order begin to load.
 */
export const SCREENS_LOADING = 'SCREENS_LOADING';

/**
 * The screensLoading function is used to dispatch the SCREENS_LOADING action - it is a pure action that simply dispatches the loading status.
 * @returns {TScreenAction} REturns a Redux action to be processed by the screensReducer.
 */
export const screensLoading = (): TScreenAction => {
    return {
        type: SCREENS_LOADING
    };
};

/**
 * The SCREENS_LOADED action is fired when available screens have loaded.
 */
export const SCREENS_LOADED = 'SCREENS_LOADED';

/**
 * The screensLoaded function dispatches the SCREENS_LOADED action - it is a pure action function that simply dispatches the data.
 */
export const screensLoaded = (screens: TScreen[]): TScreenAction => {
    return {
        type: SCREENS_LOADED,
        payload: screens
    };
};