/**
 * @component SelectIcon.tsx
 * @description A selection dropdown menu allowing the user to select an icon. Dispatches the icon's name to form data keys. 
 */

// Imports
import React, { forwardRef, useState, useEffect, ReactNode, ReactElement, MutableRefObject } from 'react';
import { useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { TAutocompleteComponent, EIconName } from '../features/view';
import { useValidation } from '../features/form';
import { TGlobalState, enumToArray } from '../features/global';
import RenderIcon from './RenderIcon';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const SelectIcon: React.FC<TAutocompleteComponent> = forwardRef((props: TAutocompleteComponent, ref) => {

    // Destructure props
    const { id, label, showLabel, dataKey, defaultValue, defaultValueKeys, defaultValueSeparator, required = false, style, className, updateKeys, staticValue, disabled, alreadyMounted } = props;
    const options = enumToArray(EIconName);

    // Get form state from Redux - we disable input if it's loading and we also grab the form value
    const { disableInput, formSelection } = useSelector((state: TGlobalState) => {
        return {
            disableInput: state.form.loading ? true : disabled ? true : false,
            formSelection: state.form.selectedValues?.[dataKey] || options[0].key
        };
    });
    
    // Set internal state
    const [value, setValue] = useState(<MenuItem key={0}><ListItemIcon><RenderIcon id={`menuicon=${0}`} staticValue={options[0].key} color={disableInput ? 'primary' : 'secondary'} /></ListItemIcon><span style={{display: 'none'}}>{options[0].key}</span><span>{options[0].value}</span></MenuItem>);
    const [stringValue, setStringValue] = useState(options[0].key);
    const optionsIndex = options.findIndex(val => val.key === value.key);
    const [indexValue, setIndexValue ] = useState(optionsIndex > -1 ? optionsIndex : 0);

    // useEffect hook for updating internal state based on Redux
    useEffect(() => {
        const currentIndex = options.findIndex(val => val.key === stringValue);
        if (currentIndex === -1) {
            setIndexValue(0);
            setStringValue(options[0].key);
        }
        else {
            setIndexValue(currentIndex);
            setStringValue(options[currentIndex].key);
        }
        if (formSelection !== stringValue) {
            setValue(<MenuItem key={currentIndex}><ListItemIcon><RenderIcon id={`menuicon=${currentIndex}`} staticValue={formSelection} color={disableInput ? 'primary' : 'secondary'} /></ListItemIcon><span style={{display: 'none'}}>{options[currentIndex].key}</span><span>{options[currentIndex].value}</span></MenuItem>);
            setStringValue(formSelection);
        }
    }, [options, stringValue, formSelection, disableInput]);

    // Event handlers for dispatching selections to Redux after validating input
    const { handleChange, handleBlur } = useValidation(dataKey, required, alreadyMounted as MutableRefObject<boolean>, setIndexValue, null, null, 'string', updateKeys, defaultValue || options[0].key, defaultValueKeys, defaultValueSeparator, false, staticValue, label, '', options.map(option => option.key)); 
    const wrappedChangeHandler = (event: React.ChangeEvent<{name?: string, value: unknown}>, child: ReactNode) => {
        handleChange(event, (child as ReactElement)?.props?.children?.[1]);
    }

    // Render the component
    return (
        <FormControl fullWidth={true}>
            <Select 
                id={id}
                inputRef={ref}
                value={indexValue}
                onChange={wrappedChangeHandler}
                onBlur={handleBlur}
                label={showLabel === false ? '' : label}
                required={required}
                disabled={disableInput}
                style={style}
                className={className}
                fullWidth={true}
            >
                {options.map((value, index) => <MenuItem key={index} value={index}><ListItemIcon><RenderIcon id={`menuicon=${index}`} staticValue={value.key} color={disableInput ? 'primary' : 'secondary'} /></ListItemIcon><span style={{display: 'none'}}>{value.key}</span><span>{value.value}</span></MenuItem>)}
            </Select>
        </FormControl>
    );
});

export default SelectIcon;