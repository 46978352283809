import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Imports - AzureAD
import { AzureAD } from 'react-aad-msal';
import { authProvider } from './features/auth';

// Imports - Redux
import { Provider } from 'react-redux';
import { rootStore } from './features/global';

// Imports - Theme
import { MuiThemeProvider } from '@material-ui/core/styles';
import fujitsuTheme from './Theme';

// Import SW
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={rootStore}>
      <AzureAD provider={authProvider} reduxStore={rootStore} forceLogin={true}>
        {window.self === window.top ?
          <MuiThemeProvider theme={fujitsuTheme}>
            <App />
          </MuiThemeProvider> : null}
      </AzureAD>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// Log web vitals to console in DEV
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV === 'development') {
  reportWebVitals(console.log);
}
// If we're not in DEV, register the ServiceWorker
else {
  serviceWorker.register();
}
