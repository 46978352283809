/**
 * @file features/form/paramValidateAndConvert.ts
 * @description Function for validating a parameter against an IParamType definition and converting it into the correct format for submission with a form.
 */

// Imports
import { TParamType } from '../view';
import { TFormData } from './types';
import validateEmail from './validateEmail';
import { isJson } from '../global';
import dayjs from 'dayjs';

// Export the function
export default function paramValidateAndConvert(param: TParamType, data: TFormData, currentUrlParams: URLSearchParams, componentLabel?: string): any {

    // console.log('DEBUG: paramValidateAndConvert input:', param, data, currentUrlParams);

    // Destructure parameter definition to get what we need
    const { key, type, required, urlParam, bodyData, maskPlaceholder, label } = param;
    const labelToUse = componentLabel || label || '';
    // console.log('key:', key, 'labelToUse:', labelToUse, 'label:', label, 'componentLabel:', componentLabel);

    // Required parameter
    if (required && urlParam) {
        // If we don't have a value in the data, insert URL parameter value
        if ((data[key] === undefined || data[key] === null || data[key] === '') && currentUrlParams.get(key)) {
            data[key] = currentUrlParams.get(key);
        }
        // If we don't have a value in the data or the URL parameter, throw an error
        else if (data[key] === undefined || data[key] === null || data[key] === '') {
            throw new TypeError(`Required parameter "${labelToUse || key}" not provided`);
        }
    }
    if (required && bodyData) {
        // console.log('PARAM: bodyData', data[key]);
        // If we don't have a value in the data or if it is null or empty, throw an error
        // console.log('DEBUG: required param value:', data[key], key);
        if (type === 'boolean' && !data[key]) {
            data[key] = false;
        }
        else if (data[key] === undefined || data[key] === null || data[key] === '') {
            throw new TypeError(`Required parameter "${labelToUse || key}" not provided`);
        }
        // For specific data types where we have masks/defaults, also throw an error for blanks
        switch (type) {
            case 'uuid':
                if (data[key] === '________-____-____-____-____________') {
                    throw new TypeError(`Required parameter "${labelToUse || key}" not provided: Expecting a UUID`);
                }
                break;
            case 'phone':
                if (data[key] === '+__ ___ ___ ___') {
                    throw new TypeError(`Required parameter "${labelToUse || key}" not provided: Expecting a Phone Number`);
                }
                break;
            case 'currency':
                if (data[key] === '$') {
                    throw new TypeError(`Required parameter "${labelToUse || key}" not provided: Expecting a Currency`);
                }
                break;
            case 'macaddress':
                if (data[key] === '__:__:__:__:__:__') {
                    throw new TypeError(`Required parameter "${labelToUse || key}" not provided: Expecting a MAC Address`);
                }
                break;
        }
        // console.log('DEBUG: paramValidateAndConvert before maskPlaceholder', data[key]);
        if (maskPlaceholder) {
            if (data[key] === maskPlaceholder || JSON.stringify(data[key]) === maskPlaceholder) {
                throw new TypeError(`Required parameter "${labelToUse || key}" not provided: Complete this field by entering valid input over this template: "${maskPlaceholder}"`);
            }
        }
    }

    // Process the parameter value
    let convertedParam: any = '';
    // Undefined, null and empty values are assigned the appropriate "empty" values for their datatype
    if ((data[key] === undefined || data[key] === null || data[key] === '')) {
        switch (type) {
            case 'string':
            case 'code':
            case 'file':
            case 'link':
            case 'phone':
            case 'email':
                convertedParam = '';
                break;
            case 'uuid':
            case 'number':
            case 'currency':
            case 'boolean':
            case 'date':
            case 'macaddress':
            case 'duration':
                convertedParam = null;
                break;
            case 'object':
                convertedParam = {};
                break;
            case 'array':
                convertedParam = [];
                break;
            default:
                throw new RangeError(`Unsupported data type "${type}" specified for "${labelToUse || key}"`);
        }
    }
    // Deal with the parameter as needed for its data type
    else {
        switch (type) {
            case 'boolean': {
                // If we have a string, accept 'true' and 'false' - convert to boolean primitive
                if (typeof data[key] === 'string') {
                    if (data[key] === 'true') {
                        convertedParam = true;
                        break;
                    }
                    else if (data[key] === 'false') {
                        convertedParam = false;
                        break;
                    }
                    else {
                        throw new TypeError(`Invalid input for "${labelToUse || key}": Expecting a boolean true/false`);
                    }
                }
                else if (typeof data[key] === 'boolean') {
                    convertedParam = data[key];
                    break;
                }
                else {
                    throw new TypeError(`Invalid input for "${labelToUse || key}": Expecting a boolean true/false`);
                }
            }
            case 'code': {
                // Accept string input - code formatting components & the server-side deal with more specific validation
                if (typeof data[key] === 'string') {
                    // convertedParam = escapeStrings ? paramEscape(data[key]) : data[key];
                    convertedParam = data[key];
                    // console.log('convertedParam - code: ', convertedParam);
                    // console.log('convertedParam', data[key]);
                    break;
                }
                else {
                    throw new TypeError(`Invalid input for "${labelToUse || key}": Expecting a code string`);
                }
            }
            case 'currency': {
                // Accept numbers and their string representations - we turn them all into currency strings (formatted string primitive that can be parsed for SQL currency fields)
                if (typeof data[key] === 'number' || typeof data[key] === 'bigint') {
                    let numberConversion: number = 0;
                    if (data[key].toFixed && typeof data[key].toFixed === 'function') {
                        numberConversion = data[key].toFixed(2);
                    }
                    else {
                        numberConversion = data[key];
                    }
                    let stringConversion: string = '';
                    if (numberConversion.toLocaleString && typeof numberConversion.toLocaleString === 'function') {
                        stringConversion = `$${numberConversion.toLocaleString('en-AU')}`;
                    }
                    else if (numberConversion.toString && typeof numberConversion.toString === 'function') {
                        stringConversion = `$${numberConversion.toString()}`;
                    }
                    else {
                        stringConversion = `$${numberConversion}`;
                    }
                    convertedParam = stringConversion;
                    break;
                }
                else if (typeof data[key] === 'string') {
                    if (data[key] === '$' || data[key] === '') {
                        convertedParam = '$';
                        break;
                    }
                    else if (isNaN(Number(data[key]))) {
                        if (isNaN(data[key].replace(/^\$/, '').replace(/,/g, ''))) {
                            throw new TypeError(`Invalid input for "${labelToUse || key}": Expecting a number or currency-formatted string`);
                        }
                        else {
                            convertedParam = data[key];
                            break;
                        }
                    }
                    else {
                        convertedParam = `$${data[key]}`;
                        break;
                    }
                }
                else {
                    throw new TypeError(`Invalid input for "${labelToUse || key}": Expecting a number or currency-formatted string`);
                }
            }
            case 'macaddress': {
                if (typeof data[key] === 'string' && /^[0-9A-F]{2,2}:[0-9A-Fa-f]{2,2}:[0-9A-Fa-f]{2,2}:[0-9A-Fa-f]{2,2}:[0-9A-Fa-f]{2,2}:[0-9A-Fa-f]{2,2}$/.test(data[key])) {
                    convertedParam = data[key];
                    break;
                }
                else if (data[key] === '' || data[key] === '__:__:__:__:__:__') {
                    convertedParam = '';
                    break;
                }
                else {
                    throw new TypeError(`Invalid input for "${labelToUse || key}": Expecting a MAC address`);
                }
            }
            case 'date': {
                // Accept Date and dayjs objects - convert to string
                if (data[key] instanceof Date || data[key] instanceof dayjs) {
                    convertedParam = data[key].toLocaleString();
                    break;
                }
                // Accept string if it's a parseable dayjs
                else if (typeof data[key] === 'string' && dayjs(data[key]).isValid()) {
                    convertedParam = dayjs(data[key]).toLocaleString();
                    break;
                }
                else if (!data[key]) {
                    convertedParam = '';
                    break;
                }
                else {
                    throw new TypeError(`Invalid input for "${labelToUse || key}": Expecting a date`);
                }
            }
            case 'duration': {
                // We are expecting a number followed by 'months', 'weeks', 'days', 'hours' or 'minutes' etc repeated at least once, with "ago" at the end optionally
                // console.log('DEBUG: paramValidateAndConvert duration input: ', data[key]);
                if (typeof data[key] === 'string' && /^(@\s)?([0-9]*\s(years?|months?|weeks?|days?|hours?|minutes?|seconds?)\s?)*(?:ago)?$/.test(data[key])) {
                    convertedParam = data[key];
                    break;
                }
                else if (!data[key]) {
                    convertedParam = '';
                    break;
                }
                else {
                    throw new TypeError(`Invalid input for "${labelToUse || key}": Expecting a duration in the format "x years y months z days h hours [ago]"`);
                }
            }
            case 'email': {
                // Test for a string that meets the RFC specification
                if (typeof data[key] === 'string' && validateEmail(data[key])) {
                    convertedParam = data[key];
                    break;
                }
                else if (!data[key]) {
                    convertedParam = '';
                    break;
                }
                else {
                    throw new TypeError(`Invalid input for "${labelToUse || key}": Expecting an email address`);
                }
            }
            case 'file': {
                if (typeof data[key] === 'string') {
                    convertedParam = data[key];
                    break;
                }
                // If we got a buffer, convert it to a Base64 string
                else if (data[key].constructor === Buffer) {
                    convertedParam = data[key].toString('base64');
                    break;
                }
                else {
                    throw new TypeError(`Invalid input for "${labelToUse || key}": Expecting a file`);
                }
            }
            case 'link': {
                // We just check that we have a string value
                if (typeof data[key] === 'string') {
                    convertedParam = data[key];
                    break;
                }
                else {
                    throw new TypeError(`Invalid input for "${labelToUse || key}": Expecting link text`);
                }
            }
            case 'number': {
                // Check for a number using isNaN
                if (isNaN(data[key])) {
                    throw new TypeError(`Invalid input for "${labelToUse || key}": Expecting a number`);
                }
                else {
                    convertedParam = data[key];
                    break;
                }
            }
            case 'phone': {
                // Check phone number regex
                // console.log('data[key] phone no:', data[key]);
                if (typeof data[key] === 'string' && /^\+[0-9]{1,2}(\s[0-9]{3,3}){3,3}$/.test(data[key])) {
                    convertedParam = data[key];
                    break;
                }
                else if (!data[key] || data[key] === '+__ ___ ___ ___') {
                    convertedParam = '';
                    break;
                }
                else {
                    throw new TypeError(`Invalid input for "${labelToUse || key}": Expecting a phone number in the form "+xx xxx xxx xxx xxx"`);
                }
            }
            case 'string': {
                if (typeof data[key] === 'string') {
                    // convertedParam = escapeStrings ? paramEscape(data[key]) : data[key];
                    convertedParam = data[key];
                    break;
                }
                else if (typeof data[key] === 'object') {
                    convertedParam = JSON.stringify(data[key]);
                    break;
                }
                else {
                    throw new TypeError(`Invalid input for "${labelToUse || key}": Expecting a string`);
                }
            }
            case 'array': {
                // console.log('DEBUG: inside array test: paramValidateAndConvert', key, data[key]);
                let result = isJson(data[key]);
                // console.log('result:', result);
                if (result === 2) {
                    try {
                        convertedParam = typeof data[key] === 'object' ? data[key] : JSON.parse(data[key]);
                        break;
                    } catch(error) {
                        throw new TypeError(`Invalid input for "${labelToUse || key}": Expecing an array. Error message: ${error.message}`);
                    }
                }
                else {
                    throw new TypeError(`Invalid input for "${labelToUse || key}": Expecing an array`);
                }
            }
            case 'object': {
                let result = isJson(data[key]);
                if (result > 0) {
                    try {
                        convertedParam = typeof data[key] === 'object' ? data[key] : JSON.parse(data[key]);
                        break;
                    } catch (error) {
                        throw new TypeError(`Invalid input for "${labelToUse || key}": expecting an object. Error message: ${error.message}`);
                    }
                }
                else {
                    throw new TypeError(`Invalid input for "${labelToUse || key}": Expecting an object`);
                }
            }
            case 'uuid': {
                // Regex test for UUID
                if (typeof data[key] === 'string' && /^[0-9a-f]{8,8}-[0-9a-fA-F]{4,4}-[0-9a-fA-F]{4,4}-[0-9a-fA-F]{4,4}-[0-9a-fA-F]{12,12}$/.test(data[key])) {
                    convertedParam = data[key];
                    break;
                }
                else if (!data[key] || data[key] === '________-____-____-____-____________') {
                    convertedParam = null;
                    break;
                }
                else {
                    throw new TypeError(`Invalid input for "${labelToUse || key}": Expecting a UUID (hexadecimal "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa")`);
                }
            }
            default:
                throw new RangeError(`Unsupported data type "${type}" specified for "${labelToUse || key}"`);
        }
    }
    // console.log('end of convertedParam:', key, convertedParam);
    return convertedParam;
}
