/**
 * @file features/global/arrayContains.ts
 * @description Checks whether an array contains an item or whether one array is a subset of another or the same as another. Uses shallowCompare to check array items that are objects.
 */

import shallowCompare from './shallowCompare';

/**
 * Checks whether an array contains an item or whether one array is a subset of another or the same as another, using shallowCompare to check array items that are objects. Note we use '==' rather than '===' to test for matches, so that '1' == 1.
 * @param {Array} testArray The array to check for the value(s).
 * @param {Array|any} searchItems The value(s) to try find in testArray.
 * @param {boolean|undefined} strictTest If searchItems is an array, only return true if testArray has the same items in the same order as testArray (using shallowCompare)
 * @returns {boolean} Returns true if the value(s) are found or false if not.
 */
export default function arrayContains(testArray: any[], searchItems: any, strictTest?: boolean): boolean {
    // If searchItems is an array and strictTest === true, only return true if searchItems and testArray have the same items in the same order - comparing the stringified values works as these should be the same for equivalent arrays in the same order
    if (searchItems.constructor === Array && strictTest) {
        return JSON.stringify(testArray) === JSON.stringify(searchItems);
    }
    // If searchItems is an array and !strictTest, return true if testArray contains searchItems
    else if (searchItems.constructor === Array) {
        let foundItems: boolean[] = [];
        for (let item of searchItems) {
            for (let value of testArray) {
                if (typeof value === 'object' && typeof item === 'object' && shallowCompare(value, item)) {
                    foundItems.push(true);
                    break;
                }
                else if ((typeof value !== 'object' || typeof item !== 'object') && value == item) // eslint-disable-line eqeqeq
                {
                    foundItems.push(true);
                    break;
                }
                else {
                    continue;
                }
            }
        }
        // Not all items found? Return false
        if (foundItems.length !== searchItems.length) {
            return false;
        }
        // Found all items? Return true
        else {
            return true;
        }
    }
    // If searchItems is not an array, return true if testArray contains searchItems
    else {
        for (let value of testArray) {
            if (typeof value === 'object' && typeof searchItems === 'object' && shallowCompare(value, searchItems)) {
                return true;
            }
            else if ((typeof value !== 'object' || typeof searchItems !== 'object') && value == searchItems) // eslint-disable-line eqeqeq
            {
                return true;
            }
            else {
                continue;
            }
        }
        return false;
    }
}