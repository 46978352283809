/**
 * @component QrScannerIcon.tsx
 * @description SVG Icon wrapper for the QR Code Scan icon. Allows MUI props to be passed to the icon.
 */

import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import {ReactComponent as QrIcon} from '../assets/qr_code_scanner-24px.svg';

const QrScannerIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon component={QrIcon} {...props}>
        </SvgIcon>
    );
};
export default QrScannerIcon;