/**
 * @file features/global/rootStore
 * @description Defines the root reducer that combines other reducers together & the Redux store.
 */

// Imports - external libs
import { combineReducers, applyMiddleware, createStore, CombinedState } from 'redux';
import { AuthenticationActions } from 'react-aad-msal';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

// Imports - custom Redux types and constants
import { TGlobalState, TAction } from './types';
import initialState from './initialState';

// Imports - reducers for the various features
import { authReducer } from '../auth';
import { alertReducer } from '../alert';
import { errorReducer } from '../error';
import { userReducer } from '../user';
import { screensReducer } from '../screens';
import { viewReducer } from '../view';
import { formReducer } from '../form';
import { gridReducer } from '../grids';

// Combine the reducers
const combinedReducers = combineReducers({
    aadResponse: authReducer,
    alert: alertReducer,
    error: errorReducer,
    user: userReducer,
    screens: screensReducer,
    view: viewReducer,
    form: formReducer,
    grids: gridReducer,
});

// Define the root reducer- we clear the state if authentication state changes
function rootReducer(state: TGlobalState | undefined, action: TAction): CombinedState<TGlobalState> {
    if (action.type === AuthenticationActions.AuthenticatedStateChanged && action.payload !== 'Authenticated')
    {
        state = initialState;
    }
    return combinedReducers(state, action);
};

// Compose the DevTools enhancer
const composeEnhancers = composeWithDevTools({trace: process.env.NODE_ENV === 'production' ? false : true, name: 'DeployIT', traceLimit: 25});

// Create the Redux store with middleware
const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunk)),
);
export default store;