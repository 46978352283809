/**
 * @file features/global/initialState
 * @description Global reducer's initial state
 */

import { TGlobalState } from './types';

import { aadInitialState } from '../auth';
import { alertInitialState } from '../alert';
import { errorInitialState } from '../error';
import { userInitialState } from '../user';
import { screensInitialState } from '../screens';
import { viewInitialState } from '../view';
import { formInitialState } from '../form';
import { gridsInitialState } from '../grids';

const initialState: TGlobalState = {
    aadResponse: aadInitialState,
    alert: alertInitialState,
    error: errorInitialState,
    user: userInitialState,
    screens: screensInitialState,
    view: viewInitialState,
    form: formInitialState,
    grids: gridsInitialState,
};
export default initialState;