/**
 * @file features/grids/configureGrid
 * @description Generates a TDataGrid object when provided with TFormData. It generates headings from the data keys and applies any extra grid config passed to it too.
 */

// Imports
import { TDataGrid } from '../view';
import { TFormData } from'../form';
import { Column } from 'material-table';
import { readableTitle } from '../global';

// Export the function
const configureGrid = (data: TFormData[], dataKey: string, customGridConfig?: TDataGrid) => {
    // console.log('DEBUG: configureGrid input data:', data);
    // Configure columns
    let columns: Column<TFormData>[] = [];
    for (let item of data) {
        for (let key in item) {
            if (columns.findIndex(val => val.field === key) === -1) {
                const column: Column<TFormData> = {
                    field: key,
                    title: readableTitle(key)
                };
                columns.push(column);
            }
        }
    }
    // console.log('DEBUG: configureGrid processed columns:', columns);

    // Configure styling
    const style = {
        headerStyle: {
            backgroundColor: '#777',
            color: '#FFF',
        },
        cellStyle: {
            width: 'auto',
            minWidth: '100px',
            maxWidth: '600px'
        },
        rowStyle: {
            verticalAlign: 'top'
        }
    };

    // Set defaults
    const gridDefaults: TDataGrid = {
        id: `datagrid-${dataKey}`,
        dataKey: dataKey,
        componentType: 'DataGrid',
        isLoading: false,
        label: 'Loading...',
        title: 'Loading...',
        showLabel: false,
        data: [],
        columns: [],
        style: style
    };
    const newGrid: TDataGrid = Object.assign({}, gridDefaults, customGridConfig, {data: data, columns: columns});
    return newGrid;
};
export default configureGrid;