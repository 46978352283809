/**
 * @file features/global/isJson.ts
 * @description Utility function for determining whether a value is (or can be converted) to a JSON array or object. Returns 0 if not a JSON, 1 if a JSON object or 2 if a JSON array.
 */

export default function isJson(value: number | bigint): 0;
export default function isJson(value: boolean): 0;
export default function isJson(value: null): 0;
export default function isJson(value: string): 0 | 1 | 2;
export default function isJson(value: any): 0 | 1 | 2;
export default function isJson(value: any): 0 | 1 | 2 {
	// A falsey value cannot be JSON
	if (!value) {
		return 0;
	}

	// If the value is an instanceof array, return 2
	if (value instanceof Array) {
		return 2;
	}

	// We don't treat numbers as JSON for display purposes
	const numberValue = Number(value);
	if (!isNaN(numberValue)) {
		return 0;
	}

	// We don't treat booleans as JSON for display purposes
	if (typeof value === 'boolean') {
		return 0;
	}

	// Nulls aren't JSON for display purposes
	if (value === null || value === 'null') {
		return 0;
	}

	// If we have a string, try parsing it. If it's one of the data types above when parsed, it's not JSON
	if (typeof value === 'string') {
		let parsedValue: any = null;
		try {
			parsedValue = JSON.parse(value);
			if (parsedValue === null) {
				return 0;
			}
			if (typeof parsedValue !== 'object') {
				return 0;
			}
		} catch (_) {
			return 0;
		}
		if (!parsedValue) {
			return 0;
		}

		// If we got up to here, it's either an object or array
		if (parsedValue instanceof Array) {
			return 2;
		}
		else {
			return 1;
		}
	}

	// If the value is otherwise of object type, if stringifiable, return 1; otherwise return 0
	if (typeof value === 'object') {
		try {
			JSON.stringify(value);
			return 1;
		} catch (_) {
			return 0;
		}
	}

	// Anything else? Return 0
	else {
		return 0;
	}
}