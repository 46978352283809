/**
 * @component LabelMaker.tsx
 * @description This component generates labels from FormData, which the user saves and that can be linked against records (e.g. Assets). This component is just the label maker itself - the FileAttachment component handles uploads, downloads, etc.
 */

import React, { forwardRef, useState, useEffect, useRef, useMemo, ReactNode, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { jsPDF } from 'jspdf';
import jsBarcode from 'jsbarcode';
import { TLabelMaker } from '../features/view';
import { TGlobalState, readableTitle } from '../features/global';
import { TFormData } from '../features/form';
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Button from './Button';
import { alertAction } from '../features/alert';

const LabelMaker: React.FC<TLabelMaker> = forwardRef((props: TLabelMaker, ref) => {
    // Destructure props, hook Redux
    const { id, dataKey, valueKey, updateKeys, barcodeKey, customStaticText = [], style, className, staticValue } = props;
    const { labelData, formReady, formLoading } = useSelector((state: TGlobalState) => {
        let data: TFormData = {
            [dataKey]: state.form.selectedValues?.[dataKey] || '',
            [valueKey]: staticValue || state.form.selectedValues?.[valueKey] || ''
        };
        if (barcodeKey) {
            data[barcodeKey] = state.form.selectedValues?.[barcodeKey] || '';
        }
        for (let key of updateKeys) {
            data[key] = state.form.selectedValues?.[key] || '';
        }
        for (let item of customStaticText) {
            data[item.key] = item.value || '';
        }
        return {
            labelData: data,
            formReady: state.form.ready && ((barcodeKey && data[barcodeKey]) || !barcodeKey),
            formLoading: state.form.loading
        };
    });

    const labelOptions = useMemo(() => ['Larger (102mm x 73mm)', 'Smaller (64mm x 26.7mm)'], []);

    // Memoise label data
    const memoisedData = useMemo(() => labelData, [labelData]);

    // Configure internal state
    const [generating, setGenerating] = useState(false);
    const [label, setLabel] = useState<jsPDF | null>(null);
    const [extraText, setExtraText] = useState<string>('');
    const [filename, setFileName] = useState('');
    const [labelSize, setLabelSize] = useState(0);

    // useEffect hook for generating the barcode when we have data
    const dispatch = useDispatch();
    const barcodeRef = useRef<SVGSVGElement | null>(null);
    useEffect(() => {
        let active = true;
        if (active && formReady && !formLoading && barcodeKey && !barcodeRef.current && memoisedData[barcodeKey] && !generating) {
            setGenerating(true);
            jsBarcode(`#${id}-barcode-svg`, memoisedData[barcodeKey], { width: 1, height: 60, displayValue: false });
            const svgElement: SVGSVGElement | null = document.getElementById(`${id}-barcode-svg`) as SVGSVGElement | null;
            if (svgElement) {
                barcodeRef.current = svgElement;
                setGenerating(false);
            }
            else {
                setGenerating(false);
            }
            dispatch(alertAction({
                severity: 'success',
                message: 'Barcode generated - label is ready to download'
            }));
        }
        return () => {
            active = false;
        }
    }, [barcodeKey, formLoading, formReady, generating, id, memoisedData, labelSize, valueKey, dispatch]);

    // Handler for downloading the barcode
    const handleDownload = () => {

        function loadImageAsPng(url: string) {
            return new Promise((resolve, reject) => {
                let sourceImage = new Image();
                sourceImage.onload = () => {
                    // console.log('onload src:', url)
                    let png = new Image();
                    let cnv = document.createElement('canvas');
                    cnv.height = sourceImage.height;
                    cnv.width = sourceImage.width;

                    let ctx = cnv.getContext('2d');

                    ctx?.drawImage(sourceImage, 0, 0);
                    png.src = cnv.toDataURL();
                    resolve(png.src);
                }
                sourceImage.onerror = reject;
                sourceImage.src = url;
            });
        }

        if (formReady && !formLoading && barcodeRef.current && !label && !generating) {
            (async () => {
                setGenerating(true);
                // console.log('inside label useEffect with data:', memoisedData);
                const dimensions = {
                    width: labelSize ? 64 : 102,
                    height: labelSize ? 26.7 : 73,
                    lineHeight: labelSize ? 2.5 : 4
                };
                const pdfDoc = new jsPDF('l', 'mm', [dimensions.width, dimensions.height]);
                pdfDoc.setFontSize(labelSize ? 5 : 8);
                pdfDoc.setFont('helvetica', 'bold');
                pdfDoc.setFillColor(0, 0, 0);
                pdfDoc.rect(2, 2, dimensions.width - 4, dimensions.lineHeight, 'F');
                pdfDoc.setTextColor(255, 255, 255);
                pdfDoc.text(memoisedData[valueKey], 4, labelSize ? 4 : 5);
                let printVals = [];
                for (let key in memoisedData) {
                    // console.log('value: ', memoisedData[key], key);
                    if (memoisedData[key] !== undefined && memoisedData[key] !== null && memoisedData[key] !== '') {
                        printVals.push(readableTitle(key.replace(/^dialog_/, '')));
                        printVals.push(memoisedData[key] || '');
                    }
                }
                printVals = printVals.filter(val => val !== readableTitle(dataKey.replace(/^dialog_/, '')) && val !== readableTitle(valueKey.replace(/^dialog_/, '')) && val !== memoisedData[dataKey] && val !== memoisedData[valueKey]);
                // console.log('printVals:', printVals, dataKey, valueKey);
                let currentYOffset = dimensions.lineHeight + (labelSize ? 4 : 6);
                for (let i = 0; i < printVals.length; i++) {
                    if ((i + 1) % 2 === 1) {
                        pdfDoc.setFillColor(0, 0, 0);
                        pdfDoc.rect(2, currentYOffset + (labelSize ? 0.5 : 1), dimensions.width - 4, dimensions.lineHeight - (labelSize ? 5 : 8), 'F');
                        pdfDoc.setTextColor(255, 255, 255);
                        pdfDoc.setFont('helvetica', 'bold');
                        pdfDoc.text(printVals[i], 4, currentYOffset);
                        currentYOffset += dimensions.lineHeight;
                    }
                    else if (barcodeKey && printVals[i] === memoisedData[barcodeKey] && barcodeRef.current) {
                        pdfDoc.setTextColor(0, 0, 0);
                        pdfDoc.setFont('helvetica', 'normal');
                        pdfDoc.text(printVals[i], 4, currentYOffset);
                        const parsedBarcode = new XMLSerializer().serializeToString(barcodeRef.current);
                        // console.log('parsedBarcode:', parsedBarcode);
                        const barcodeSvgUri = `data:image/svg+xml;base64,${btoa(parsedBarcode)}`;
                        try {
                            // console.log('uri:', barcodeSvgUri);
                            const png = await loadImageAsPng(barcodeSvgUri);
                            // console.log('png:', png);
                            pdfDoc.addImage(png as HTMLImageElement, 2, currentYOffset + 0.3, dimensions.width - 4, (dimensions.lineHeight * 2));
                        } catch (error) {
                            console.error(error);
                        }

                        currentYOffset += dimensions.lineHeight * 3;
                    }
                    else {
                        pdfDoc.setTextColor(0, 0, 0);
                        pdfDoc.setFont('helvetica', 'normal');
                        pdfDoc.text(printVals[i], 4, currentYOffset);
                        currentYOffset += dimensions.lineHeight;
                    }
                }
                if (extraText) {
                    pdfDoc.text(extraText, 4, currentYOffset - 1);
                }
                dispatch(alertAction({
                    severity: 'success',
                    message: 'Downloading label...'
                }));

                if (filename && /.pdf$/i.test(filename)) {
                    pdfDoc.save(filename);
                }
                else if (filename) {
                    pdfDoc.save(`${filename}.pdf`);
                }
                else {
                    pdfDoc.save(`${memoisedData[valueKey]}-${Date.now()}-label.pdf`);
                }
                setGenerating(false);
            })();
        }
    };

    return (
        <Grid item container xs={12} direction="column" justify="flex-start" alignItems="center" spacing={1} style={{ border: '1px solid black' }}>
            <Grid item container xs={11}>
                <Typography variant="h5" style={{ textDecoration: 'underline' }}>Label Generator:</Typography>
            </Grid>
            <Grid item container xs={11}>
                <Typography variant="body1"><em>If the fields below are locked, a label cannot be generated yet due to missing data. Please complete the form first.</em></Typography>
            </Grid>
            <Grid item container xs={11}>
                <FormControl fullWidth={true}>
                    <FormControlLabel control={
                        <Select
                            id={`${id}-select-size`}
                            inputRef={ref}
                            value={labelSize}
                            onChange={(event: React.ChangeEvent<{ name?: string, value: unknown }>, child: ReactNode) => {
                                const newIndex = typeof child !== 'boolean' ? typeof child === 'object' && typeof (child as ReactElement)?.props.value === 'number' ? (child as ReactElement)?.props.value : typeof (child as ReactElement)?.props.children === 'string' ? labelOptions.indexOf((child as ReactElement)?.props.children) : null : null;
                                if (newIndex > -1) {
                                    setLabelSize(newIndex);
                                    barcodeRef.current = null;
                                    setLabel(null);
                                }
                            }}
                            label='Select label size'
                            required={true}
                            disabled={!formReady || formLoading || generating}
                            style={style}
                            className={className}
                            fullWidth={true}
                        >
                            {labelOptions.map((val: string, index: number) => {
                                return <MenuItem key={index} value={index}>{val}</MenuItem>;
                            })}
                        </Select>} label="Select label size" labelPlacement="start" />
                </FormControl>
            </Grid>
            <Grid item container xs={11}>
                <FormControl fullWidth={true}>
                    <TextField id={`${id}-extra-label-text`} label='Enter extra message (if any)' disabled={!formReady || formLoading || generating} onChange={(event) => setExtraText(event.currentTarget.value)} value={extraText} />
                </FormControl>
            </Grid>
            <Grid item container xs={11}>
                <FormControl fullWidth={true}>
                    <TextField id={`${id}-filename`} label='Enter custom filename (if any)' disabled={!formReady || formLoading || generating} onChange={(event) => setFileName(event.currentTarget.value)} value={filename} />
                </FormControl>
            </Grid>
            <Grid item container xs={11} direction="row" justify="space-between">
                {barcodeKey ? <>
                    <Grid item>
                        <Typography variant="h5">Barcode generated:</Typography>
                    </Grid>
                    <Grid item>
                        <svg id={`${id}-barcode-svg`}></svg>
                    </Grid></> : null}
                <Grid item>
                    <Button id={`${id}-download-label-button`} label='Download label' disabled={!formReady || formLoading || generating} fullWidth={true} iconName="DownloadIcon" text="Download Label" button_purpose="misc" customAction={handleDownload} />
                </Grid>
            </Grid>
        </Grid>
    );
});
export default LabelMaker;
