/**
 * @component DataCell.tsx
 * A wrapper for rendering grid cell data. We have a separate component for this because we display data slightly differently in grids for readability, etc. This component also guesses the data type and renders the appropriate component accordingly. Rendering of different data types is done as follows:
 * - Undefined, null, empty string or empty array: Grey and italics Typography saying "- No data -".
 * - Boolean or string equivalent (e.g. "YES/NO", etc): A disabled switch in the on or off position.
 * - Icon name: Render the icon together with the key.
 * - Currency: Format currency with $ etc. Use red for negative numbers.
 * - Date/time: Format as DD/MM/YYYY h:mm am/pm.
 * - Duration: Remove the "@\s" at the beginning (if present) and render as "x hours y minutes [ago]".
 * - Number: Show string representation. Use red for negative numbers.
 * - Array of primitives (numbers or strings): Disabled AutocompleteMultiple (i.e. series of chips in the array order).
 * - Array of JSONs or other JSON: Display the ReactJson component.
 * - Rich text/HTML (including links): Display as such, though we only allow saf(er) rich text.
 * - String (or anything else): Display using Typography body2 variant.
 */

// Imports
import React from 'react';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import RenderIcon from './RenderIcon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactHtmlParser from 'react-html-parser';
import { TDataCellProps } from '../features/grids';
import { enumContains, isJson } from '../features/global';
import ReactJson from 'react-json-view';
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-light.css';
import { isTStoredQuery, queryRegexes, EQueryType, TQueryType } from '../features/view';
import TextField from '@material-ui/core/TextField';

// Declare and export the component
export default function DataCell(props: TDataCellProps): JSX.Element {
	const { cellData, column, dataKey, rowId, rowData } = props;
    const { title = '', field = '' } = column;
	// Is the data null, undefined or an empty string? If so, render "no data" in italics
	if (cellData === undefined || cellData === null || cellData === '')
	{
		return <Typography id={`datagrid-${dataKey}-celldata=${field}-${rowId}`} variant="body2" color="primary" style={{fontStyle: 'italics', fontSize: '0.75rem'}}>- No data -</Typography>;
	}

	// Do we have an SQL query? If so, render it as such
	let queryTypeKey = '';
	if (typeof rowData === 'object')
	{
		for (let key in rowData)
		{
			if (enumContains(EQueryType, rowData[key]))
			{
				queryTypeKey = key;
			}
		}
		// console.log('DEBUG: rowData queryTypeKey:', queryTypeKey, isTStoredQuery(rowData, false))
		if (queryTypeKey && typeof rowData === 'object' && isTStoredQuery(rowData, false) && queryRegexes[rowData[queryTypeKey] as TQueryType].test(cellData))
		{
			return (
				<Editor
                    id={`datagrid-${dataKey}-celldata=${field}-${rowId}`} 
					value={cellData}
					onValueChange={(value: string) => { 
                        // No-op
                    }}
					highlight={code => hljs.highlight('pgsql', code).value}
					padding={10}
					style={{
						fontFamily: 'monospace',
						fontWeight: 'bold',
						fontSize: '0.8rem',
						background: 'white',
						border: '1px solid black',
						boxShadow: 'inset 0 0 6px rgba(181, 181, 181, 0.8)'
					}}
					disabled={true}
				/>
			);
		}
	}

	// Boolean value or equivalent? Show a disabled switch component
	if (typeof cellData === 'boolean')
	{
		return <Switch id={`datagrid-${dataKey}-celldata=${field}-${rowId}`} checked={cellData} disabled={true} color={cellData ? "secondary" : "primary"} />;
	}
	if (typeof cellData === 'string' &&
		(
			cellData.toLowerCase().trim() === 'true' ||
			cellData.toLowerCase().trim() === 'yes' ||
			cellData.toLowerCase().trim() === 'on' ||
			cellData.toLowerCase().trim() === 'checked'
		)
	)
	{
		return <Switch id={`datagrid-${dataKey}-celldata=${field}-${rowId}`} checked={true} disabled={true} color="secondary" />;
	}
	if (typeof cellData === 'string' &&
		(
			cellData.toLowerCase().trim() === 'false' ||
			cellData.toLowerCase().trim() === 'no' ||
			cellData.toLowerCase().trim() === 'off' ||
			cellData.toLowerCase().trim() === 'unchecked'
		)
	)
	{
		return <Switch id={`datagrid-${dataKey}-celldata=${field}-${rowId}`} checked={false} disabled={true} color="primary"/>;
	}

	// Do we have an icon name (by convention, these contain 'icon') and is 'icon' also in the field name and column title? If so, render the icon and name
	if (/^[a-z]*icon$/i.test(cellData) && /icon/i.test(title.toString()) && /icon/i.test(field.toString()))
	{
		try {
			return <RenderIcon id={`datagrid-${dataKey}-celldata-${field}-${rowId}`} staticValue={cellData} color="secondary" showName='below' />;
		} catch (_) {
			return <Typography id={`datagrid-${dataKey}-celldata-${field}-${rowId}`} variant="body2">{cellData}</Typography>;
		}
	}

	// Do we have a currency field?
	if ((column.type === 'currency') && (typeof cellData === 'number' || typeof cellData === 'bigint' || !isNaN(cellData) || /^\$([0-9]{0,3},)*([0-9]{0,3})\.[0-9]*$/.test(cellData)))
	{
		let cellValue = cellData;
		switch (typeof cellData) {
			case 'number':
				cellValue = `$${cellData.toLocaleString('en-AU')}`;
				break;
			case 'bigint':
				cellValue = `${cellData.toString()}`;
				break;
			default:
				break;
		}
		return <Typography id={`datagrid-${dataKey}-celldata-${field}-${rowId}`} variant="body2">{cellValue}</Typography>;
	}
	else if (column.type === 'currency')
	{
		return <Typography id={`datagrid-${dataKey}-celldata-${field}-${rowId}`} variant="body2">$0</Typography>;
	}

	// Do we have another type of number or a value that can be converted to one?
	if (typeof cellData === 'number' || typeof cellData === 'bigint' || !isNaN(cellData))
	{
		return <Typography id={`datagrid-${dataKey}-celldata-${field}-${rowId}`} variant="body2">{cellData.toString()}</Typography>;
	}

	// Do we have an object or stringified JSON? If so, parse it and use the ReactJson component or a disabled AutocompleteMultiple component for primitive arrays
	if (isJson(cellData))
	{
		// console.log('cellData marked as JSON:', cellData);
		const parsedData = typeof cellData !== 'object' ? JSON.parse(cellData) : cellData;
		if (parsedData instanceof Array)
		{
			let typeTest = true;
			for (let item of parsedData)
			{
				if (typeof item !== 'number' && typeof item !== 'string')
				{
					typeTest = false;
					break;
				}
			}
			if (typeTest && parsedData.length > 0)
			{
				return <Autocomplete id={`datagrid-${dataKey}-celldata-${field}-${rowId}`} options={parsedData} style={{width: '100%', display: 'inline'}} value={parsedData} renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        disabled={true}
                    />
                )} disabled={true} multiple disableClearable={true} ChipProps={{color: 'secondary', disabled: true, deleteIcon: <></>}} />
			}
			else if (typeTest)
			{
				return <Typography id={`datagrid-${dataKey}-celldata-${field}-${rowId}`} variant="body2" color="primary" style={{fontStyle: 'italics', fontSize: '0.75rem'}}>- No data -</Typography>;
			}
		}
		return <ReactJson src={parsedData} name={false} collapsed={true} theme="bright:inverted" style={{textAlign: 'left'}} />;
	}

	// Rich text? Render it. Otherwise, just return the value wrapped in typography
	try {
		return <>{ReactHtmlParser(cellData)}</>;
	} catch (_)
	{
		let useCellData = cellData;
		if (typeof useCellData === 'string' && /^@\s/.test(useCellData))
		{
			useCellData = cellData.replace(/^@\s/, '');
		}
		return <Typography id={`datagrid-${dataKey}-celldata-${field}-${rowId}`} variant="body2">{useCellData}</Typography>;
	}
}