/**
 * @component Alert.tsx
 * @description Displays on-screen alerts.
 */

import React, { useState, useEffect, SyntheticEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { TGlobalState } from '../features/global';
import { alertAction } from '../features/alert';

export default function AlertComponent(): JSX.Element {
    const alert = useSelector((state: TGlobalState) => state.alert);
    const dispatch = useDispatch();
    const { display = false, severity, message, autoHideDuration } = alert;

    const [open, setOpen] = useState(false);

    useEffect(() => {
        // console.log('DEBUG: Alert useEffect triggered');
        let mounted = true;
        if (mounted) {
            setOpen(display);
        }
        return () => {
            mounted = false;
        };
    }, [display]);

    const handleClose = (event?: SyntheticEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        else {
            setOpen(false);
            dispatch(alertAction({ display: false }));
        }
    };

    return (
        open ?
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={autoHideDuration} onClose={handleClose}>
                <MuiAlert elevation={6} variant="filled" severity={severity} onClose={handleClose}>
                    {message}
                </MuiAlert>
            </Snackbar>
            : <></>
    );
}