/**
 * @file features/view/reducer
 * @description Redux reducer for currently-loaded View metadata.
 */

// Imports
import { VIEW_LOADING, VIEW_LOADED, VIEW_RESET } from './actions';
import viewInitialState from './initialState';
import { TViewState, TViewAction } from './types';

/**
 * The view reducer updates Redux state base on actions/payloads.
 * @param {TViewState} state Previous state object.
 * @param {TViewAction?} action Action to process.
 * @returns {TViewState} Returns the new state.
 */
const viewReducer = (state = viewInitialState, action: TViewAction | undefined): TViewState => {
    if (!action) {
        return state;
    }
    const { type, payload } = action;
    switch (type) {
        case VIEW_LOADING:
            return {
                ...state,
                loading: true
            };
        case VIEW_LOADED:
            if (!payload) {
                return {
                    ...state,
                    loading: false,
                    ready: true,
                    readyTime: new Date()
                };
            }
            return {
                ...state,
                loading: false,
                ready: true,
                readyTime: new Date(),
                currentView: payload || null
            };
        case VIEW_RESET:
            return {
                ...state,
                loading: false,
                ready: false,
                currentView: null,
                readyTime: null
            };
        default:
            return state;
    }
};
export default viewReducer;