/**
 * @component EditShortText.tsx
 * @description A simple one-line text edit field with no masks or special controls. This component dispatches a formEdit action on user input, but otherwise just shows the staticValue (prop provided by parent) or Redux state value for the dataKey. The component does have an option to accept barcode/QR code input; it does so anyway (barcode scanners behave like keyboards), but using this prop makes the component show an icon and a tooltip indicating that barcode input is appropriate.
 */

// Imports
import React, { forwardRef, MutableRefObject, RefObject, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { useValidation } from '../features/form';
import { TInputComponent, EDataType } from '../features/view';
import AdornEnd from './AdornEnd';
import { useSelector } from 'react-redux';
import { TGlobalState } from '../features/global';

// Declare the component with a forwardRef, so that we can pass a ref for adjusting focus, etc
const EditShortText: React.FC<TInputComponent> = forwardRef((props: TInputComponent, ref) => {
    // Destructure props
    const { id, label, showLabel, dataKey, defaultValue, defaultValueKeys, forceDefaultValueStart = false, defaultValueSeparator, required = false, style, className, updateKeys, barcodeInput, dataType = 'string', staticValue, disabled, alreadyMounted } = props;
    
    // Set internal state variables
    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');

    // Get form loading state - we disable input if it's loading
    const disableInput = useSelector((state: TGlobalState) => {
        return state.form.loading ? true : disabled ? true : false;
    });

    // Get the event handler from the useValidation hook
    const { handleChange, handleBlur } = useValidation(dataKey, required, alreadyMounted as MutableRefObject<boolean>, setValue, setError, setErrorText, dataType, updateKeys, defaultValue, defaultValueKeys, defaultValueSeparator, forceDefaultValueStart, staticValue, label);

    // Render the component
    return (
        <Tooltip 
            title={disableInput ? 'Editing locked' : barcodeInput ? 'Click here and scan or manually type barcode or QR code' : showLabel !== false && label ? label : `Enter ${EDataType[dataType]}`} 
            aria-label={barcodeInput ? `${id}-scan-code` : id}
        >
            <TextField
                id={id}
                label={showLabel === false ? '' : label}
                value={value}
                aria-invalid={disableInput ? false : error ? true : false}
                onChange={handleChange}
                onBlur={handleBlur}
                required={required}
                disabled={disableInput}
                error={disableInput ? false : error ? true : false}
                helperText={errorText}
                style={style}
                className={className}
                inputRef={ref as RefObject<HTMLInputElement>}
                fullWidth={true}
                InputProps={{
                    endAdornment: <AdornEnd disabled={disableInput} dataType={dataType} barcodeInput={barcodeInput} error={error} />
                }}
            />
        </Tooltip>
    );
});
export default EditShortText;