/**
 * @component SimpleSelect.tsx
 * @description A simple dropdown menu - not for typing/autocompletes, but for menus with a few fixed options.
 */

// Imports
import React, { forwardRef, useState, useEffect, MutableRefObject } from 'react';
import { useSelector } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { TAutocompleteComponent } from '../features/view';
import { useValidation } from '../features/form';
import { TGlobalState } from '../features/global';

// Declare and export the component
const SimpleSelect: React.FC<TAutocompleteComponent> = forwardRef((props: TAutocompleteComponent, ref) => {
    // Destructure props
    const { id, label, showLabel, dataKey, defaultValue, defaultValueKeys, defaultValueSeparator, required = false, options = ['- No options -'], style, className, staticValue, updateKeys, disabled, alreadyMounted } = props;

    // Set internal state variables
    const [value, setValue] = useState(options[0]);
    const optionsIndex = options.indexOf(value);
    const [indexValue, setIndexValue] = useState(optionsIndex > -1 ? optionsIndex : 0);

    // Get form state from Redux - we disable input if it's loading and we also grab the form value
    const { disableInput, formSelection } = useSelector((state: TGlobalState) => {
        return {
            disableInput: state.form.loading ? true : disabled ? true : false,
            formSelection: state.form.selectedValues?.[dataKey] || options[0]
        };
    });

    // useEffect hook for updating internal state based on Redux
    useEffect(() => {
        const currentIndex = options.indexOf(value);
        if (currentIndex === -1) {
            setIndexValue(0);
        }
        else {
            setIndexValue(currentIndex);
        }
        if (formSelection !== value) {
            setValue(formSelection);
        }
    }, [options, value, formSelection]);

    // Event handlers for dispatching selections to Redux & validating data
    const { handleChange, handleBlur } = useValidation(dataKey, required, alreadyMounted as MutableRefObject<boolean>, setIndexValue, null, null, 'string', updateKeys, defaultValue || options[0], defaultValueKeys, defaultValueSeparator, false, staticValue, label, '', options);

    // Render the component
    return (
        <FormControl fullWidth={true}>
            <Select
                id={id}
                inputRef={ref}
                value={indexValue}
                onChange={handleChange}
                onBlur={handleBlur}
                label={showLabel === false ? '' : label}
                required={required}
                disabled={disableInput}
                style={style}
                className={className}
                fullWidth={true}
            >
                {options.map((val: string, index: number) => {
                    return <MenuItem key={index} value={index}>{val}</MenuItem>;
                })}
            </Select>
        </FormControl>
    );
});
export default SimpleSelect;