/**
 * @file features/auth/initialState
 * @description Exports the initial state of the auth reducer.
 */

import { AuthenticationState } from 'react-aad-msal';
import { TAADResponseState } from './types';

const aadInitialState: TAADResponseState = {
    initialising: false,
    initialised: false,
    idToken: null,
    accessToken: null,
    authState: AuthenticationState.Unauthenticated,
    account: null
};
export default aadInitialState;