/**
 * @file features/user/reducer
 * @description The reducer for user profile actions.
 */

// Imports
import { PROFILE_LOADING, PROFILE_LOADED } from './actions';
import userInitialState from './initialState';
import { TUserState, TUserAction } from './types';

/**
 * The user reducer updates Redux state base on actions/payloads.
 * @param {TUserState} state Previous state object.
 * @param {TUserAction} action Action to process.
 * @returns {TUserState} Returns the new state.
 */
const userReducer = (state = userInitialState, action: TUserAction | undefined): TUserState => {
    if (!action)
    {
        return state;
    }
    const { type, payload } = action;
    switch (type)
    {
        case PROFILE_LOADING:
            return {
                ...state,
                loading: true
            };
        case PROFILE_LOADED:
            if (!payload)
            {
                return state;
            }
            return {
                ...state,
                loading: false,
                profile: payload
            };
        default:
            return state;
    }
};
export default userReducer;