/**
 * @file features/view/isTComponentType
 * @description Type guard for the TComponentType (list of valid component types)
 */

import { EComponentType, TComponentType } from './types';

const isTComponentType = (input: unknown): input is TComponentType => {
    try {
        const test = EComponentType[input as TComponentType];
        if (!test) {
            return false;
        }
        else {
            return true;
        }
    } catch (_) {
        return false;
    }
};
export default isTComponentType;