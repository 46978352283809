/**
 * @file features/screens/useScreens
 * @description Contains the custom useScreens hook - this should be used with components that require screens to be loaded (like the main menu). It makes sure we have screens and if not, fetches them.
 */

// Imports
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TGlobalState, screensUrl } from '../global';
import { authProvider } from '../auth';
import { codeToStatusText, showError, TErrorState, validateTErrorState, parseError } from '../error';
import { screensLoading, screensLoaded } from './actions';
import { TScreen } from './types';

const useScreens = () => {

    // Hook dispatch and the store
    const dispatch = useDispatch();
    const { screens, ready}  = useSelector((state: TGlobalState) => {
        return {
            ...state,
            ready: state.user.profile && !state.user.loading && !state.screens.loading && state.screens.available && state.screens.available.length === 0 && !state.error.display,
            errorDisplay: state.error.display
        };
    });
    

    // useEffect hook for loading screens
    const screensRef = useRef(screens.available && screens.available.length > 0 ? true : false);
    useEffect(() => {
        let cancelled = false;
        // console.log('useScreens useEffect running')
        const fetchScreens = async() => {
                dispatch(screensLoading());
                try {
                    const token = await authProvider.getAccessToken();
                    const init = {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token.accessToken}`
                        }
                    };
                    const result = await fetch(screensUrl, init);
                    if (result.ok)
                    {
                        const screens: TScreen[] = await result.json().then(body => body.data);
                        screensRef.current = true;
                        return dispatch(screensLoaded(screens));
                    }
                    else
                    {
                        const status = codeToStatusText(result.status);
                        let responseBody = null;
                        let message = '';
                        let correlationId = 'FEATURES/SCREENS/USE_SCREENS/SCREENS_FETCH_ERROR';
                        try {
                            responseBody = await result.json();
                        } catch (_) {
                            responseBody = await result.text();
                        }
                        if (typeof responseBody === 'string')
                        {
                            message = responseBody;
                        }
                        else
                        {
                            if (responseBody.message)
                            {
                                message = responseBody.message;
                            }
                            if (responseBody.correlationId)
                            {
                                correlationId = responseBody.correlationId;
                            }
                        }
                        const err: TErrorState = {
                            status: status,
                            message: message,
                            correlationId: correlationId,
                            display: true
                        };
                        dispatch(showError(err));
                    }
                } catch (err) {
                    if (validateTErrorState(err))
                    {
                        dispatch(showError(parseError(err)));
                    }
                    else
                    {
                        const errorObj: TErrorState = {
                            status: 'Service Error',
                            message: err.message,
                            stack: err.stack,
                            correlationId: 'FEATURES/SCREENS/USE_SCREENS/SCREENS_SYSTEM_ERROR',
                            display: true
                        };
                        dispatch(showError(errorObj));
                    }
                    screensRef.current = true;
                }
        };
        if (!cancelled && ready && !screensRef.current && !screens.loading)
        {
            fetchScreens();
        }
        // Cleanup function - makes sure we don't run the fetch if not mounted
        return () => {
            cancelled = true;
        }

    }, [dispatch, screens.loading, ready]);
};
export default useScreens;