
// Imports - React, etc.
import React, { RefObject, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Link, Switch } from 'react-router-dom';

// Imports - MUI styling, layout components, assets
import { withStyles, Theme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import DeployITLogo from './assets/deployit-logo-400.png';
import Background from './assets/background.jpg';
import { Styles } from '@material-ui/styles';
import { Classes } from '@material-ui/styles/mergeClasses/mergeClasses';

// Imports - custom components, types and functions
import AlertComponent from './components/AlertComponent';
import ErrorBoundary from './components/ErrorBoundary';
import ErrorComponent from './components/ErrorComponent';
import CopyrightFooter from './components/CopyrightFooter';
import ExpandingMenu from './components/ExpandingMenu';
import UserMenu from './components/UserMenu';
import RenderIcon from './components/RenderIcon';
import Welcome from './components/Welcome';
import Screen from './components/Screen';
import { TGlobalState } from './features/global';
import Demo from './components/Demo';

// Configure styling
const drawerWidth = 290;
const styles = (theme: Theme): Styles<Theme, {}, string> => ({
  '@global': {
    '*::-webkit-scrollbar': {
      width: '13px',
      height: '13px'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(181, 181, 181, 0.8)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.secondary.light,
      border: '1px solid rgba(0, 0, 0, 0.25)',
      borderRadius: '6px',
      '&:hover': {
        backgroundColor: theme.palette.secondary.dark
      }
    },
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }
  },
  root: {
    display: 'flex'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    height: '120px',
    padding: '2vh',
    paddingTop: '30px',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin, width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    height: '120px',
    padding: '2vh',
    paddingTop: '30px',
  },
  appBarLeft: {
    width: drawerWidth,
    marginLeft: 0,
    marginRight: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    height: '120px',
    padding: '2vh',
    paddingTop: '30px',
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    background: `url(${Background}) no-repeat center center fixed`,
    '-webkit-background-size': 'cover',
    '-moz-background-size': 'cover',
    '-o-background-size': 'cover',
    backgroundSize: 'cover',
    paddingTop: '130px',
  },
  drawerHeader: {
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    height: '120px',
  },
  logoImage: {
    maxHeight: '60px',
    height: 'auto',
    maxWidth: '150px',
    width: 'auto',
    marginLeft: '30px',
  },
  heading: {
    fontFamily: 'Fujitsu-Sans-Bold',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    ...theme.mixins.toolbar
  },
  menuHeaderText: {
    display: 'flex',
    alignItems: 'center',
    color: 'white',
    justifyContent: 'flex-start',
    marginLeft: '50px',
    fontFamily: 'Fujitsu-Sans-Regular'
  },
  userMenu: {
    justifyContent: 'flex-end'
  },
  downIcon: {
    justifyContent: 'flex-end'
  },
  accordion: {
    width: drawerWidth - 50
  },
  content: {
    flex: '0 1 auto',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: -drawerWidth,
    background: `url(${Background}) no-repeat center center fixed`,
    '-webkit-background-size': 'cover',
    '-moz-background-size': 'cover',
    '-o-background-size': 'cover',
    backgroundSize: 'cover',
    minHeight: '100vh',
    width: '100%'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginLeft: 0
  },
  contentPaper: {
    background: 'rgba(255, 255, 255, 0.9)',
    border: '7px solid rgba(0, 0, 0, 0.3)',
    borderRadius: '10px',
    marginTop: '130px',
    margin: '30px',
    maxWidth: '100%',
    whiteSpace: 'normal',
    minHeight: '70vh',
    padding: '30px'
  }
});

// Interface for App props
interface IAppProps {
  classes: Classes;
  ref?: ((instance: any | null) => void) | RefObject<any> | null;
}

function App(props: IAppProps): JSX.Element {

  // Configure drawer state and handlers
  const [open, setOpen] = useState(false);
  const openMenu = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setOpen(true);
  const closeMenu = (_: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setOpen(false);

  // Destructure class names
  const { classes } = props;

  // Get error state from Redux
  const error = useSelector((state: TGlobalState) => state.error);

  return (
    <div className={classes.root}>
      <Router>
        <CssBaseline />
        <AppBar position="fixed" className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}>
          <Toolbar>
            <Grid container direction="row" justify="space-evenly" alignItems='center' alignContent='center'>
              <Grid item xs={4} container direction="row" justify="flex-start" alignItems='center' alignContent='center'>
                <IconButton color="inherit" aria-label="open-main-menu" onClick={openMenu} edge="start" className={clsx(classes.menuButton, open && classes.hide)}>
                  <RenderIcon id="main-menu-open" staticValue="MenuIcon" />
                </IconButton>
                <Link to="/#"><img src={DeployITLogo} alt="DeployIT Logo" className={classes.logoImage} /></Link>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h1" style={{ color: 'black' }}>&nbsp;{process.env.REACT_APP_HEADING_BLACK || 'Deploy'}<span style={{ color: "red" }}>{process.env.REACT_APP_HEADING_RED || 'IT'}</span>&nbsp;</Typography>
              </Grid>
              <Grid item xs={4}>
                <UserMenu />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer variant="persistent" className={classes.drawer} anchor="left" open={open} classes={{ paper: classes.drawerPaper }}>
          <AppBar className={classes.appBarLeft}>
            <Grid container direction="row" justify="space-between">
              <Typography variant="h2" className={classes.menuHeaderText}>Main Menu</Typography>
              <IconButton onClick={closeMenu}>
                <RenderIcon id="main-menu-close" staticValue="ChevronLeftIcon" />
              </IconButton>
              <span>&nbsp;</span>
            </Grid>
          </AppBar>
          <ExpandingMenu accordionClass={classes.accordion} />
        </Drawer>
        <main className={clsx(classes.content, {
          [classes.contentShift]: open
        })}>
          <div className={classes.contentPaper}>
            <AlertComponent />
            <Grid container direction="column" justify="space-between">
              {error.display ? <ErrorComponent /> : 
              <ErrorBoundary correlationIdText="APP/SCREEN_SWITCH_ERROR">
                <Switch>
                  <Route path="/" exact>
                    <Welcome />
                  </Route>
                  <Route path="/demo" exact>
                  {process.env.NODE_ENV === 'development' ? 
                    <Demo /> : <Typography variant="h3">The system component demos are only available in Development mode.</Typography>}
                  </Route> 
                  <Route path="/:category_name/:screen_name">
                    <Screen />
                  </Route>
                </Switch>
              </ErrorBoundary>}
              <CopyrightFooter />
            </Grid>
          </div>
        </main>
      </Router>
    </div>
  );
}

export default withStyles(styles as Styles<Theme, {}, string>)(App);
