/**
 * @file AutocompleteMenu.tsx
 * @description An autocomplete menu that fetches options from the back-end. This component supports autocompletes where multiple form values are mapped to the options (i.e. selecting a value will also fill other values). It also supports multiple value selection.
 */

// Imports
import React, { forwardRef, useState, useMemo, useEffect, useRef, MutableRefObject } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Autocomplete, { AutocompleteChangeReason } from '@material-ui/lab/Autocomplete';
import { TAutocompleteComponent } from '../features/view';
import { arrayContains, TGlobalState } from '../features/global';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Cancel';
import Tooltip from '@material-ui/core/Tooltip';
import { formEdit, formLoaded, formLoading, runQuery, TFormData } from '../features/form';
import { validateTErrorState } from '../features/error';
import { alertAction } from '../features/alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import paramValidateAndConvert from '../features/form/paramValidateAndConvert';

const AutocompleteMenu: React.FC<TAutocompleteComponent> = forwardRef((props: TAutocompleteComponent, ref) => {

    // Destructure props
    const { id, label, showLabel, dataKey, defaultValue, defaultValueKeys = [], defaultValueSeparator, required = false, style, className, updateKeys = [], staticValue, disabled, valueKey = dataKey, fetchQueries = [], multiple, freeSolo, options = [], optionsKey } = props;

    // Get Redux state
    const emptyObj = useMemo(() => {
        const obj = {
            [valueKey]: '',
            [dataKey]: ''
        };
        for (let key of updateKeys) {
            obj[key] = '';
        }
        return obj;
    }, [valueKey, dataKey, updateKeys]);
    const { formSelection, initialOptions, formData, queries, ready, formCurrentlyLoading } = useSelector((state: TGlobalState) => {
        const queries = state.view.currentView?.query.filter((_, i) => fetchQueries.indexOf(i) > -1) || [];
        const params = queries.map(query => query.parameters.map(p => p.key)).reduce((previous, current) => [...previous, ...current], []);
        let valueSelection = state.form.selectedValues?.[valueKey] || (multiple ? [] : '');
        if (multiple && !(valueSelection instanceof Array)) {
            try {
                valueSelection = JSON.parse(valueSelection);
            } catch (_) {
                valueSelection = [];
            }
        }
        const formData: TFormData = {
            [valueKey]: valueSelection,
            [dataKey]: state.form.selectedValues?.[dataKey] || ''
        };
        if (optionsKey) {
            formData[optionsKey] = state.form.selectedValues?.[optionsKey] || '';
        }
        for (let key of [...updateKeys, ...defaultValueKeys, ...params]) {
            formData[key] = state.form.selectedValues?.[key] || '';
        }
        let initialOptions = [emptyObj];
        if (options && options.length) {
            initialOptions = options.map(option => {
                return {
                    [valueKey]: option
                };
            });
        }
        else if (optionsKey && formData[optionsKey] instanceof Array) {
            initialOptions = formData[optionsKey].map((option: string) => {
                return {
                    [valueKey]: option
                };
            });
        }
        else if (valueKey && formData[valueKey] instanceof Array) {
            initialOptions = formData[valueKey].map((option: string) => {
                return {
                    [valueKey]: option
                };
            });
        }
        else if (valueKey && formData[valueKey] !== undefined) {
            initialOptions = [{[valueKey]: formData[valueKey]}];
        }
        else {
            initialOptions = [];
        }

        return {
            formSelection: valueSelection,
            formData: formData,
            queries: state.view.currentView?.query.filter((_, i) => fetchQueries.indexOf(i) > -1) || [],
            ready: state.view.ready && state.form.ready,
            initialOptions: initialOptions,
            formCurrentlyLoading: state.form.loading
        }
    });

    // Set state - options
    const [currentOptions, setCurrentOptions] = useState<TFormData[]>([emptyObj, ...initialOptions]);

    // Memoise the default value
    const memoisedDefault = useMemo(() => {
        let defaultValueStr = staticValue || defaultValue || '';
        let defaultValues = [];
        if (!staticValue && defaultValueKeys && defaultValueKeys instanceof Array && defaultValueKeys.length > 0) {
            for (let key of defaultValueKeys) {
                defaultValues.push(formData[key]);
            }
            defaultValueStr = defaultValues.join(defaultValueSeparator || ' ');
        }
        if (multiple) {
            return defaultValues.map(item => {
                return {
                    [valueKey]: item
                };
            });
        }
        else {
            if (required && !defaultValueStr) {
                return {
                    [valueKey]: currentOptions[0]?.[valueKey] || ''
                };
            }
            return {
                [valueKey]: defaultValueStr
            };
        }
    }, [staticValue, defaultValue, defaultValueKeys, multiple, defaultValueSeparator, formData, valueKey, required, currentOptions]);

    // Set state for values, error messages, etc.
    const [value, setValue] = useState<TFormData | TFormData[]>(memoisedDefault);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [open, setOpen] = useState(false);

    // Refs etc
    const loaded = useRef(false);
    const alreadyLoading = useRef(false);
    const fetchNow = open && ready && !loaded.current && !alreadyLoading.current;

    // useEffect hook that does the data fetching and synchronisation
    const dispatch = useDispatch();
    const mountedAlready = useRef(false);
    useEffect(() => {
        let active = true;

        // Utility function for setting value and dispatching formData
        const dispatchFormData = (valKey: string, otherKeys: string[], data: TFormData, dispatchVal: boolean = true) => {
            if (dispatchVal) {
                dispatch(formEdit({
                    key: valKey,
                    value: data[valKey]
                }));
            }
            setValue(data);
            for (let key of otherKeys) {
                if (data[key] !== undefined && data[key] !== null) {
                    dispatch(formEdit({
                        key: key,
                        value: data[key]
                    }));
                }
                else {
                    dispatch(formEdit({
                        key: key,
                        value: ''
                    }));
                }
            }
        };

        // Do we have a form value? If so, set it and add it as a valid option
        if (active && !fetchNow && ready && !multiple && !(formSelection instanceof Array) && (value as TFormData)?.[valueKey] !== formSelection) {
            // console.log('DEBUG: inside value setter - one value');
            let optionIndex = currentOptions.findIndex(opt => opt[valueKey] === formSelection);
            if (optionIndex === -1) {
                optionIndex = currentOptions.length;
                setCurrentOptions([...currentOptions, Object.assign({}, emptyObj, { [valueKey]: formSelection })]);
                setValue(Object.assign({}, emptyObj, { [valueKey]: formSelection }));
            }
            else {
                setValue(currentOptions[optionIndex]);
            }
            setError(false);
            setErrorText('');
            mountedAlready.current = true;
            return undefined;
        }
        else if (active && !fetchNow && ready && multiple && formSelection instanceof Array && value instanceof Array && !arrayContains(value.map(v => v[valueKey]), formSelection)) {
            // console.log('DEBUG: inside value setter - arrays', formSelection, value.map(v => v[valueKey]));
            const newOptions: TFormData[] = [];
            const selectedValues: TFormData[] = [];
            for (let item of formSelection) {
                if ((freeSolo || !mountedAlready.current) && currentOptions.findIndex(opt => opt?.[valueKey] === item) === -1) {
                    newOptions.push(Object.assign({}, emptyObj, {[valueKey]: item}));
                    selectedValues.push(Object.assign({}, emptyObj, {[valueKey]: item}));
                }
                else if (currentOptions.findIndex(opt => opt[valueKey] === item) > -1) {
                    selectedValues.push(Object.assign({}, emptyObj, {[valueKey]: item}));
                }
            }
            // console.log('newOptions:', newOptions);
            // console.log('selectedVAlues:', selectedValues);
            setCurrentOptions([...currentOptions, ...newOptions]);
            setValue(selectedValues);
            setError(false);
            setErrorText('');
            mountedAlready.current = true;
            return undefined;
        }

        // Set the error text if this is a required field and we have nothing
        if (active && !fetchNow && required && ready && ((value instanceof Array && value.length === 0) || (!(value instanceof Array) && !value[valueKey] && !error))) {
            // console.log('DEBUG: useEffect: autocomplete invalid branch: ', active, !fetchNow, required, ready, dataKey, value);
            setError(true);
            setErrorText('Required field');
            mountedAlready.current = true;
            return undefined;
        }

        // Run the data fetching queries if active etc
        (async () => {
            // console.log('DEBUG:', active, open, ready, !loaded.current, !alreadyLoading.current);
            if (active && fetchNow) {
                try {
                    alreadyLoading.current = true;
                    // console.log('Fetching autocompletes for', dataKey);
                    if (queries[0]) {
                        const params = queries[0].parameters;
                        let pass = true;
                        for (let param of params) {
                            try {
                                paramValidateAndConvert(param, formData, new URLSearchParams(window.location.search));
                            } catch (error) {
                                setError(true);
                                setErrorText(error.message);
                                pass = false;
                            }
                        }
                        if (pass) {
                            setError(false);
                            if (!formCurrentlyLoading) {
                                dispatch(formLoading());
                            }
                            const result = await runQuery(queries[0], formData, dispatch, true);
                            if (result) {
                                dispatch(formLoaded());
                            }
                            if (validateTErrorState(result) || result.length === 0) {
                                loaded.current = true;
                                alreadyLoading.current = false;
                                setError(true);
                                setErrorText(validateTErrorState(result) ? `Error: ${result.message}` : 'No available options');
                                setCurrentOptions(initialOptions);
                                return;
                            }
                            if (result.findIndex(item => item[valueKey] === '') === -1) {
                                result.unshift(emptyObj);
                            }
                            const selectedRow = result.find(item => item[valueKey] === formSelection) || emptyObj;
                            dispatchFormData(valueKey, [dataKey, ...updateKeys], selectedRow);
                            setCurrentOptions(result);
                        }
                    }
                    else {
                        setCurrentOptions(initialOptions);
                    }
                    // console.log('DEBUG: Done fetching and we have this ref:', ref);
                    alreadyLoading.current = false;
                    loaded.current = true;
                    mountedAlready.current = true;
                    if (ref && (ref as MutableRefObject<HTMLInputElement>).current) {
                        (ref as MutableRefObject<HTMLInputElement>).current.focus();
                    }
                } catch (error) {
                    loaded.current = false;
                    alreadyLoading.current = false;
                    mountedAlready.current = true;
                    dispatchFormData(valueKey, [dataKey, ...updateKeys], emptyObj);
                    setError(true);
                    setErrorText(`Error fetching options: ${error.message}`);
                }
            }
        })();

        // Return a cleanup function
        return () => {
            active = false;
        };

    }, [currentOptions, dataKey, dispatch, formData, updateKeys, value, valueKey, ready, queries, required, emptyObj, error, initialOptions, formSelection, fetchNow, freeSolo, multiple, ref, open, formCurrentlyLoading]);

    // Event handlers for dispatching selections to Redux
    const handleChange = (event: React.ChangeEvent<{}>, newValue: TFormData | string[], changeReason: AutocompleteChangeReason) => {
        // Set the loaded.current ref to false so we reload the options if the menu is reopened
        loaded.current = false;

        // Static value? We can't change it
        if (staticValue) {
            dispatch(alertAction({
                severity: 'warning',
                message: `The value in "${label || dataKey}" is auto-filled and cannot be changed.`,
                autoHideDuration: 6000,
                display: true
            }));
            setValue(multiple ? [Object.assign({}, emptyObj, { [valueKey]: staticValue })] : Object.assign({}, emptyObj, { [valueKey]: staticValue }));
            return;
        }

        // Is this a clear? Clear values
        if (changeReason === 'clear') {
            // console.log('clear event');
            setValue(multiple ? [] : Object.assign({}, emptyObj, { [valueKey]: '' }));
            dispatch(formEdit({
                key: valueKey,
                value: multiple ? [] : ''
            }));
            for (let key of [dataKey, ...updateKeys]) {
                dispatch(formEdit({
                    key: key,
                    value: ''
                }));
            }
            setOpen(false);
            return;
        }

        // Set values and dispatch
        if (newValue instanceof Array && value instanceof Array) {
            if (freeSolo && !arrayContains(currentOptions, newValue)) {
                const mappedValues = newValue.map(val => {
                    if (typeof val === 'string') {
                        return Object.assign({}, emptyObj, { [valueKey]: val });
                    }
                    else {
                        return val;
                    }
                });
                const updatedOptions = [...currentOptions, ...mappedValues].sort((a, b) => a[valueKey] - b[valueKey]).filter((item, index, self) => self.findIndex(s => s[valueKey] === item[valueKey]) === index);
                setCurrentOptions(updatedOptions);
                setValue(mappedValues);
                dispatch(formEdit({
                    key: valueKey,
                    value: mappedValues.map(v => v[valueKey] || '') || []
                }));
                if (dataKey !== valueKey) {
                    dispatch(formEdit({
                        key: dataKey,
                        value: mappedValues.map(v => v[dataKey] || '') || []
                    }));
                }
                for (let key of updateKeys) {
                    dispatch(formEdit({
                        key: key,
                        value: mappedValues.map(v => v[key] || '') || []
                    }));
                }
                setError(false);
                setErrorText('');
            }
            else if (!freeSolo && !arrayContains(currentOptions, newValue)) {
                setError(true);
                setErrorText('Invalid Selection');
            }
            else {
                const mappedValues = newValue.map(val => {
                    if (typeof val === 'string') {
                        return Object.assign({}, emptyObj, { [valueKey]: newValue });
                    }
                    else {
                        return val;
                    }
                });
                const updatedSelection = mappedValues.filter(val => currentOptions.findIndex(opt => opt[valueKey] === val[valueKey]) > -1) || [];
                setValue(updatedSelection);
                dispatch(formEdit({
                    key: valueKey,
                    value: updatedSelection.map(v => v[valueKey] || '') || []
                }));
                if (dataKey !== valueKey) {
                    dispatch(formEdit({
                        key: dataKey,
                        value: updatedSelection.map(v => v[dataKey] || '') || []
                    }));
                }
                for (let key of updateKeys) {
                    dispatch(formEdit({
                        key: key,
                        value: updatedSelection.map(v => v[key] || '') || []
                    }));
                }
                setError(false);
                setErrorText('');
            }

        }
        else {
            const selectedValue = !freeSolo ? (currentOptions.find(item => (item[valueKey] === (newValue as TFormData)[valueKey])) || emptyObj) : Object.assign({}, emptyObj, newValue);
            setValue(selectedValue);
            dispatch(formEdit({
                key: valueKey,
                value: selectedValue[valueKey] || ''
            }));
            for (let key in selectedValue) {
                if (key !== valueKey && selectedValue[key] !== undefined && selectedValue[key] !== null) {
                    dispatch(formEdit({
                        key: key,
                        value: selectedValue[key]
                    }));
                }
            }
            setError(false);
            setErrorText('');
        }
    };

    // Render the component
    const chipProps = {
        color: 'secondary',
        disabled: disabled,
        deleteIcon: disabled ? <></> : <DeleteIcon />
    }
    // console.log('DEBUG: Render: autocompleteOptions:', currentOptions);
    // console.log('DEBUG: Render: autocomplete value:', value);
    return (
        <Tooltip
            title={label ? label : multiple && freeSolo ? 'Select/enter any values' : multiple && !freeSolo ? 'Select multiple options' : !multiple && freeSolo ? 'Select/enter any value' : 'Select an option'}
            aria-label={id}
        >
            <Autocomplete
                id={id}
                disabled={disabled}
                value={value}
                open={open}
                onOpen={() => {
                    loaded.current = false;
                    setCurrentOptions(initialOptions);
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                options={currentOptions}
                onChange={handleChange}
                multiple={multiple}
                freeSolo={freeSolo}
                includeInputInList={true}
                autoHighlight={true}
                disableClearable={required && !multiple}
                getOptionLabel={(option) => {
                    // console.log('getOptionLAbel option:', option);
                    return option[valueKey] || '';
                }}
                getOptionSelected={(option, value) => option?.[valueKey] === value?.[valueKey] || value?.[valueKey] === '' || value === ''}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="standard"
                        label={error ? errorText : showLabel !== false ? label : ''}
                        fullWidth={true}
                        inputRef={ref}
                        InputProps={{
                            ...params.InputProps,
                            onFocus: (e) => setOpen(true),
                            endAdornment: (
                                <React.Fragment>
                                    {alreadyLoading.current ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        helperText={alreadyLoading.current ? 'Loading selections...' : errorText}
                        error={error && !formCurrentlyLoading && ready}
                    />
                )}
                ChipProps={multiple ? chipProps : undefined}
                style={style}
                className={className}
                fullWidth={true}
            />
        </Tooltip>
    );
});
export default AutocompleteMenu;