import { TComponent, TLinkComponent, TDateComponent, ELinkTarget } from './types';

/**
 * @function isTLinkComponent Checks if an object is of the TLinkComponent type.
 * @param {TComponent|TLinkComponent|TDateComponent} testComponentProps The component props to test.
 * @returns {boolean} Returns true if the component props implement ILinkComponentProps.
 */
export default function isTLinkComponentProps(testComponentProps: TComponent | TLinkComponent | TDateComponent): testComponentProps is TLinkComponent {
    if (!testComponentProps) {
		return false;
    }
    const testTLink = testComponentProps as TLinkComponent;
	if (
        testTLink.dataKey && typeof testTLink.dataKey === 'string' && testTLink.dataKey.length > 0 && testTLink.linkHrefKey && typeof testTLink.linkHrefKey === 'string' && testTLink.linkHrefKey.length > 0 &&
        (
            (testTLink.linkTarget && ELinkTarget[testTLink.linkTarget] > -1) || (!testTLink.linkTarget)
        )
    ) {
        return true;
	}
	else {
        return false;
    }
}