/**
 * @file features/error/types
 * @description Contains types used for the Error feature.
 */

// Imports
import { IAction } from '../global';
import { SHOW_ERROR, HIDE_ERROR } from './actions';

/**
 * @type TErrorStatus
 * @enum
 * @description Possible error statuses. OK is included as it is used in logical tests.
 */
export enum EErrorStatus {
    'OK',
    'Invalid Input',
    'Not Found',
    'Timeout Error',
    'Request Cancelled',
    'Authentication Required',
    'Forbidden',
    'Service Unavailable',
    'Service Error',
}
export type TErrorStatus = keyof typeof EErrorStatus;

/**
 * @type TErrorState
 * @description Redux state for Error objects.
 */
export type TErrorState = {
    status: TErrorStatus,
    display: boolean,
    message: string,
    correlationId?: string,
    stack?: string,
    showResetOptions?: boolean,
};

/**
 * @type TErrorAction
 * @description Specific type for Error actions - creates & converts an extended interface into a type. For this action, a payload is mandatory and needs to be of type TErrorState.
 */
interface IErrorAction extends IAction {
    type: typeof SHOW_ERROR | typeof HIDE_ERROR;
    payload?: TErrorState;
}
export type TErrorAction = IErrorAction;