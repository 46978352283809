/**
 * @file features/view/isTIconName
 * @description Type guard for determining if a value is a valid TIconName.
 */

import { EIconName, TIconName } from './types';

const isTIconName = (input: unknown): input is TIconName => {
    try {
        const test = EIconName[input as TIconName];
        if (!test) {
            return false;
        }
        else {
            return true;
        }
    } catch (_) {
        return false;
    }
};
export default isTIconName;