/**
 * @component SingleComponent.tsx
 * @description Renders a single component from the list of supported dynamic components. These are chained together to build dynamic forms. 
 * @TODO ADD TO THIS AS MORE COMPONENTS ARE ADDED
 */

// Imports - React, Redux, hooks
import React, { useState, useEffect, useRef, RefObject, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { formEdit, showComponent, TFormData } from '../features/form';

// Imports - Other components
import Grid, { GridSize } from '@material-ui/core/Grid';

// Imports - components
import ErrorBoundary from './ErrorBoundary';
import AutocompleteMenu from './AutocompleteMenu';
import DataGrid from './DataGrid';
import DateTimePicker from './DateTimePicker';
import DurationPicker from './DurationPicker';
import EditLongText from './EditLongText';
import EditMasked from './EditMasked';
import EditShortText from './EditShortText';
import SimpleSelect from './SimpleSelect';
import SelectIcon from './SelectIcon';
import SwitchInput from './SwitchInput';
import FileAttachment from './FileAttachment';
import Button from './Button';
// import JsonBuilder, { IJsonBuilderProps } from './JsonBuilder';
import Chart from './Chart';
import AddressPicker from './AddressPicker';
import DialogButton from './DialogButton';
import LabelMaker from './LabelMaker';

import ViewText from './ViewText';
import RenderIcon from './RenderIcon';
import { TComponent, TInputComponent, TMaskedInputComponent, TAutocompleteComponent, TDateComponent, TButton, TRenderIconComponent, isTComponentType, TComponentPropType, TDataGrid, TShowRule, TDialogButton, TFileAttachment, TChart, TLabelMaker } from '../features/view';
import { Typography } from '@material-ui/core';
import { TGlobalState } from '../features/global';
import { TErrorState, showError } from '../features/error';

// Return the component
export default function SingleComponent(props: TComponentPropType): JSX.Element {
	const { id, componentType, label, showLabel, widths = {labelWidth: 3, valueWidth: 9}, hideable, showRules, show, required, style, dataKey = 'data', updateKeys = [], valueKey, clearDataOnHide = true, disabled, thisTabOrStep = 0, componentsPerRow = 1 } = props as TAutocompleteComponent;

	// Get Redux state for checking hide/show rules and error state
	const { lockedAndLoading, formData, displayError } = useSelector((state: TGlobalState) => {
		const relevantFormData: TFormData = {};
		for (let key in state.form.selectedValues) {
			if (key === dataKey) {
				// console.log('DEBUG: SingleComponent useSelector branch key === dataKey:', key, dataKey);
				relevantFormData[key] = state.form.selectedValues[key];
			}
			else if (key === valueKey) {
				// console.log('DEBUG: SingleComponent useSelector branch key === valueKey:', key, dataKey);
				relevantFormData[key] = state.form.selectedValues[key];
			}
			else if (updateKeys.indexOf(key) > -1) {
				// console.log('DEBUG: SingleComponent useSelector branch key is updateKey:', key, dataKey, updateKeys);
				relevantFormData[key] = state.form.selectedValues[key];
			}
			else if (showRules && showRules.findIndex((item: TShowRule) => item.showKey === key || item.testKey === key) > -1) {
				// console.log('DEBUG: SingleComponent useSelector branch key in showRules:', key, dataKey, JSON.stringify(showRules));
				relevantFormData[key] = state.form.selectedValues[key];
			}
		}
		// console.log('DEBUG: SingleComponent useSelector relevantFormData:', relevantFormData);
		return {
			lockedAndLoading: disabled ? true : state.user.loading || state.screens.loading || state.view.loading || state.form.loading ? true : false,
			formData: relevantFormData,
			displayError: state.error.display
		};
	});

	// Hook dispatch
	const dispatch = useDispatch();

	// Create the refs to pass
	const ref = useRef<RefObject<any> | null>(null);
	const alreadyMounted = useRef<boolean>(false);

	const memoisedFormData = useMemo(() => formData, [formData]);

	// Determine if we show the component
	// Also dispatch error here if our component type is not supported
	const [showing, setShowing] = useState(showComponent(hideable || true, showRules || [], memoisedFormData || {}, show));
	useEffect(() => {
		let mounted = true;

		if (mounted) {
			// console.log('showRules useEffect called on component', hideable, showRules, memoisedFormData, show, displayError, componentType, id, dispatch, dataKey, showing, valueKey, updateKeys, clearDataOnHide);
			if (!isTComponentType(componentType)) {
				const error: TErrorState = {
					status: 'Service Error',
					message: `The view definition refers to an unsupported component type, "${componentType}" with ID "${id}". Please contact Support to get this resolved.`,
					correlationId: 'COMPONENTS/SINGLE_COMPONENT/UNSUPPORTED_TYPE',
					display: true
				};
				dispatch(showError(error));
				setShowing(false);
			}
			const display = showComponent(hideable || false, showRules || [], memoisedFormData || {}, show);

			// console.log('DEBUG: calculated display for', dataKey, 'display:', display, 'showRules:', showRules, 'formData:', memoisedFormData);
			if (!displayError && display !== showing) {
				if (!display && clearDataOnHide) {
					dispatch(formEdit({
						key: dataKey,
						value: ''
					}));
					if (valueKey) {
						dispatch(formEdit({
							key: valueKey,
							value: ''
						}));
					}
					if (updateKeys) {
						for (let key of updateKeys) {
							dispatch(formEdit({
								key: key,
								value: ''
							}));
						}
					}
				}
				setShowing(display);
			}

			alreadyMounted.current = true;
		}

		return () => {
			mounted = false;
		}

	}, [hideable, showRules, memoisedFormData, show, displayError, componentType, id, dispatch, dataKey, showing, valueKey, updateKeys, clearDataOnHide]);

	// If the component is hidden, return nothing
	if (!showing) {
		return (<></>);
	}

	// Also set the maxWidth and maxHeight so that components aren't bigger than their container
	let styleToUse = Object.assign({}, { textAlign: 'left' }, style, { maxWidth: '100%', maxHeight: '100%' });

	// Finally, return the component specified in the componentType prop
	const labelComponent = showLabel === false ? null : <Grid item xs={widths?.labelWidth || 3}><Typography variant="h5">{label}{required ? <span style={{ color: 'red' }}>&nbsp;*</span> : null}</Typography></Grid>;
	// We pass the "disabled" prop based on whether the component is "lockedAndLoading"
	switch (componentType) {
		case 'Autocomplete':
			return (
				<>
					{labelComponent}
					<Grid item xs={widths?.valueWidth || 9}>
						<AutocompleteMenu {...props as TAutocompleteComponent} style={styleToUse} multiple={false} ref={ref} alreadyMounted={alreadyMounted} disabled={lockedAndLoading} />
					</Grid>
				</>
			);
		case 'AutocompleteMultiple':
			return (
				<>
					{labelComponent}
					<Grid item xs={widths?.valueWidth || 9}>
						<AutocompleteMenu {...props as TAutocompleteComponent} style={styleToUse} multiple={true} ref={ref} alreadyMounted={alreadyMounted} disabled={lockedAndLoading} />
					</Grid>
				</>
			);
		case 'Button':
			return (
				<ErrorBoundary correlationIdText={`COMPONENTS/BUTTON/UNCAUGHT/${id}`}>
					{labelComponent}
					<Grid item xs={widths?.valueWidth || 9}>
						<Button {...props as TButton} style={styleToUse} disabled={lockedAndLoading} alreadyMounted={alreadyMounted} />
					</Grid>
				</ErrorBoundary>
			);
		case 'DataGrid': {
			let componentsCalc = componentsPerRow;
			if (componentsPerRow === 0) {
				componentsCalc = 1;
			}
			widths.valueWidth = 12/componentsCalc as GridSize;
			return (
				<ErrorBoundary correlationIdText={`COMPONENTS/DATAGRID/UNCAUGHT/${id}`}>
					{labelComponent}
					<Grid item xs={widths?.valueWidth || 12}>
						<DataGrid {...props as TDataGrid} style={styleToUse} alreadyMounted={alreadyMounted} />
					</Grid>
				</ErrorBoundary>
			);
		}
		case 'DateTimePicker':
			return (
				<ErrorBoundary correlationIdText={`COMPONENTS/DATETIMEPICKER/UNCAUGHT/${id}`}>
					{labelComponent}
					<Grid item xs={widths?.valueWidth || 9}>
						<DateTimePicker {...props as TDateComponent} style={styleToUse} disabled={lockedAndLoading} ref={ref} alreadyMounted={alreadyMounted} />
					</Grid>
				</ErrorBoundary>
			);
		case 'DurationPicker':
			return (
				<ErrorBoundary correlationIdText={`COMPONENTS/DURATIONPICKER/UNCAUGHT/${id}`}>
					{labelComponent}
					<Grid item xs={widths?.valueWidth || 9}>
						<DurationPicker {...props as TInputComponent} style={styleToUse} disabled={lockedAndLoading} ref={ref} alreadyMounted={alreadyMounted} />
					</Grid>
				</ErrorBoundary>
			);
		case 'EditLongText':
			return (
				<ErrorBoundary correlationIdText={`COMPONENTS/EDITLONGTEXT/UNCAUGHT/${id}`}>
					{labelComponent}
					<Grid item xs={widths?.valueWidth || 9}>
						<EditLongText {...props as TInputComponent} style={styleToUse} disabled={lockedAndLoading} ref={ref} alreadyMounted={alreadyMounted} />
					</Grid>
				</ErrorBoundary>
			);
		case 'EditMasked':
			return (
				<ErrorBoundary correlationIdText={`COMPONENTS/EDITMASKED/UNCAUGHT/${id}`}>
					{labelComponent}
					<Grid item xs={widths?.valueWidth || 9}>
						<EditMasked {...props as TMaskedInputComponent} style={styleToUse} disabled={lockedAndLoading} ref={ref} alreadyMounted={alreadyMounted} />
					</Grid>
				</ErrorBoundary>
			);
		case 'EditShortText':
			return (
				<ErrorBoundary correlationIdText={`COMPONENTS/EDITSHORTTEXT/UNCAUGHT/${id}`}>
					{labelComponent}
					<Grid item xs={widths?.valueWidth || 9}>
						<EditShortText {...props as TInputComponent} style={styleToUse} disabled={lockedAndLoading} ref={ref} alreadyMounted={alreadyMounted} />
					</Grid>
				</ErrorBoundary>
			);
		// case 'JsonBuilder':
		// 	return (
		// 		<>
		// 			{labelComponent}
		// 			<Grid item xs={widths.valueWidth}>
		// 				<JsonBuilder {...props as IJsonBuilderProps} />
		// 			</Grid>
		// 		</>
		// 	);
		case 'SimpleSelect':
			return (
				<ErrorBoundary correlationIdText={`COMPONENTS/SIMPLESELECT/UNCAUGHT/${id}`}>
					{labelComponent}
					<Grid item xs={widths?.valueWidth || 9}>
						<SimpleSelect {...props as TAutocompleteComponent} style={styleToUse} disabled={lockedAndLoading} ref={ref} alreadyMounted={alreadyMounted} />
					</Grid>
				</ErrorBoundary>
			);
		case 'SwitchInput':
			return (
				<ErrorBoundary correlationIdText={`COMPONENTS/SINGLECOMPONENT/SWITCHINPUT/${id}`}>
					{labelComponent}
					<Grid item xs={widths?.valueWidth || 9}>
						<SwitchInput {...props as TInputComponent} style={styleToUse} disabled={lockedAndLoading} alreadyMounted={alreadyMounted} />
					</Grid>
				</ErrorBoundary>
			);
		case 'FileAttachment':
			return (
				<>
					{labelComponent}
					<Grid item xs={widths?.valueWidth || 9}>
						<FileAttachment {...props as TFileAttachment} style={styleToUse} />
					</Grid>
				</>
			);
		case 'ViewText':
			return (
				<ErrorBoundary correlationIdText={`COMPONENTS/VIEWTEXT/UNCAUGHT/${id}`}>
					{labelComponent}
					<Grid item xs={widths?.valueWidth || 9}>
						<ViewText {...props as TComponent} style={styleToUse} alreadyMounted={alreadyMounted} />
					</Grid>
				</ErrorBoundary>
			);
		case 'RenderIcon':
			return (
				<ErrorBoundary correlationIdText={`COMPONENTS/RENDERICON/UNCAUGHT/${id}`}>
					{labelComponent}
					<Grid item xs={widths?.valueWidth || 9}>
						<RenderIcon {...props as TRenderIconComponent} style={styleToUse} alreadyMounted={alreadyMounted} />
					</Grid>
				</ErrorBoundary>
			);
		case 'DialogButton':
			return (
				<ErrorBoundary correlationIdText={`COMPONENTS/DIALOGBUTTON/UNCAUGHT/${id}`}>
					{labelComponent}
					<Grid item xs={widths?.valueWidth || 9}>
						<DialogButton {...props as TDialogButton} style={styleToUse} alreadyMounted={alreadyMounted} />
					</Grid>
				</ErrorBoundary>
			)
		case 'AddressPicker':
			return (
				<ErrorBoundary correlationIdText={`COMPONENTS/ADDRESSPICKER/UNCAUGHT/${id}`}>
					{labelComponent}
					<Grid item xs={widths?.valueWidth || 9}>
						<AddressPicker {...props as TAutocompleteComponent} disabled={lockedAndLoading} ref={ref} alreadyMounted={alreadyMounted} />
					</Grid>
				</ErrorBoundary>
			);
		case 'SelectIcon':
			return (
				<ErrorBoundary correlationIdText={`COMPONENTS/SELECTICON/UNCAUGHT/${id}`}>
					{labelComponent}
					<Grid item xs={widths?.valueWidth || 9}>
						<SelectIcon {...props as TAutocompleteComponent} disabled={lockedAndLoading} style={styleToUse} ref={ref} alreadyMounted={alreadyMounted} />
					</Grid>
				</ErrorBoundary>
			);
		case 'Chart': {
			let componentsCalc = componentsPerRow;
			if (componentsPerRow === 0) {
				componentsCalc = 1;
			}
			widths.valueWidth = 12/componentsCalc as GridSize;
			return (
				<ErrorBoundary correlationIdText={`COMPONENTS/CHART/UNCAUGHT/${id}`}>
					<Grid item xs={widths?.valueWidth || 9}>
						{labelComponent}
						<Chart {...props as TChart} style={style} alreadyMounted={alreadyMounted} thisTabOrStep={thisTabOrStep} />
					</Grid>
				</ErrorBoundary>
			);
		}
		case 'LabelMaker': {
			let componentsCalc = componentsPerRow;
			if (componentsPerRow === 0) {
				componentsCalc = 1;
			}
			widths.valueWidth = 12/componentsCalc as GridSize;
			return (
				<ErrorBoundary correlationIdText={`COMPONENTS/LABELMAKER/UNCAUGHT/${id}`}>
					{labelComponent}
					<Grid item xs={widths?.valueWidth || 9}>
						<LabelMaker {...props as TLabelMaker} style={styleToUse} />
					</Grid>
				</ErrorBoundary>
			);
		}
		// case 'QuerySelector':
		// 	return (
		// 		<>
		// 			{labelComponent}
		// 			<Grid item xs={widths.valueWidth}>
		// 				<QuerySelector {...props as IQuerySelectorProps} style={styleToUse} />
		// 			</Grid>
		// 		</>
		// 	);
		default:
			// Show nothing - we shouldn't reach this but it is here anyway
			return (<></>);
	}
}