/**
 * @file features/view/actions
 * @description Contains Redux actions for loading the current View.
 */

// Imports
import { TViewAction, TViewMetadata } from './types';

/**
 * The VIEW_LOADING action is fired when a view begins to load.
 */
export const VIEW_LOADING = 'VIEW_LOADING';

/**
 * The viewLoading function is used to dispatch the VIEW_LOADING action - it is a pure action function that simply dispatches the loading status.
 * @returns {TViewAction} Returns a Redux action to be processed by the viewReducer.
 */
export const viewLoading = (): TViewAction => {
    return {
        type: VIEW_LOADING
    };
};

/**
 * The VIEW_LOADED action is fired when a view has loaded.
 */
export const VIEW_LOADED = 'VIEW_LOADED';

/**
 * The viewLoaded function is used to dispatch the VIEW_LOADED action - it is a pure action function that simply dispatches the metadata received.
 * @param {TViewMetadata?} viewMeta The view metadata to dispatch.
 * @returns {TViewAction} Returns a Redux action to be processed by the viewReducer.
 */
export const viewLoaded = (viewMeta?: TViewMetadata | null) => {
    return {
        type: VIEW_LOADED,
        payload: viewMeta
    };
};

/**
 * The VIEW_RESET action should be fired when we want a view to reload, as it sets the "ready" state to false. This should be used if we want to reload the view and re-run any initial fetch queries.
 */
 export const VIEW_RESET = 'VIEW_RESET';

 /**
  * The viewReset function is used to dispatch the VIEW_RESET action - it is a pure action function that simply dispatches the "ready" status.
  * @returns {TViewAction} Returns a Redux action to be processed by the viewReducer.
  */
 export const viewReset = (): TViewAction => {
     return {
         type: VIEW_RESET
     };
 };