/**
 * @component Demo.tsx
 * @description This component is designed specifically to demonstrate the functionality of various components. It can be used on form-builders, for unit testing, etc.
 */

// Imports
import React, { useEffect, useMemo } from 'react';

import ErrorBoundary from './ErrorBoundary';
import { TViewMetadata, viewLoaded } from '../features/view';
import Wizard from './Wizard';

import { useDispatch, useSelector } from 'react-redux';
import { TGlobalState } from '../features/global';
import { useQuery } from '../features/form';

const Demo: React.FC = () => {

    // Here we define the components
    // The content here demonstrates how the various components should be defined in view metadata for a "New" Wizard
    const viewMetadata: TViewMetadata = useMemo(() => {
        return {
            categoryAndScreen: 'staging-management:-services',
            view: 'newwizard',
            query: [
                {
                    purpose: 'Meta',
                    query_type: 'select',
                    query_name: 'getmyscreens',
                    dataKey: 'gridDemo',
                    parameters: []
                },
                {
                    purpose: 'DataLoad',
                    query_type: 'select',
                    query_name: 'countpartsbydevicetype',
                    dataKey: 'device_type',
                    parameters: []
                }
            ],
            tabsOrSteps: [
                {
                    title: 'Files, Charts and Data Grid',
                    errorText: 'Data Grid Demo',
                    sections: [
                        {
                            heading: 'Chart Demo',
                            componentsPerRow: 1,
                            components: [
                                {
                                    id: 'demochart',
                                    componentType: 'Chart',
                                    chartType: 'pie',
                                    label: "Parts by Device Type",
                                    dataKey: 'device_type',
                                    valueKey: 'count',
                                    drilldownBaseHref: '/parts-and-services/parts-and-bundles',
                                    drilldownMode: 'gridview',
                                    drilldownUrlParam: {
                                        name: 'device_type',
                                        urlKey: 'device_type'
                                    }
                                }
                            ]
                        },
                        {
                            heading: 'Files Demo',
                            componentsPerRow: 1,
                            components: [
                                {
                                    id: 'fileuploaddemo',
                                    dataKey: 'fileUploadDemo',
                                    componentType: 'FileAttachment',
                                    label: 'Upload File - Existing',
                                    recordTableName: 'services',
                                    categoryId: 0,
                                    staticValue: '4880d4cd-27e9-4ee9-82a8-48fee2b485da',
                                    uploadable: true
                                },
                                {
                                    id: 'filedownloaddemo',
                                    dataKey: 'fileDownloadDemo',
                                    componentType: 'FileAttachment',
                                    label: 'Download File',
                                    recordTableName: 'services',
                                    categoryId: 0,
                                    staticValue: '4880d4cd-27e9-4ee9-82a8-48fee2b485da'
                                },
                                {
                                    id: 'filenewdemo',
                                    dataKey: 'fileNewDemo',
                                    componentType: 'FileAttachment',
                                    label: 'Upload File - New',
                                    recordTableName: 'services',
                                    categoryId: 0,
                                    uploadable: true
                                },
                            ]
                        },
                        {
                            heading: 'Grid Demo',
                            componentsPerRow: 0,
                            components: [
                                {
                                    id: 'gridDemo',
                                    dataKey: 'gridDemo',
                                    componentType: 'DataGrid',
                                    label: 'Grid Demo',
                                    linkParams: [
                                        {
                                            name: 'category_name',
                                            urlKey: 'category'
                                        },
                                        {
                                            name: 'screen_name',
                                            urlKey: 'screen'
                                        }
                                    ],
                                    rowStyleRules: [
                                        {
                                            testKey: 'category_name',
                                            showValues: ['Companies']
                                        },
                                        {
                                            testKey: 'available_views',
                                            showValues: 'newwizard'
                                        }
                                    ],
                                    rowStyleColours: [
                                        {
                                            background: '#FF7373',
                                            foreground: '#000000'
                                        },
                                        {
                                            background: '#00FF00',
                                        }
                                    ],
                                    editDialog: {
                                        dataKey: 'gridDemoEdit',
                                        id: 'gridDemoEdit',
                                        label: 'Edit One',
                                        dialogText: 'Testing dialog text display',
                                        confirmQueries: [0],
                                        sections: [
                                            {
                                                heading: 'Pick Icon',
                                                componentsPerRow: 1,
                                                components: [
                                                    {
                                                        id: 'editIcon',
                                                        dataKey: 'editIcon',
                                                        componentType: 'SelectIcon',
                                                        label: 'Select another icon'
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    deleteDialog: {
                                        dataKey: 'gridDemoDelete',
                                        id: 'gridDemoDelete',
                                        label: 'Delete One',
                                        dialogText: 'Press submit to confirm deletion.',
                                        confirmQueries: [0],
                                        sections: [
                                            {
                                                heading: 'CONFIRM DELETION',
                                                componentsPerRow: 0,
                                                components: [
                                                    {
                                                        id: 'confirm-deletion-text',
                                                        dataKey: 'confirmDeletionText',
                                                        componentType: 'ViewText',
                                                        showLabel: false,
                                                        staticValue: '<span style="color:red"><strong>If you press Submit, this record will be deleted.</strong></span>',
                                                        dataType: 'code',
                                                        code: 'richtext'
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    showDeleteAction: true,
                                    newDialog: {
                                        dataKey: 'gridDemoNew',
                                        id: 'gridDemoNew',
                                        label: 'Add One',
                                        dialogText: 'Testing dialog text display',
                                        confirmQueries: [0],
                                        sections: [
                                            {
                                                heading: 'Pick Icon',
                                                componentsPerRow: 1,
                                                components: [
                                                    {
                                                        id: 'editIcon',
                                                        dataKey: 'editIcon',
                                                        componentType: 'SelectIcon',
                                                        label: 'Select another icon'
                                                    }
                                                ]
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    ]
                },
                {
                    title: 'Text inputs',
                    validateParams: [
                        {
                            key: 'emailInput',
                            type: 'email',
                            bodyData: true,
                            urlParam: false,
                            required: true,
                            label: 'Email (Required)'
                        },
                        {
                            key: 'plainTextInput',
                            type: 'string',
                            bodyData: true,
                            urlParam: false,
                            required: false,
                            label: 'Plain text'
                        },
                        {
                            key: 'barcodeInput',
                            type: 'string',
                            bodyData: true,
                            urlParam: false,
                            required: false,
                            label: 'Barcode',
                        },
                        {
                            key: 'phoneInput',
                            type: 'phone',
                            bodyData: true,
                            urlParam: false,
                            required: false,
                            label: 'Phone No',
                        },
                        {
                            key: 'currencyInput',
                            type: 'currency',
                            bodyData: true,
                            urlParam: false,
                            required: false,
                            label: 'Currency',
                        },
                        {
                            key: 'uuidInput',
                            type: 'uuid',
                            bodyData: true,
                            urlParam: false,
                            required: false,
                            label: 'UUID',
                        },
                        {
                            key: 'macInput',
                            type: 'macaddress',
                            bodyData: true,
                            urlParam: false,
                            required: false,
                            label: 'MAC Address',
                        },
                        {
                            key: 'numberInput',
                            type: 'number',
                            bodyData: true,
                            urlParam: false,
                            required: false,
                            label: 'Number',
                        },
                        {
                            key: 'customMaskInput',
                            type: 'object',
                            bodyData: true,
                            urlParam: false,
                            required: true,
                            label: 'Custom Mask (Required)'
                        },
                    ],
                    errorText: 'Please complete all required fields',
                    sections: [
                        {
                            heading: 'Not Masked',
                            componentsPerRow: 1,
                            components: [
                                {
                                    id: 'emailInput',
                                    dataKey: 'emailInput',
                                    componentType: 'EditShortText',
                                    dataType: 'email',
                                    label: 'Email (required)',
                                    required: true
                                },
                                {
                                    id: 'plainTextInput',
                                    dataKey: 'plainText',
                                    componentType: 'EditShortText',
                                    dataType: 'string',
                                    label: 'Any text'
                                },
                                {
                                    id: 'barcodeInput',
                                    dataKey: 'barcodeInput',
                                    componentType: 'EditShortText',
                                    dataType: 'string',
                                    label: 'Barcode',
                                    barcodeInput: true
                                },
                            ]
                        },
                        {
                            heading: 'Masked',
                            componentsPerRow: 1,
                            components: [
                                {
                                    id: 'phoneInput',
                                    dataKey: 'phoneInput',
                                    componentType: 'EditMasked',
                                    dataType: 'phone',
                                    label: 'Phone'
                                },
                                {
                                    id: 'currencyInput',
                                    dataKey: 'currencyInput',
                                    componentType: 'EditMasked',
                                    dataType: 'currency',
                                    label: 'Money'
                                },
                                {
                                    id: 'uuidInput',
                                    dataKey: 'uuidInput',
                                    componentType: 'EditMasked',
                                    dataType: 'uuid',
                                    label: 'UUID'
                                },
                                {
                                    id: 'macInput',
                                    dataKey: 'macInput',
                                    componentType: 'EditMasked',
                                    dataType: 'macaddress',
                                    label: 'MAC Address'
                                },
                                {
                                    id: 'numberInput',
                                    dataKey: 'numberInput',
                                    componentType: 'EditMasked',
                                    dataType: 'number',
                                    label: 'Any Number'
                                },
                                {
                                    id: 'customMaskInput',
                                    dataKey: 'customMaskInput',
                                    dataType: 'object',
                                    componentType: 'EditMasked',
                                    label: 'Enter Empties (custom mask)',
                                    format: '{"id": "########-####-####-####-############", "mac": "##:##:##:##:##:##"}',
                                    maskChar: "_",
                                    maskPlaceholder: '{"id": "________-____-____-____-____________", "mac": "__:__:__:__:__:__"}',
                                    formatChars: {
                                        '#': '[0-9A-Fa-f]'
                                    },
                                    required: true
                                },
                                {
                                    id: 'viewJson',
                                    dataKey: 'customMaskInput',
                                    componentType: 'EditLongText',
                                    dataType: 'code',
                                    code: 'json',
                                    label: 'Parsed object',
                                    disabled: true
                                },
                            ]
                        }
                    ]
                },
                {
                    title: 'Pickers',
                    validateParams: [
                        {
                            key: 'dateTimePicker',
                            type: 'date',
                            bodyData: true,
                            urlParam: false,
                            required: true,
                            label: 'Date/Time'
                        },
                        {
                            key: 'durationPicker',
                            type: 'duration',
                            bodyData: true,
                            urlParam: false,
                            required: false,
                            label: "Duration"
                        },
                        {
                            key: 'iconPicker',
                            type: 'string',
                            bodyData: true,
                            urlParam: false,
                            required: false
                        },
                        {
                            key: 'testAutocompleteValue',
                            type: 'string',
                            bodyData: true,
                            urlParam: false,
                            required: true,
                            label: 'Single-select Autocomplete'
                        },
                        {
                            key: 'autocompleteMultipleFree',
                            type: 'array',
                            bodyData: true,
                            urlParam: false,
                            required: false
                        },
                        {
                            key: 'simpleSelect',
                            type: 'string',
                            bodyData: true,
                            urlParam: false,
                            required: false
                        },
                    ],
                    errorText: 'Please select/enter values in all required fields',
                    sections: [
                        {
                            heading: 'Places, Dates and Durations',
                            componentsPerRow: 1,
                            components: [
                                {
                                    id: 'dateTimePicker',
                                    dataKey: 'dateTimePicker',
                                    componentType: 'DateTimePicker',
                                    dataType: 'date',
                                    label: 'Select date',
                                    required: true,
                                    defaultStartEnd: true
                                },
                                {
                                    id: 'displayDate',
                                    dataKey: 'dateTimePicker',
                                    componentType: 'ViewText',
                                    dataType: 'date',
                                    label: 'Display Formatted Date',
                                    dateFormat: 'DD/MM/YYYY h:mm a'
                                },
                                {
                                    id: 'durationPicker',
                                    dataKey: 'durationPicker',
                                    componentType: 'DurationPicker',
                                    dataType: 'duration',
                                    label: 'Enter/Select duration',
                                    datePickerType: 'datetime-local',
                                    defaultStartEnd: true
                                },
                            ]
                        },
                        {
                            heading: 'Menus',
                            componentsPerRow: 1,
                            components: [
                                {
                                    id: 'showSwitch',
                                    dataKey: 'showSwitch',
                                    componentType: 'SwitchInput',
                                    dataType: 'boolean',
                                    label: 'Show menus?',
                                },
                                {
                                    id: 'autocompleteMultipleLimited',
                                    dataKey: 'autocompleteMultipleLimited',
                                    valueKey: 'autocompleteMultipleLimited',
                                    componentType: 'AutocompleteMultiple',
                                    dataType: 'string',
                                    label: 'Select menus to show',
                                    required: true,
                                    options: [
                                        'Icon Picker',
                                        'Autocomplete / Record Picker',
                                        'Free entry multi-value autocomplete',
                                        'Simple Select Menu',
                                        'Address Picker'
                                    ],
                                    hideable: true,
                                    showRules: [{ testKey: 'showSwitch' }]
                                },
                                {
                                    id: 'iconPicker',
                                    dataKey: 'iconPicker',
                                    label: 'Sample Icons',
                                    componentType: 'SelectIcon',
                                    hideable: true,
                                    showRules: [{ testKey: 'autocompleteMultipleLimited', showValues: 'Icon Picker' }]
                                },
                                {
                                    id: 'testAutocomplete',
                                    componentType: 'Autocomplete',
                                    dataType: 'string',
                                    label: 'Select One',
                                    dataKey: 'testAutocomplete',
                                    valueKey: 'testAutocompleteValue',
                                    required: true,
                                    options: [
                                        'You cannot leave this menu',
                                        'Without selecting an option',
                                        'The selection must be in the list'
                                    ],
                                    fetchQueries: [],
                                    hideable: true,
                                    showRules: [{ testKey: 'autocompleteMultipleLimited', showValues: 'Autocomplete / Record Picker' }]
                                },
                                {
                                    id: 'autocompleteMultipleFree',
                                    dataKey: 'autocompleteMultipleFree',
                                    componentType: 'AutocompleteMultiple',
                                    label: 'Enter any values',
                                    dataType: 'string',
                                    freeSolo: true,
                                    hideable: true,
                                    showRules: [{ testKey: 'autocompleteMultipleLimited', showValues: 'Free entry multi-value autocomplete' }]
                                },
                                {
                                    id: 'simpleSelect',
                                    dataKey: 'simpleSelect',
                                    componentType: 'SimpleSelect',
                                    label: 'Simple Select',
                                    dataType: 'string',
                                    hideable: true,
                                    showRules: [{ testKey: 'autocompleteMultipleLimited', showValues: 'Simple Select Menu' }],
                                    options: [
                                        'A simple menu',
                                        'Use for few options',
                                        'No autocomplete typing'
                                    ]
                                },
                                {
                                    id: 'addressPicker',
                                    dataKey: 'address_picker',
                                    componentType: 'AddressPicker',
                                    label: 'Address Picker',
                                    updateKeys: [
                                        'country',
                                        'state',
                                        'postcode',
                                        'suburb',
                                        'address1',
                                        'address2'
                                    ],
                                    hideable: true,
                                    showRules: [{ testKey: 'autocompleteMultipleLimited', showValues: 'Address Picker' }]
                                },
                                {
                                    id: 'address1',
                                    dataKey: 'address1',
                                    componentType: 'ViewText',
                                    label: 'Address 1',
                                    hideable: true,
                                    showRules: [{ testKey: 'address1' }]
                                },
                                {
                                    id: 'address2',
                                    dataKey: 'address2',
                                    componentType: 'ViewText',
                                    label: 'Address 2',
                                    hideable: true,
                                    showRules: [{ testKey: 'address2' }]
                                },
                                {
                                    id: 'suburb',
                                    dataKey: 'suburb',
                                    componentType: 'ViewText',
                                    label: 'Suburb',
                                    hideable: true,
                                    showRules: [{ testKey: 'suburb' }]
                                },
                                {
                                    id: 'postcode',
                                    dataKey: 'postcode',
                                    componentType: 'ViewText',
                                    label: 'Postcode',
                                    hideable: true,
                                    showRules: [{ testKey: 'postcode' }]
                                },
                                {
                                    id: 'geostate',
                                    dataKey: 'state',
                                    componentType: 'ViewText',
                                    label: 'State',
                                    hideable: true,
                                    showRules: [{ testKey: 'state' }]
                                },
                                {
                                    id: 'country',
                                    dataKey: 'country',
                                    componentType: 'ViewText',
                                    label: 'Country',
                                    hideable: true,
                                    showRules: [{ testKey: 'country' }]
                                },
                            ]
                        }
                    ]
                },
                {
                    title: 'Review/Confirm',
                    errorText: 'Please review and press confirm to submit',
                    sections: [
                        {
                            heading: 'Text Inputs',
                            componentsPerRow: 3,
                            components: [
                                {
                                    id: 'testDialog',
                                    dataKey: 'testDialog',
                                    componentType: 'DialogButton',
                                    label: 'Open Form Dialog',
                                    buttonProps: {
                                        id: 'dialog-button-test',
                                        dataKey: 'dialogButtonTest',
                                        text: 'Open Dialog',
                                        iconName: 'HelpIcon'
                                    },
                                    dialogProps: {
                                        id: 'form-dialog',
                                        dataKey: 'testDialog',
                                        label: 'Edit One',
                                        dialogText: "Select a Device Type, Part and specify options. Press Submit to confirm and save.",
                                        confirmQueries: [
                                            0
                                        ],
                                        sections: [
                                            {
                                                heading: 'Test',
                                                componentsPerRow: 1,
                                                components: [
                                                    {
                                                        componentType: 'DateTimePicker',
                                                        dataKey: 'dialog_dateTimePicker',
                                                        id: 'dialog-datetime-picker'
                                                    }
                                                ]
                                            }
                                        ]
                                    },
                                    dispatchKeys: ["dateTimePicker"]
                                },
                                {
                                    id: 'emailDisplay',
                                    dataKey: 'emailInput',
                                    componentType: 'ViewText',
                                    dataType: 'email',
                                    label: 'Email',
                                    required: true
                                },
                                {
                                    id: 'plainTextDisplay',
                                    dataKey: 'plainText',
                                    componentType: 'ViewText',
                                    dataType: 'string',
                                    label: 'Plain Text'
                                },
                                {
                                    id: 'barcodeInput',
                                    dataKey: 'barcodeInput',
                                    componentType: 'ViewText',
                                    dataType: 'string',
                                    label: 'Barcode'
                                },
                                {
                                    id: 'phoneDisplay',
                                    dataKey: 'phoneInput',
                                    componentType: 'ViewText',
                                    dataType: 'phone',
                                    label: 'Phone'
                                },
                                {
                                    id: 'currencyDisplay',
                                    dataKey: 'currencyInput',
                                    componentType: 'ViewText',
                                    dataType: 'currency',
                                    label: 'Money'
                                },
                                {
                                    id: 'uuidDisplay',
                                    dataKey: 'uuidInput',
                                    componentType: 'ViewText',
                                    dataType: 'uuid',
                                    label: 'UUID'
                                },
                                {
                                    id: 'macDisplay',
                                    dataKey: 'macInput',
                                    componentType: 'ViewText',
                                    dataType: 'macaddress',
                                    label: 'MAC'
                                },
                                {
                                    id: 'numberDisplay',
                                    dataKey: 'numberInput',
                                    componentType: 'ViewText',
                                    dataType: 'number',
                                    label: 'Number'
                                },
                                {
                                    id: 'viewCustomMask',
                                    dataKey: 'customMaskInput',
                                    dataType: 'code',
                                    code: 'json',
                                    componentType: 'ViewText',
                                    label: 'Custom',
                                    required: true
                                },
                            ]
                        },
                        {
                            heading: 'Pickers',
                            componentsPerRow: 2,
                            components: [
                                {
                                    id: 'displayDateConfirm',
                                    dataKey: 'dateTimePicker',
                                    componentType: 'ViewText',
                                    dataType: 'date',
                                    label: 'Date',
                                    dateFormat: 'DD/MM/YYYY h:mm a',
                                    required: true
                                },
                                {
                                    id: 'durationDisplay',
                                    dataKey: 'durationPicker',
                                    componentType: 'ViewText',
                                    dataType: 'duration',
                                    label: 'Duration',
                                    datePickerType: 'datetime-local'
                                },
                                {
                                    id: 'iconShow',
                                    dataKey: 'iconPicker',
                                    label: 'Icon',
                                    componentType: 'RenderIcon',
                                    showName: 'right',
                                },
                                {
                                    id: 'displayAutocomplete',
                                    componentType: 'ViewText',
                                    dataType: 'string',
                                    label: 'Selection',
                                    dataKey: 'testAutocompleteValue',
                                    required: true,
                                    fetchQueries: [],
                                },
                                {
                                    id: 'autocompleteMultipleDisplay',
                                    dataKey: 'autocompleteMultipleFree',
                                    componentType: 'AutocompleteMultiple',
                                    label: 'Free values',
                                    disabled: true,
                                    dataType: 'string',
                                    valueKey: 'autocompleteMultipleFree',
                                },
                                {
                                    id: 'displaySelect',
                                    dataKey: 'simpleSelect',
                                    componentType: 'ViewText',
                                    label: 'Simple Selection',
                                    dataType: 'string',
                                }
                            ]
                        },
                        {
                            heading: 'Label Maker',
                            componentsPerRow: 0,
                            components: [
                                {
                                    id: 'labelMakerDemo',
                                    componentType: 'LabelMaker',
                                    dataKey: 'barcodeDemo',
                                    valueKey: 'barcodeDemo',
                                    staticValue: 'Label with code demo',
                                    updateKeys: ['fileDownloadDemoFileTitle', 'fileDownloadDemoFileDate'],
                                    barcodeKey: 'fileUploadDemoFileUuid'
                                }
                            ]
                        },


                    ]
                }
            ],
            topButtons: [{ id: 'switch-mode' }],
            bottomButtons: [{ id: 'button0' }, { id: 'button1' }, { id: 'button2' }, { id: 'button3', customAction: () => alert('Submitting') }],
            availableModes: ['newwizard', 'view', 'gridview', 'editwizard']
        };
    }, []);

    const dispatch = useDispatch();
    const view = useSelector((state: TGlobalState) => state.view.currentView);

    useEffect(() => {
        let mounted = true;
        if (mounted && !view) {
            dispatch(viewLoaded(viewMetadata));
        }
        return () => {
            mounted = false;
        }
    }, [viewMetadata, dispatch, view]);

    useQuery();

    return (<ErrorBoundary correlationIdText="COMPONENTS/DEMO/UNCAUGHT">
        <Wizard {...viewMetadata} />
    </ErrorBoundary>);
};
export default Demo;
