/**
 * @file features/grids/actions.ts
 * @description Redux actions relevant to data grids.
 */

// Imports
import { TGridAction, TGridState, TCellDataDispatch } from './types';

/**
 * The DATAGRID_LOADING action is fired when a grid is loading data from the back-end.
 */
export const DATAGRID_LOADING = 'DATAGRID_LOADING';

/**
 * The gridLoading function is used to dispatch the DATAGRID_LOADING action - it is a pure action function that simply dispatches the grid's updated state.
 * @returns {TGridAction} Returns a Redux action to be processed by the gridReducer.
 */
export const gridLoading = (grid: TGridState): TGridAction => {
    return {
        type: DATAGRID_LOADING,
        payload: grid
    };
};

/**
 * The DATAGRID_LOADED action is fired when a grid's data has loaded.
 */
export const DATAGRID_LOADED = 'DATAGRID_LOADED';

/**
 * The gridLoaded function is used to dispatch the DATAGRID_LOADED action - it is a pure action function that simply dispatches the grid's updated state.
 * @returns {TGridAction} Returns a Redux action to be processed by the gridReducer.
 */
export const gridLoaded = (grid: TGridState): TGridAction => {
    return {
        type: DATAGRID_LOADED,
        payload: grid
    };
};

/**
 * The DATAGRID_EDIT action is dispatched when a grid cell is edited.
 * @returns {TGridAction} Returns a Redux action to be processed by the gridReducer.
 */
export const DATAGRID_EDIT = 'DATAGRID_EDIT';

/**
 * The gridEdit function is used to dispatch the DATAGRID_EDIT action - it is a pure action function that simply dispatches the grid's updated state.
 * @returns {TGridAction} Returns a Redux action to be processed by the gridReducer.
 */
export const gridEdit = (cellData: TCellDataDispatch) => {
    return {
        type: DATAGRID_EDIT,
        payload: cellData
    };
};