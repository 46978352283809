/**
 * @file features/global/testTMode
 * @description Type guard that checks if a value is a TMode.
 */

import { TMode } from "./types";

const testTMode = (testString: string): testString is TMode => {
    switch(testString) {
        case 'gridview':
        case 'view':
        case 'edit':
        case 'editwizard':
        case 'gridedit':
        case 'new':
        case 'newwizard':
        case 'dashboard':
        case 'delete':
        case 'wizard':
            return true;
        default:
            return false;
    }
};
export default testTMode;