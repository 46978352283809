/**
 * @component ViewText.tsx
 * @description A view text component for displaying text data. Hooks into Redux state to get the value to display. Supports normal short text (i.e. body1 Material UI Typography) as well as links, dates, rich text and JSON.
 */

// Imports
import React from 'react';
import { useSelector } from 'react-redux';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import dayjs from 'dayjs';
import { TComponent, TLinkComponent, TDateComponent, isTDateComponent, isTLinkComponent } from '../features/view';
import { TFormData } from '../features/form';
import ReactJson from 'react-json-view';
import ReactHtmlParser from 'react-html-parser';
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js';
import 'highlight.js/styles/atom-one-light.css';
import { TGlobalState } from '../features/global';
import Skeleton from '@material-ui/lab/Skeleton';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

// Declare and export the component
export default function ViewText(props: TComponent): JSX.Element;
export default function ViewText(props: TLinkComponent): JSX.Element;
export default function ViewText(props: TDateComponent): JSX.Element;
export default function ViewText(props: TComponent | TLinkComponent | TDateComponent): JSX.Element {
    // Destructure props that all components have
    const { id, dataKey = props.id, dataType, style, className, staticValue } = props;

    // Get the value to be displayed
    // Static values take priority - otherwise we use the data at selectedValues[dataKey] or an empty string
    const { selectedValues, formLoading } = useSelector((state: TGlobalState) => {
        return {
            selectedValues: state.form.selectedValues || {} as TFormData,
            formLoading: state.form.loading || !state.form.ready
        };
    });
    let showValue = staticValue || selectedValues[dataKey] || '';
    const styleToUse = Object.assign({}, style, { maxWidth: '100%' });

    // Is the form loading? If so, show a skeleton
    if (formLoading && !staticValue) {
        return <Skeleton animation="pulse" variant="text" height="1vh" />;
    }

    // Do we have a link component?
    if (isTLinkComponent(props)) {
        const { linkHrefKey, linkTarget = '_blank' } = props;
        const linkHref = selectedValues[linkHrefKey];
        // Show '- No data -' string if we don't have a showValue or link href
        if (!showValue || !linkHref) {
            return (
                <Typography id={id} variant="subtitle2" style={styleToUse} className={className}>
                    - No data -
                </Typography>
            );
        }
        else {
            return (
                <Typography id={id} variant="body1" style={styleToUse} className={className}>
                    <Link href={linkHref} color="secondary" target={linkTarget}>
                        {showValue}
                    </Link>
                </Typography>
            );
        }
    }

    // For all other view text components, we display '- No data -' string if we don't have a showValue
    if (!showValue) {
        return (
            <Typography id={id} variant="body1" style={Object.assign({}, styleToUse, { fontStyle: 'italic' })} color='primary' className={className}>
                - No data -
            </Typography>
        );
    }

    // Date component - use DayJS to display date
    else if (isTDateComponent(props)) {
        const { dateFormat } = props;
        const dateString = dayjs(showValue).format(dateFormat);
        return (
            <Typography id={id} variant="body1" style={styleToUse} className={className}>
                {dateString}
            </Typography>
        );
    }

    // Other supported 'ViewText' component types
    else {
        // Other components
        switch (dataType) {
            case 'array': {
                if (typeof showValue === 'string') {
                    try {
                        showValue = JSON.parse(showValue);
                    } catch (_) {
                        return (
                            <Typography id={id} variant="body1" style={styleToUse} className={className}>
                                {showValue as string}
                            </Typography>
                        );
                    }
                }
                if (showValue instanceof Array) {
                    let typeTest = true;
                    for (let item of showValue) {
                        if (typeof item !== 'number' && typeof item !== 'string') {
                            typeTest = false;
                            break;
                        }
                    }
                    if (typeTest && showValue.length > 0) {
                        return <Autocomplete id={id} options={showValue} style={{ width: '100%', display: 'inline' }} value={showValue} renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                disabled={true}
                            />
                        )} disabled={true} multiple disableClearable={true} ChipProps={{ color: 'secondary', disabled: true, deleteIcon: <></> }} />
                    }
                    else if (typeTest) {
                        return <Typography id={id} variant="body2" color="primary" style={{ fontStyle: 'italics', fontSize: '0.75rem' }}>- No data -</Typography>;
                    }
                }
                return (
                    <Typography id={id} variant="body1" style={styleToUse} className={className}>
                        {showValue as string}
                    </Typography>
                );
            }
            case 'code': {      // eslint-disable-line no-fallthrough
                const { code } = props;
                if (code === 'json') {
                    let parsedValue: any = {};
                    try {
                        parsedValue = typeof showValue === 'object' ? showValue : JSON.parse(showValue);
                    } catch (error) {
                        parsedValue = {
                            error: 'Unable to parse value',
                            message: error.message,
                            stack: process.env.NODE_ENV !== 'production' ? error.stack : 'Please fix the data displayed here - it must be valid JSON. If the problem persists, please contact Support',
                            correlationId: 'COMPONENTS/VIEWTEXT/JSON_PARSE_ERROR'
                        };
                    }
                    return (
                        <Grid item id={id} className={className}>
                            <ReactJson src={parsedValue} name={false} style={Object.assign({}, styleToUse, { textAlign: 'left' })} theme="bright:inverted" />
                        </Grid>
                    );
                }
                else if (code === 'richtext') {
                    return (
                        <Grid item id={id} style={styleToUse} className={className}>
                            {ReactHtmlParser(showValue as string)}
                        </Grid>
                    );
                }
                else if (code === 'pgsql') {
                    return (
                        <Editor
                            value={showValue}
                            onValueChange={(value: string) => { }}
                            highlight={code => hljs.highlight('pgsql', code).value}
                            padding={10}
                            style={Object.assign({}, styleToUse, {
                                fontFamily: 'monospace',
                                fontWeight: 'bold',
                                fontSize: '1rem',
                                background: 'white',
                                border: '1px solid black',
                                boxShadow: 'inset 0 0 6px rgba(181, 181, 181, 0.8)'
                            })}
                            disabled={true}
                        />
                    );
                }
                // If the code language is undefined or unsupported, fall through & display it as any other data type using this component - i.e. convert to string and show as body1 text
            }
            case 'duration':	// eslint-disable-line no-fallthrough
                if (/^@\s/.test(showValue)) {
                    showValue = showValue.replace(/^@\s/, '');
                }
            default:	// eslint-disable-line no-fallthrough
                if (typeof showValue === 'object') {
                    showValue = JSON.stringify(showValue);

                }
                else if (typeof showValue !== 'string' && showValue.toString !== undefined && typeof showValue.toString === 'function') {
                    showValue = showValue.toString();
                }
                return (
                    <Typography id={id} variant="body1" style={styleToUse} className={className}>
                        {showValue as string}
                    </Typography>
                );
        }
    }
}