/**
 * @file features/view/isTStoredQuery.ts
 * Type guard for checking if a string represents a valid parameterised query that is compatible with the SQL Query Service. This function checks:
 * - Whether a query string is in the correct format based on its query type.
 * - Whether names in the query string are valid.
 * - Whether the number of parameter tokens matches the number of parameters.
 * - Whether the permitted role list supplied is valid.
 * - Whether the parameter definitions are valid, in that they have the right keys with the right data types, etc.
 * @param {unknown} query The query object to test.
 * @param {boolean=false} throwError Do we throw an error if the test fails or just return false?
 * @returns {true} Returns true if the query is valid.
 * @throws {TypeError} Throws a TypeError if there is a problem.
 */

import { EQueryType, TQueryType, TStoredQuery, EDataType, sqlNameRegex, queryRegexes, parameterTokenRegex, TParamType } from './types';
import { enumContains, ERole, TRole } from '../global';

export default function isTStoredQuery(query: unknown, throwError = false): query is TStoredQuery {
	// Destructure query
	const { query_type, query_name, query_data, parameters, permitted_roles, PartitionKey, RowKey } = query as TStoredQuery;
	const queryType = query_type || PartitionKey || '';
	const queryName = query_name || RowKey || '';

	// Make sure we have required props

	// Test query type
	if (!enumContains(EQueryType, queryType)) {
		if (throwError) {
			throw new TypeError(`Invalid query type specified`);
		}
		else {
			return false;
		}
	}

	// Test query name
	if (!sqlNameRegex.test(queryName)) {
		if (throwError) {
			throw new TypeError(`The query name specified is invalid - it must start with a letter or underscore and can only contain letters, numbers and underscores`);
		}
		else {
			return false;
		}
	}

	// Check that the query string passes the Regex test for the query type
	if (queryType !== 'transaction' && (typeof query_data !== 'string' || !queryRegexes[queryType as TQueryType].test(query_data))) {
		if (throwError) {
			throw new TypeError(`The query data string specified does not represent a supported "${queryType}" query`);
		}
		else {
			return false;
		}
	}
	else if (queryType === 'transaction' && (!(query_data instanceof Array) || query_data.find(query => !queryRegexes[query.type].test(query.query)))) {
		if (throwError) {
			throw new TypeError(`The query definition specified does not represent a supported "transaction" query`);
		}
	}
	// Make sure all permitted_roles supplied are valid and that we have at least one permitted role
	let testRoles: TRole[] = [];
	if (permitted_roles instanceof Array) {
		testRoles = permitted_roles;
	}
	else {
		try {
			testRoles = JSON.parse(permitted_roles);
		} catch (_) {
			// No-op
		}
	}
	for (let role of testRoles) {
		if (!role || !enumContains(ERole, role)) {
			if (throwError) {
				throw new TypeError(`The permitted roles specified are not supported`);
			}
			else {
				return false;
			}
		}
	}

	// Check that each parameter definition has the required keys
	function checkParams(paramsArray: TParamType[], queryString: string) {
		const paramTokens = queryString.match(parameterTokenRegex) || [];
			if (paramTokens.length !== paramsArray.length) {
				throw new TypeError('A parameter array must define the same number of parameters as tokens in the query string')
			}
		for (let param of paramsArray) {
			if (
				!Object.prototype.hasOwnProperty.call(param, 'key') ||
				typeof param['key'] !== 'string' ||
				!/^[A-Za-z][A-Za-z0-9_]*$/.test(param['key'])
			) {
					throw new TypeError(
						`The parameter definition ${JSON.stringify(
							param
						)} is invalid - a key property containing only letters, numbers and underscores must be specified`
					);
			}
			if (
				!Object.prototype.hasOwnProperty.call(param, 'type') ||
				typeof param['type'] !== 'string'
			) {
					throw new TypeError(
						`The parameter definition ${JSON.stringify(
							param
						)} is invalid - a data type property must be specified`
					);
			}
			if (!enumContains(EDataType, param.type)) {
					throw new TypeError(`The parameter definition ${JSON.stringify(param)} is invalid - the data type specified is not supported`);
			}
			if (
				!Object.prototype.hasOwnProperty.call(param, 'required') ||
				typeof param['required'] !== 'boolean'
			) {
					throw new TypeError(
						`The parameter definition ${JSON.stringify(
							param
						)} is invalid - a boolean "required" property must be specified`
					);
			}
		}
		return true;
	}
	if (queryType === 'transaction') {
		let testQueries = [];
		if (query_data instanceof Array) {
			testQueries = query_data;
		}
		else {
			try {
				testQueries = JSON.parse(query_data);
			} catch (error) {
				if (throwError) {
					throw error;
				}
				else {
					return false;
				}
			}
		}
		for (let i=0; i<testQueries.length; i++) {
			try {
				checkParams((parameters as (TParamType[])[])[i], testQueries[i].query);
			} catch (error) {
				if (throwError) {
					throw error;
				}
				else {
					return false;
				}
			}
		}
	}
	else {
		try {
			let testParams: TParamType[] = [];
			if (parameters instanceof Array) {
				testParams = parameters;
			}
			else {
				try {
					testParams = JSON.parse(parameters);
				} catch (error) {
					if (throwError) {
						throw error;
					}
					else {
						return false;
					}
				}
			}
			checkParams(testParams, query_data as string);
		} catch (error) {
			if (throwError) {
				throw error;
			}
			else {
				return false;
			}
		}
	}
	

	// If we got past these tests, return true
	return true;
}