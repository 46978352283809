/**
 * @file features/user/useProfile
 * @description Contains the custom useProfile hook - this should be used with components that require a profile to be loaded. It makes sure we have a profile and if not, fetches it.
 */

// Imports
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TGlobalState, profileUrl, IProfile } from '../global';
import { authProvider } from '../auth';
import { codeToStatusText, showError, TErrorState, validateTErrorState, parseError } from '../error';
import { profileLoading, profileLoaded } from './actions';
import { screensLoaded } from '../screens';
import { viewLoaded } from '../view';
import { formLoaded } from '../form';
import { TUserProfile } from './types';

const useProfile = (profile: IProfile | null, loading: boolean) => {

    // Hook dispatch and the error store
    const dispatch = useDispatch();
    const errorDisplay = useSelector((state: TGlobalState) => state.error.display);

    // useEffect hook - fetch profile if there is no profile, no error and nothing's been cancelled
    const profileRef = useRef(profile ? true : false);
    useEffect(() => {
        let cancelled = false;

        const fetchProfile = async () => {
                dispatch(profileLoading());
                try {
                    const token = await authProvider.getAccessToken();
                    const init = {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token.accessToken}`
                        }
                    };
                    const result = await fetch(profileUrl, init);
                    if (result.ok) {
                        const profileData: TUserProfile = await result.json().then(body => body.data);
                        if (!profileData) {
                            dispatch(screensLoaded([]));
                            dispatch(viewLoaded(null));
                            dispatch(formLoaded(null));
                            const err: TErrorState = {
                                status: 'Forbidden',
                                message: 'Your account is not permitted to access StageIT. Please log in with a different account.',
                                correlationId: 'FEATURES/USER/USE_PROFILE/PROFILE_NOT_FOUND',
                                display: true
                            };
                            dispatch(showError(err));
                            return;
                        }
                        dispatch(profileLoaded(profileData));
                        profileRef.current = true;
                    }
                    else {
                        dispatch(screensLoaded([]));
                        dispatch(viewLoaded(null));
                        dispatch(formLoaded(null));
                        const status = codeToStatusText(result.status);
                        let responseBody = null;
                        let message = '';
                        let correlationId = 'FEATURES/USER/USE_PROFILE/FETCH_ERROR';
                        try {
                            responseBody = await result.json();
                        } catch (_) {
                            responseBody = await result.text();
                        }
                        if (typeof responseBody === 'string') {
                            message = responseBody;
                        }
                        else {
                            if (responseBody.message) {
                                message = responseBody.message;
                            }
                            if (responseBody.correlationId) {
                                correlationId = responseBody.correlationId;
                            }
                        }
                        const err: TErrorState = {
                            status: status,
                            message: message,
                            correlationId: correlationId,
                            display: true
                        };
                        dispatch(showError(err));
                    }
                } catch (err) {
                    dispatch(screensLoaded([]));
                    dispatch(viewLoaded(null));
                    dispatch(formLoaded(null));
                    if (validateTErrorState(err)) {
                        dispatch(showError(parseError(err)));
                    }
                    else {
                        const errorObj: TErrorState = {
                            status: 'Service Error',
                            message: err.message,
                            stack: err.stack,
                            correlationId: 'FEATURES/USER/USE_PROFILE/SYSTEM_ERROR',
                            display: true
                        };
                        dispatch(showError(errorObj));
                    }
                    profileRef.current = true;
                }
        };
        if (!cancelled && !loading && !profileRef.current && !errorDisplay) {
            fetchProfile();
        }
        // Cleanup function so we don't run if not mounted
        return () => {
            cancelled = true;
        }

    }, [dispatch, errorDisplay, loading]);
};
export default useProfile;