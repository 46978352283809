/**
 * @function readableTitle Utility function for converting part of a URL path slug or field name (lowercase with underscores or dashes) into a more readable title - underscores are replaced with spaces and the first letter of each word is capitalised. "id" is converted to "ID#".
 * @param {string} title The title to convert.
 * @returns {string} Returns the converted title.
 */
const readableTitle = (title: string) => {
	const splitTitle = title.split(/_|-/);
	let processedWords: string[] = [];
	for (let word of splitTitle)
	{
		if (/^id$/i.test(word))
		{
			processedWords.push('ID#');
		}
		else
		{
			const firstLetter = word[0].toUpperCase();
			processedWords.push(word.replace(/^./, firstLetter));
		}
	}
	return processedWords.join(' ');
};
export default readableTitle;