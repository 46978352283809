/**
 * @file features/global/types
 * @description Contains types and interfaces used with Redux.
 */

// Imports
import { TAADResponseState } from '../auth';
import { TAlertState } from '../alert';
import { TErrorState } from '../error';
import { TUserState } from '../user';
import { TScreenState } from '../screens';
import { TViewState } from '../view';
import { TFormState } from '../form';
import { TAllGrids } from '../grids';

/**
 * @interface IAction
 * @description Generic Redux "action" interface with optional type and payload. Other reducers can extend this interface.
 */
export interface IAction {
    type: string;
    payload?: any;
};

/**
 * @type TAction
 * @description Conversion of IAction interface to a type.
 */
export type TAction = IAction;

/**
 * @type TGlobalState
 * @description Global Redux state - we add properties to this as we add reducers.
 */
export type TGlobalState = {
    aadResponse: TAADResponseState,
    alert: TAlertState,
    error: TErrorState,
    user: TUserState,
    screens: TScreenState,
    view: TViewState,
    form: TFormState,
    grids: TAllGrids,
};

/**
 * @type TAccessLevel
 * @enum
 * @description Enum type for Access Levels - the broadest access control/authorisation level. This type is defined here because it is used by multiple features.
 */
export enum AccessLevel {
    'Administrator',
    'Support',
    'Fujitsu User',
    'Customer',
    'Vendor',
    'ADHOC'
}
export type TAccessLevel = keyof typeof AccessLevel;

/**
 * @type TRole
 * @enum
 * @description Enum type for Roles, which provide finer access control. * is a wildcard (i.e. all roles can access this), "Inactive" should be used for ex-users and "null" and "undefined" are included to avoid type errors, but will result in no access. This type is defined here because it is used by multiple features.
 */
export enum ERole {
    'Administrator',
    'Support',
    'Deployment Director',
    'Integrations Team Leader',
    'Senior Integrations Specialist',
    'Integrations Specialist',
    'Program Manager',
    'Project Manager',
    'PMO Staff',
    'Warehouse Staff',
    'Resource Coordinator',
    'Engineer',
    'Travel Coordinator',
    'Platforms Team Member',
    '*',
    'Inactive',
    null,
    undefined
}
export type TRole = keyof typeof ERole;

/**
 * @type TTeamType
 * @enum
 * @description Enum type for Team Types. Teams are the most granular access control method, which is implemented by limiting backend queries to the teams that are allowed to use them. This type is defined here because it is used by multiple features.
 */
export enum ETeamType {
    'Deployment',
    'Support',
    'Integration',
    'Logistics',
    'Platforms',
    null,
    undefined
}
export type TTeamType = keyof typeof ETeamType;

/**
 * @type TEmploymentBasis
 * @enum
 * @description Enum type for Employment Basis. This is not used for access control but rather for profile data. This type is defined here because it is used by multiple features.
 */
export enum EEmploymentBasis {
    'FTE',
    'PTE',
    'Standard Contractor',
    'ADHOC',
    'Customer',
    'Vendor',
    null,
    undefined
}
export type TEmploymentBasis = keyof typeof EEmploymentBasis;

/**
 * @type TLiveStatus
 * @enum
 * @description Possible "Live Status" values
 */
export enum ELiveStatus {
    'C' = 'Current',
    'D' = 'Disabled/Deleted',
    'A' = 'Archived',
    'R' = 'Restored',
    'H' = 'Hidden'
}
export type TLiveStatus = keyof typeof ELiveStatus;

/**
 * @interface IProfile
 * @description Extendable interface for profiles - whether of users or non-users. This interface is defined here because it is used by multiple features.
 */
export interface IProfile {
    first_name: string;
    last_name: string;
    profile_picture?: string;
    profile_picture_id?: string;
    role_title: string;
    employment_basis: TEmploymentBasis;
    email: string;
    primary_contact_number: string;
    secondary_contact_number: string | null;
    home_suburb: string | null;
    home_state: string | null;
    home_postcode: string | null;
    home_country: string | null;
    company_name: string;
    manager_name: string;
    office_location_name: string | null;
    office_address_1: string;
    office_address_2: string | null;
    office_suburb: string | null;
    office_state: string | null;
    office_postcode: string | null;
    created_date: Date;
    created_by: string;
    last_modified_date: Date;
    last_modified_by: string;
    live_status: TLiveStatus;
}

/**
 * @type TMode
 * @enum
 * @description Enum type for the view mode.
 */
export enum EMode {
    'gridview' = 'Grid View',
    'view' = 'View One',
    'edit' = 'Edit One',
    'editwizard' = 'Edit Wizard',
    'gridedit' = 'Grid Edit',
    'new' = 'Create New',
    'newwizard' = 'New Wizard',
    'dashboard' = 'Dashboard',
    'delete' = 'Delete One',
    'wizard' = 'Workflow Wizard'
}
export type TMode = keyof typeof EMode;