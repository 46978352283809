/**
 * @file features/screens/reducer
 * @description The reducer for screen actions.
 */

// Imports
import { SCREENS_LOADING, SCREENS_LOADED } from './actions';
import screensInitialState from './initialState';
import { TScreenState, TScreenAction } from './types';

/**
 * The screens reducer updates Redux state base on actions/payloads.
 * @param {TScreenState} state Previous state object.
 * @param {TScreenAction} action Action to process.
 * @returns {TScreenState} Returns the new state.
 */
const screensReducer = (state = screensInitialState, action: TScreenAction | undefined): TScreenState => {
    if (!action)
    {
        return state;
    }
    const { type, payload } = action;
    switch (type)
    {
        case SCREENS_LOADING:
            return {
                ...state,
                loading: true
            };
        case SCREENS_LOADED:
            if (!payload)
            {
                return state;
            }
            return {
                ...state,
                loading: false,
                available: payload
            };
        default:
            return state;
    }
};
export default screensReducer;